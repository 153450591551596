import React, {FC} from 'react';
// import {useTranslation} from 'react-i18next';
// import {ReactComponent as BackIcon} from 'assets/images/icons/back.svg';
// import {history} from 'index';
import pageTitleStyle from './PageTitle.module.scss';
import {useMedia} from 'hooks';


export const PageTitle: FC<{ text?: string; showBackBtn?: boolean; }> = (props) => {
    const {children, text = false} = props;
    // const {t} = useTranslation();
    const isMobile = useMedia();

    // const onHistoryBackClick = () => {
    //     history.back();
    // };


    return (
        <h1 className={`${pageTitleStyle.pageTitle} ${isMobile ? 'textHeader_06' : 'textHeader_03'}`}>
            {/*{showBackBtn && <button onClick={onHistoryBackClick} className={pageTitleStyle.pageTitleIcon} title={t('Go back')}><BackIcon/></button>}*/}
            {!!text ? text : children}
        </h1>
    );
};
