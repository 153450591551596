import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks";
import { MainRoutesEnum, notificationListPushItem, RootState } from "core";
import { useDispatch, useSelector } from "react-redux";
import {LayoutContainer, LoaderWithOverlay} from "components";
import { setLandingType } from "modules";
import { LandingTypes } from "modules/Landing/typings";
import { getCookie } from "utils";
import { InfoGraphic } from "../../components";
import { ENotificationsThemes } from "../../../../components/Notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Extension.module.scss";

const Extension: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uriParams } = useSelector(
    (state: RootState) => state.landingReducer
  );
  const [eventIsSended, setEventIsSended] = useState(false);
  const navigate = useNavigate();

  useDocumentTitle(uriParams.domain || t("External website landing"));
  useEffect(() => {
    dispatch(setLandingType(LandingTypes.EXTENSION));
  }, [dispatch]);

  const EXTENSION_ID = "jndboabnjeeeepiipnmjnpcbgjpffikm";
  // const EXTENSION_ID = "fiplpocddfchffgopblkmchfobmfpiip"; // for local development

  const csrfToken = getCookie("csrftoken");
  const { landingType } = useSelector(
    (state: RootState) => state.landingReducer
  );

  const getTokens = async () => {
    try {
      const tokens = await axios({
        method: "post",
        url: "/api/token/",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      }).then((res) => res.data);

      const config = {
        chromeExtension: {
          id: EXTENSION_ID,
        },
      };

      const data = { ...tokens };
      if (chrome?.runtime && config.chromeExtension?.id) {
        chrome.runtime.sendMessage(config.chromeExtension.id, {
          name: "GiftmioSignUp",
          data,
        });
        setEventIsSended(true);
        navigate(MainRoutesEnum.CATALOG);
      } else {
        setEventIsSended(true);
        dispatch(notificationListPushItem({
          id: Date.now().toString(),
          title: `${t('Something went wrong')}. ${t('Please try to reload the page')}`,
          theme: ENotificationsThemes.warning,
        }));
      }
    } catch (e) {
      console.info(`Failed to send token to extension: ${e}`);
    }
  };
  useEffect(() => {
    getTokens();
  }, []);
  return (
      <div className={styles.landing}>
        <div className={styles.container}>
          {landingType === LandingTypes.EXTENSION && (
              <h1 className={styles.title}>
                {t("You logged into the")}
                <br/>
                {uriParams?.domain || t("Giftmio Extension")}
              </h1>
          )}
          <span className={styles.subtitle}>
          {t("To start, re-open the extension")}
        </span>
        </div>
        <LayoutContainer>
          <div className={styles.infoGraphic}>
            <p className={`textHeader_06 brand_text`}>
              {t('Never miss a cashback opportunity while shopping online')}
            </p>
            <InfoGraphic/>
          </div>
        </LayoutContainer>

        {!eventIsSended && <LoaderWithOverlay/>}
      </div>
  );
};
export {Extension};
export default Extension;
