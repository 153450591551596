import babyIcon from 'assets/images/categories/baby.svg';
import booksIcon from 'assets/images/categories/books.svg';
import clothesIcon from 'assets/images/categories/clothes.svg';
import electronicsIcon from 'assets/images/categories/electronics.svg';
import beautyIcon from 'assets/images/categories/beauty.svg';
import digitalIcon from 'assets/images/categories/digital.svg';
import travelIcon from 'assets/images/categories/travel.svg';
import furnitureIcon from 'assets/images/categories/furniture.svg';
import petsIcon from 'assets/images/categories/pets.svg';
import adultIcon from 'assets/images/categories/adult.svg';
import sportsIcon from 'assets/images/categories/sports.svg';
import foodIcon from 'assets/images/categories/food.svg';
import giftsIcon from 'assets/images/categories/gifts.svg';
import carsIcon from 'assets/images/categories/cars.svg';
import financeIcon from 'assets/images/categories/finance.svg';
import hobbiesIcon from 'assets/images/categories/hobbies.svg';

export const categoriesIcons = {
    baby: babyIcon,
    books: booksIcon,
    clothes: clothesIcon,
    electronics: electronicsIcon,
    beauty: beautyIcon,
    digital: digitalIcon,
    travel: travelIcon,
    furniture: furnitureIcon,
    pets: petsIcon,
    adult: adultIcon,
    sports_and_outdoor: sportsIcon,
    food: foodIcon,
    gifts_and_flowers: giftsIcon,
    cars_and_bikes: carsIcon,
    finance_and_insurance: financeIcon,
    hobbies: hobbiesIcon,
}
