import { DarkClickableArea} from 'components';
import { ModalContent} from './components';
import { usePortal } from 'hooks';
import cn from 'classnames';
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';

import styles from './Modal.module.scss';
import { IModalProps } from './Modal.types';

/**
 * Модальное окно
 */
export const Modal: FC<IModalProps> = (props) => {
  const {
    className,
    closable = true,
    active = false,
    fixed = false,
    setActive,
    onClose,
    modalContainerId,
    scrollContainerId,
    size,
    darkClickableAreaClassName,
    ...modalWindowProps
  } = props;

  // DOM-элемент, в которой будет пробрасываться контент
  const target = usePortal(modalContainerId);
  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  const [scrollContainerNode, setScrollContainerNode] = useState<Element| null>(null);

  useEffect(() => {
    const node = document.getElementById(scrollContainerId);

    setScrollContainerNode(node);

    return () => {
      // Вернет скролл на контенте, если ушли со страницы
      scrollContainerNode?.classList.remove(styles.noScroll);
    };
  }, [scrollContainerId, scrollContainerNode]);

  // Выключит скролл для контейнера
  useEffect(() => {
    if (!scrollContainerNode) {
      return;
    }

    if (active) {
      scrollContainerNode.classList.add(styles.noScroll);
      if (!fixed) {
        scrollContainerNode.scrollTo({
          top: 0,
          left: 0,
        });
      }
    } else {
      scrollContainerNode.classList.remove(styles.noScroll);
    }
  }, [fixed, scrollContainerNode, active]);

  // Обработчик нажатия по затемненной зоне вокруг модального окна и по крестику
  const handleCloseModal = (event) => {
    const target = event.target as HTMLElement;
    if (closable &&
      (!modalContentRef.current?.contains(target) || closeButtonRef.current?.contains(target))
    ) {
      onClose?.();
      setActive(false);
    }
  };

  if (!active) {
    return null;
  }

  return createPortal(
    <DarkClickableArea
      className={cn(styles.container, darkClickableAreaClassName)}
      fixed={fixed}
      onClick={handleCloseModal}
    >
      <div className={cn(styles.modalContentContainer, className)}>
        <ModalContent
          active={active}
          setActive={setActive}
          onClose={handleCloseModal}
          closable={closable}
          size={size}
          ref={modalContentRef}
          closeButtonRef={closeButtonRef}
          {...modalWindowProps}
        />
      </div>
    </DarkClickableArea>,
    target,
  );
};
