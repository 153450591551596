import React, {FC, useState} from 'react';
import style from './ModalHint.module.scss';
import {ReactComponent as CloseIcon} from 'assets/images/icons/close.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/images/icons/arrow-right.svg';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from '../Button/Button';
import {ModalHintInterface} from './ModalHint.interface';


export const ModalHint: FC<ModalHintInterface> = ({onCloseClick, onOkClick}) => {
    const {t} = useTranslation();
    const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

    return (
        <div className={style.modal_wrapper}>
            <div className={style.modal}>
                <div className={style.header}>
                    <button className={style.close_button} onClick={onCloseClick}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className={style.content}>
                    <div className={style.content_step}>
                        {isFirstStep
                            ? <span className={style.content_step_text}>{t('STEPS 1/2')}</span>
                            : <span className={style.content_step_text}>{t('STEPS 2/2')}</span>
                        }
                        {isFirstStep && <button className={style.arrow_button} onClick={() => setIsFirstStep(!isFirstStep)}>
                            <ArrowRightIcon/>
                        </button> }
                    </div>
                    <div className={style.content_main}>
                        <h2 className='textHeader_06'>
                            {isFirstStep ? <Trans i18nKey='modalhintTextOne'>
                                How to get <span className={style.brand_text}>quick</span> cashback?
                            </Trans> : <Trans i18nKey='modalhintTextTwo'>
                                How to get your <span className={style.brand_text}>cashback</span>
                            </Trans>}
                        </h2>
                        {isFirstStep
                            ? <p>{t('Click the blue button to get your cashback in just 7 days after buying.')}</p>
                            : <p>{t('Now, just sign into your Giftmio account so we can send you the cashback when it arrives!')}</p>
                        }
                    </div>
                    <Button onClick={onOkClick} theme='stroke' textContent={t('Ok')} isFullWidth={true}/>
                </div>
            </div>
        </div>
    );
};
