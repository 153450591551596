import React, {FC, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import style from './TakingYou.module.scss';
import {Button} from 'components';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {useMedia} from 'hooks';
import {Loader, LoaderSizes} from '@design-system/ui-kit';
import {LandingTypes} from '../../typings';
import {TakingYouInterface} from './TakingYou.interface';

export const TakingYou: FC<TakingYouInterface> = (props) => {
    const isMobile = useMedia();
    const {t} = useTranslation();
    const {onCancelClick, onActivateClick} = props;
    const {landingType, uriParams} = useSelector((state: RootState) => state.landingReducer);
    const [counter, setCounter] = React.useState(10);

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer as NodeJS.Timer);
    }, [counter]);
    return (
        <div className={style.wrapper}>
            <div className={style.main_wrapper}>
                <div className={style.main}>
                    <Loader inline={false} noMargin={false} size={LoaderSizes.s}/>
                    <p className={`${isMobile ? 'textBody_02' : 'brand_text textBodyMedium_L'}`}>{t('Taking you to')}:</p>
                    <div className={style.main_loader}>
                        <h1 className={`${isMobile ? 'textHeader_06 brand_text' : 'textHeader_01'}`}>{uriParams?.domain || t('External website')}</h1>
                        {!isMobile && <span className={style.loader}></span>}
                    </div>
                    <p className={`${style.main_text} textBody_01`}>
                        {landingType === LandingTypes.TAKEADSV1
                            ? <>{!isMobile && <Trans i18nKey='takeAdsLandingTextWithBtn' counter={counter}>
                            <button onClick={() => onCancelClick('click_please_click_here')}
                                    className={`brand_text ${style.main_btn}`}>
                              Please click here
                            </button>
                            if you are not redirected in {{counter}} sec.
                          </Trans>}</>
                            : <Trans i18nKey='takeAdsLandingText' counter={counter}>
                                Please click here if you are not redirected in {{counter}} sec.
                            </Trans>
                        }
                    </p>
                    {landingType === LandingTypes.TAKEADSV2 &&
                      <Button isFullWidth={isMobile} onClick={() => onCancelClick('click_continue')} theme='stroke' textContent={t('Continue')} kind='ellipse'/>
                    }
                </div>
                {isMobile && landingType === LandingTypes.TAKEADSV1 &&
                  <p className={`textBody_01 text_center`}>
                    <Trans i18nKey='takeAdsLandingTextWithBtn' counter={counter}>
                        <button onClick={onCancelClick} className={`brand_text ${style.main_btn}`}>
                            Please click here</button>if you are not redirected in {{counter}} sec.
                    </Trans>
                  </p>}
            </div>
            {isMobile ? uriParams?.txt
                ? <h2 className={`textHeader_03 text_center`}>{uriParams.txt}</h2>
                : <h2 className={`textHeader_03 text_center`}>
                    <Trans i18nKey='directLandingWantToSave'>
                        Want to save up to <span className='brand_text'>10%</span>?
                    </Trans>
                </h2> : <></>
            }
            {isMobile &&
              <div className={style.btns}>
                <Button onClick={onActivateClick} textContent={t('Yes, activate my cashback!')} kind='ellipse'/>
                <Button onClick={onCancelClick} kind={'ellipse'} textContent={t('No, I hate saving')} theme='stroke'/>
              </div>}
        </div>

    );
};
