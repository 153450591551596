import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_DE from './translations/translation.de.json';
import TRANSLATIONS_EN from './translations/translation.en.json';
import TRANSLATIONS_ES from './translations/translation.es.json';
import TRANSLATIONS_FR from './translations/translation.fr.json';
import TRANSLATIONS_IT from './translations/translation.it.json';
import TRANSLATIONS_PL from './translations/translation.pl.json';
import TRANSLATIONS_PT from './translations/translation.pt.json';
import TRANSLATIONS_RU from './translations/translation.ru.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        keySeparator: false,
        nsSeparator: false,
        react: {
            bindI18n: 'languageChanged',
            useSuspense: false,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'b', 'span'],
        },
        returnEmptyString: false,
        resources: {
            de: {
                translation: TRANSLATIONS_DE,
            },
            en: {
                translation: TRANSLATIONS_EN,
            },
            es: {
                translation: TRANSLATIONS_ES,
            },
            fr: {
                translation: TRANSLATIONS_FR,
            },
            it: {
                translation: TRANSLATIONS_IT,
            },
            pl: {
                translation: TRANSLATIONS_PL,
            },
            pt: {
                translation: TRANSLATIONS_PT,
            },
            ru: {
                translation: TRANSLATIONS_RU,
            }
        },
    });

export {i18n};
export default i18n;
