import {LandingTypes} from '../../modules/Landing/typings';


export const getClickUnderLink = (link: string, landingType: string): string => {
    const defaultRedirectLink = '/';
    let subid;

    switch (landingType) {
      case LandingTypes.DIRECTV1:
        subid = 'giftmio_landing_1';
        break;
      case LandingTypes.DIRECTV2:
        subid = 'giftmio_landing_2';
        break;
    }

    if (!link) {
        return defaultRedirectLink;
    }

    try {
        const originalLink = new URL(link);
        if (!originalLink.searchParams.get('subid')) {
            originalLink.searchParams.set('subid', subid);
        }
        return originalLink.href;
    } catch (error) {
        return defaultRedirectLink;
    }
};
