export enum BillingFormControlsEnum {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    PAYPAL_ACCOUNT = 'paypal_account',
    PAYPAL_ZIP_CODE = 'paypal_zip_code',
    CARD_FIRST_NAME = 'card_first_name',
    CARD_LAST_NAME = 'card_last_name',
    CARD_ZIP_CODE = 'card_zip_code',
    CARD_IBAN = 'card_iban',
    CARD_SWIFT = 'card_swift',
    RU_CARD_NUMBER = 'ru_card_number',
    RU_CARD_HOLDER = 'ru_card_holder',
}
