import {LandingTypes} from '../../modules/Landing/typings';
import {AnalyticEventOptionsEnum} from 'typings';

export const getLandingLocation = (landingType: string): string => {
    switch (landingType) {
        case LandingTypes.DIRECTV1:
            return AnalyticEventOptionsEnum.DIRECT_LINK;
        case LandingTypes.DIRECTV2:
            return AnalyticEventOptionsEnum.DIRECT_LINK_V2;
        case LandingTypes.TAKEADSV1:
            return AnalyticEventOptionsEnum.TAKE_ADS_V1;
        case LandingTypes.TAKEADSV2:
            return AnalyticEventOptionsEnum.TAKE_ADS_V2;
        default:
            return AnalyticEventOptionsEnum.WELCOME_LINK;
    }
};
