import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccountStoreInterface, BillingFormControlsEnum, PromocodesControlsEnum} from './typings';
import {
    BillingInfoInterface,
    CurrencyRestrictionsInterface,
    EarningInterface,
    PromocodeInterface,
    ReferralInfoInterface,
    WithdrawalMethodsEnum,
    WithdrawalsInterface
} from 'typings';
import {nonFieldErrorsKey} from '../../consts';


const defaultAccountState: AccountStoreInterface = {
    earningsList: [],
    earningsCount: 0,
    withdrawalsList: [],
    withdrawalsCount: 0,
    promoCodeList: [],
    promoCodeCount: 0,
    billingInfo: {} as BillingInfoInterface,
    referralInfo: {} as ReferralInfoInterface,
    accountTitle: '',
    actionState: '',
    currencyRestrictions: {},
    isError: false,
    formErrors: {},
    isWithdrawSucceed: false,
    isNeedBillingInfo: false,
    withdrawalMethod: WithdrawalMethodsEnum.PAYPAL,
    isLoading: false,
};

export const accountSlice = createSlice({
    name: 'catalog',
    initialState: defaultAccountState,
    reducers: {
        resetAccountToInitialState: state => defaultAccountState,
        hideAccountError: state => ({...state, isError: false}),
        hideAccountLoader: state => ({...state, isLoading: false}),
        showAccountError: state => ({...state, isError: true}),
        showAccountLoader: state => ({...state, isLoading: true}),
        setWithdrawSucceed: state => ({...state, isWithdrawSucceed: true}),
        setNeedBillingInfo: (state, action: PayloadAction<boolean>) => ({...state, isNeedBillingInfo: action.payload}),
        setEarningsList: (state, action: PayloadAction<EarningInterface[]>) => ({...state, earningsList: action.payload, actionState: 'earnings'}),
        setEarningsCount: (state, action: PayloadAction<number>) => ({...state, earningsCount: action.payload}),
        setWithdrawalsList: (state, action: PayloadAction<WithdrawalsInterface[]>) => ({...state, withdrawalsList: action.payload, actionState: 'withdrawals'}),
        setWithdrawalsCount: (state, action: PayloadAction<number>) => ({...state, withdrawalsCount: action.payload}),
        setPromoCodeList: (state, action: PayloadAction<PromocodeInterface[]>) => ({...state, promoCodeList: action.payload}),
        setPromoCodeCount: (state, action: PayloadAction<number>) => ({...state, promoCodeCount: action.payload}),
        setAccountTitle: (state, action: PayloadAction<string>) => ({...state, accountTitle: action.payload}),
        setCurrencyRestrictions: (state, action: PayloadAction<CurrencyRestrictionsInterface>) => ({...state, currencyRestrictions: action.payload}),
        setBillingInfo: (state, action: PayloadAction<BillingInfoInterface>) => ({...state, billingInfo: action.payload}),
        setReferralInfo: (state, action: PayloadAction<ReferralInfoInterface>) => ({...state, referralInfo: action.payload}),
        setAccountFormErrors: (state, action: PayloadAction<any>) => ({...state, formErrors: action.payload}),
        setWithdrawalMethod: (state, action: PayloadAction<{method: WithdrawalMethodsEnum; isRegionRu: boolean;}>) => {
            state.withdrawalMethod = !action.payload.isRegionRu
                ? action.payload.method
                : action.payload.method !== WithdrawalMethodsEnum.CARD ? action.payload.method : WithdrawalMethodsEnum.RU_CARD;
        },
        clearAccountFormError: (state, action: PayloadAction<BillingFormControlsEnum | PromocodesControlsEnum | typeof nonFieldErrorsKey>) => {
            const controlName: BillingFormControlsEnum | PromocodesControlsEnum | typeof nonFieldErrorsKey = action.payload;
            const newFormError = controlName ? {...state.formErrors} : {};
            delete newFormError?.[controlName];
            return {...state, formErrors: newFormError};
        },
        getEarningsList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getWithdrawalList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getPromoCodeList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getCurrencyRestrictions() {},
        getBillingInfo(state, action: PayloadAction<{ withdrawalMethod: string; }>) {},
        getReferralInfo() {},
        createWithdrawal(state, action: PayloadAction<{ data: any; }>) {},
        postBillingInfo(state, action: PayloadAction<{ data: any; withdrawalMethod: string; isGoToWithdrawal: boolean}>) {},
        registerPromoCode(state, action: PayloadAction<{ data: any; }>) {},
    },
});

export const {
    resetAccountToInitialState,
    hideAccountError,
    hideAccountLoader,
    showAccountError,
    showAccountLoader,
    setEarningsList,
    setEarningsCount,
    setWithdrawalsList,
    setWithdrawalsCount,
    setAccountTitle,
    setCurrencyRestrictions,
    setBillingInfo,
    setReferralInfo,
    setAccountFormErrors,
    clearAccountFormError,
    getEarningsList,
    getWithdrawalList,
    getCurrencyRestrictions,
    getBillingInfo,
    getReferralInfo,
    createWithdrawal,
    setWithdrawSucceed,
    setNeedBillingInfo,
    postBillingInfo,
    getPromoCodeList,
    setPromoCodeList,
    setPromoCodeCount,
    registerPromoCode,
    setWithdrawalMethod,
} = accountSlice.actions;

export const accountReducer = accountSlice.reducer;

