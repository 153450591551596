import React, {FC} from 'react';
import style from './PromoCard.module.scss';
import {ReactComponent as ClockIcon}  from 'assets/images/icons/clock.svg';
import {useTranslation} from 'react-i18next';
import {PromocodeInterface} from 'typings';
import {Trans} from 'react-i18next';


export const PromoCard: FC<{promo: PromocodeInterface}> = (props) => {
    const {promo} = props;
    const {promocode, increasing_coefficient, end_date, is_active} = promo;
    const {t} = useTranslation();


    return (
        <div className={`${style.card} ${!promo?.is_active ? style.card_inactive : ''}`}>
            <span className={style.card__status}>
                {is_active
                    ? <span className={style.card__cc}><Trans i18nKey={'statusActive'}>Active</Trans></span>
                    : <span className={style.card__ce}><Trans i18nKey={'statusInactive'}>Inactive</Trans></span>}
            </span>
            <span className={`${style.card__promo} textHeader_08`}>{promocode}</span>
            <span className={`${style.card__cashback} textBodyMedium_02`}>+ {t(`{{increasingCoefficient}} to standard cashback`, {increasingCoefficient: increasing_coefficient})}</span>
            <div className={`textBodyMedium_03 ${style.card__date}`}>
                {end_date && <>
                  <ClockIcon/>
                  <span className={`textBody_03`}>{t('Ends {{endDate}}', {endDate: end_date})}</span>
                </>}
            </div>
        </div>
    );
};
