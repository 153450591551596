import {clientid} from './clientId';
import {getTimeStamp} from './getTimeStamp';
import {v4 as uuidv4} from 'uuid';
import {getDateTimeGreenwich} from './getDateTimeGreenwich';
import {referrer} from './referrer';
import {getUrlTags} from './getUrlTags';
import {store} from 'core';
import {AppConfigInterface} from 'typings';
import {DEFAULT_LANGUAGE} from 'consts';


export const getGaParams = () => {
    const state = store.getState();
    const appConfig: AppConfigInterface = state.commonReducer.appConfig;
    return {
        'send_to': process.env.REACT_APP_GA_MEASUREMENT_ID,
        'event_option1': 'undefined',
        'event_option2': 'undefined',
        'event_option3': 'giftmio',
        'event_option4': 'undefined',

        'hit_id': getTimeStamp(),
        'uu_id': uuidv4(),
        'uu_id_2': uuidv4(),
        'datetime_greenwich': getDateTimeGreenwich(),
        'datetime_greenwich_2': getDateTimeGreenwich(),

        'client_id_ga': clientid() + '.',
        'program_id': 'undefined',
        'advertiser_id': 'undefined',
        'advcampaign_id': 'undefined',
        'publisher_id': 'undefined',
        'keycloak_id': appConfig?.user?.user_id || 'undefined',
        'website_id': 'undefined',

        'locale': window.localStorage.i18nextLng || DEFAULT_LANGUAGE,
        'page_params': 'undefined',
        'url_tags': getUrlTags(),
        'referrer_without_login_page': referrer(),
        'ab_test': 'undefined',
        'sendbox_id': 'undefined',

        'user_id': appConfig?.user?.user_id || undefined // только когда пользователь авторизован и у него есть keycloak_id
    };
};
