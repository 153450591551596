import {i18n} from 'i18n';
import airpodsIcon from 'assets/images/goods/airpods.svg';
import appleWatchIcon from 'assets/images/goods/apple-watch.svg';
import ipadIcon from 'assets/images/goods/ipad.svg';
import iphoneIcon from 'assets/images/goods/iphone.svg';
import macbookIcon from 'assets/images/goods/macbook.svg';
import ps5Icon from 'assets/images/goods/ps5.svg';


export const Products = [
    {
        title: i18n.t('Aiprods Max'),
        cashback: i18n.t('€10 Cashback'),
        img: airpodsIcon,
        id: 'airpods'
    },
    {
        title: i18n.t('Apple Watch'),
        cashback: i18n.t('€15 Cashback'),
        img: appleWatchIcon,
        id: 'appleWatch'
    },
    {
        title: i18n.t('iPad'),
        cashback: i18n.t('€10 Cashback'),
        img: ipadIcon,
        id: 'ipad'
    },
    {
        title: i18n.t('iPhone'),
        cashback: i18n.t('€15 Cashback'),
        img: iphoneIcon,
        id: 'iphone'
    },
    {
        title: i18n.t('Macbook'),
        cashback: i18n.t('€15 Cashback'),
        img: macbookIcon,
        id: 'macbook'
    },
    {
        title: i18n.t('PlayStation 5'),
        cashback: i18n.t('€15 Cashback'),
        img: ps5Icon,
        id: 'ps'
    },
];
