import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CatalogStoreInterface} from './typings';
import {transformOffersUtil} from 'utils';
import {OfferInterface} from 'typings';


const defaultCatalogState: CatalogStoreInterface = {
    offerList: [],
    isError: false,
    isLoading: false,
};

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState: defaultCatalogState,
    reducers: {
        resetCatalogToInitialState: state => defaultCatalogState,
        hideCatalogError: state => ({...state, isError: false}),
        hideCatalogLoader: state => ({...state, isLoading: false}),
        showCatalogError: state => ({...state, isError: true}),
        showCatalogLoader: state => ({...state, isLoading: true}),
        setCatalogOfferList: (state, action: PayloadAction<OfferInterface[]>) => {
            const offerList = transformOffersUtil(action.payload) as OfferInterface[];
            return {...state, offerList};
        },
        setCatalogOfferListCount: (state, action: PayloadAction<number>) => ({...state, offersCount: action.payload}),

        getCatalogOfferList(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
    },
});

export const {
    getCatalogOfferList,
    hideCatalogLoader,
    resetCatalogToInitialState,
    setCatalogOfferList,
    showCatalogLoader,
    setCatalogOfferListCount,
} = catalogSlice.actions;

export const catalogReducer = catalogSlice.reducer;

