import React, {FC} from 'react';
import actionCardStyle from './ActionCard.module.scss';
import {ActionCardInterface} from './ActionCard.interface';
import {ActionCardHeader, ActionCardBody} from './components';


export const ActionCard: FC<ActionCardInterface> = (props) => {
    const {amount, date, description, name, status} = props;

    return (
        <div className={actionCardStyle.actionCard}>
            <ActionCardHeader status={status} amount={amount}/>
            <ActionCardBody name={name || ''} description={description} date={date}/>
        </div>
    );
};
