import React, {FC} from 'react';
import mobileOverlayStyle from './MobileOverlayLayout.module.scss';
import {MobileOverlayLayoutInterface} from './MobileOverlayLayout.interface';
import {ReactComponent as CloseIcon} from 'assets/images/icons/close.svg';



export const MobileOverlayLayout: FC<MobileOverlayLayoutInterface> = (props) => {
    const {title, ...overlayProps} = props;

    return (
            <div className={mobileOverlayStyle.mobile_overlay}>
                <div className={mobileOverlayStyle.mobile_overlay__wrapper}>
                    <div className={mobileOverlayStyle.mobile_overlay__header}>
                        <span className='textHeader_09'>{title || ''}</span>
                        <button className={mobileOverlayStyle.mobile_overlay__closeButton} onClick={overlayProps.onCloseClick}>
                            <CloseIcon/>
                        </button>
                    </div>
                    {props.children}
                </div>
            </div>
    );
};
