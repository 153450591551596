import React, {FC, useEffect, useMemo} from 'react';
import {setInfoTitle} from '../../info.reducer';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Accordion} from 'components';
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {InfoMenuEnum} from 'typings';

const Contacts: FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {documentTitles} = useTransObjects();


    useDocumentTitle(documentTitles[InfoMenuEnum.CONTACTS]);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[InfoMenuEnum.CONTACTS] }],
        [documentTitles[InfoMenuEnum.CONTACTS]]);
    useBreadcrumbs(breadcrumbs);

    useEffect(() => {
        dispatch(setInfoTitle(t('Contacts')));
    }, [dispatch, t]);

    return (
        <div>
            <Accordion title={t('Top FAQs')}/>
        </div>
    );
}

export {Contacts};
export default Contacts;
