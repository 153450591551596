import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useCommonSelector, useDocumentTitle, useGeo} from 'hooks';
import {RootState} from 'core';
import {useDispatch, useSelector} from 'react-redux';
import {SearchInfo} from '../../components';
import {Accordion, JoinGiftmio, LayoutContainer, LoaderWithOverlay, OfferList, StoreList} from 'components';
import {setLandingType} from '../../landing.reducer';
import {LandingTypes} from '../../typings';
import {OfferInterface} from 'typings';

const SearchLanding: FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { isLoading} = useSelector((state: RootState) => state.landingReducer);
    const stores: OfferInterface[] = useCommonSelector<OfferInterface[]>('trending_stores');
    const offers: OfferInterface[] = useCommonSelector<OfferInterface[]>('top_offers');

    useDocumentTitle(t('Welcome to Giftmio Cashback Service'));

    useEffect(() => {
        dispatch(setLandingType(LandingTypes.SEARCH));
    }, [dispatch]);
    useGeo();

    return (
        <div>
            <SearchInfo/>
            <LayoutContainer>
                <StoreList stores={stores} title={t('Brands with Cashback')} endFragment={<JoinGiftmio/>}/>
                <OfferList offers={offers} title={t('Store with Cashback')} endFragment={<JoinGiftmio/>}/>
                <Accordion isLanding={true}/>
            </LayoutContainer>
            {isLoading && <LoaderWithOverlay/>}
        </div>
    );
};

export {SearchLanding};
export default SearchLanding;
