import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'components';
import {ChooseCountryInterface} from './ChooseCountry.interface';
import style from '../../CountryRegistrationModal.module.scss';
import {ReactComponent as DoneIcon} from 'assets/images/icons/done.svg';
import {ReactComponent as BackIcon} from 'assets/images/icons/back.svg';
import {FlagIcons} from 'consts';


export const ChooseCountryReg: FC<ChooseCountryInterface> = (props) => {
    const {currentGeo, geo, onChangeClick, onBackClick} = props;
    const {t} = useTranslation();

    return (
        <Fragment>
            <div className={style.dialog__header}>
            <h2 className={`${style.dialog__header_title} textHeader_07`}>
                <button onClick={onBackClick} className={style.page_title_icon} title={t('Go back')}><BackIcon/></button>
                {t('Choose your country')}
            </h2>
            <p className={`${style.dialog__text} textBody_04`}>{t(`Your choice impacts the offers and stores available to you.`)}</p>
            </div>
            <div className={style.dialog__content}>
                {geo.map(geoValue => (
                    <div key={Math.random()} className={`${style.dialog__countries_wrap} ${geoValue?.countries[0]?.value === 'other' ? style.dialog__countries_wrap_other : ''}`}>
                        <div className={style.dialog__countries_text}>
                            <h3 className={`$textHeader_09`}>{geoValue.region}</h3>
                            {geoValue?.countries[0]?.value === 'other' &&
                              <p className={`${style.dialog__text} textBody_04`}>{t(`Have not found your country in the list? Choose this variant.`)}</p>
                            }
                        </div>
                        <div className={style.dialog__countries}>
                            {geoValue.countries.map(country => (
                                <Button textContent={country.text}
                                        key={Math.random()}
                                        textAlign='left'
                                        size='s'
                                        img={<img alt={'Location icon'} className={style.dialog__img_icon} src={FlagIcons[country.value as string]}/>}
                                        IconRight={currentGeo === country.value ? DoneIcon : undefined}
                                        theme={'empty'}
                                        iconRightTheme={'done'}
                                        onClick={() => onChangeClick ? onChangeClick(country.value) : ''}/>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {/*<div className={`${style.dialog__footer} controls controls_align_justify`}>*/}
            {/*    <Button theme={'stroke'}*/}
            {/*            size='xxl'*/}
            {/*            onClick={onBackClick}*/}
            {/*            textContent={t('Back')}/>*/}
            {/*    <Button className={style.dialog__footer_btn_confirm}*/}
            {/*            disabled={!currentGeo}*/}
            {/*            onClick={() => onChangeClick ? onChangeClick(currentGeo) : ''}*/}
            {/*            size='xxl'*/}
            {/*            textContent={t('Confirm')}/>*/}
            {/*</div>*/}
        </Fragment>
    );
};
