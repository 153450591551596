export enum LanguagesEnum {
    EN = 'en',
    PL = 'pl',
    PR = 'pt',
    DE = 'de',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
    RU = 'ru',
}
