import AE from 'assets/images/flags/circle/ae.svg';
import AR from 'assets/images/flags/circle/ar.svg';
import AU from 'assets/images/flags/circle/au.svg';
import AT from 'assets/images/flags/circle/at.svg';
import BE from 'assets/images/flags/circle/be.svg';
import BR from 'assets/images/flags/circle/br.svg';
import CA from 'assets/images/flags/circle/ca.svg';
import CH from 'assets/images/flags/circle/ch.svg';
import CL from 'assets/images/flags/circle/cl.svg';
import CO from 'assets/images/flags/circle/co.svg';
import CZ from 'assets/images/flags/circle/cz.svg';
import DE from 'assets/images/flags/circle/de.svg';
import DK from 'assets/images/flags/circle/dk.svg';
import ES from 'assets/images/flags/circle/es.svg';
import FI from 'assets/images/flags/circle/fi.svg';
import FR from 'assets/images/flags/circle/fr.svg';
import GB from 'assets/images/flags/circle/gb.svg';
import GR from 'assets/images/flags/circle/gr.svg';
import HU from 'assets/images/flags/circle/hu.svg';
import IE from 'assets/images/flags/circle/ie.svg';
import IN from 'assets/images/flags/circle/in.svg';
import IT from 'assets/images/flags/circle/it.svg';
import KZ from 'assets/images/flags/circle/kz.svg';
import MX from 'assets/images/flags/circle/mx.svg';
import NL from 'assets/images/flags/circle/nl.svg';
import NO from 'assets/images/flags/circle/no.svg';
import NZ from 'assets/images/flags/circle/nz.svg';
import other from 'assets/images/flags/circle/other.svg';
import PE from 'assets/images/flags/circle/pe.svg';
import PH from 'assets/images/flags/circle/ph.svg';
import PL from 'assets/images/flags/circle/pl.svg';
import PT from 'assets/images/flags/circle/pt.svg';
import RU from 'assets/images/flags/circle/ru.svg';
import SE from 'assets/images/flags/circle/se.svg';
import UA from 'assets/images/flags/circle/ua.svg';
import US from 'assets/images/flags/circle/us.svg';
import ZA from 'assets/images/flags/circle/za.svg';


export const FlagCircleIcons = {
    AE: AE,
    AR: AR,
    AU: AU,
    AT: AT,
    BE: BE,
    BR: BR,
    CA: CA,
    CH: CH,
    CL: CL,
    CO: CO,
    CZ: CZ,
    DE: DE,
    DK: DK,
    ES: ES,
    FI: FI,
    FR: FR,
    GB: GB,
    GR: GR,
    HU: HU,
    IE: IE,
    IN: IN,
    IT: IT,
    KZ: KZ,
    MX: MX,
    NL: NL,
    NO: NO,
    NZ: NZ,
    other: other,
    PE: PE,
    PH: PH,
    PL: PL,
    PT: PT,
    RU: RU,
    SE: SE,
    UA: UA,
    US: US,
    ZA: ZA,
};
