export const clientid = () => {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    const raw = (match) ? decodeURIComponent(match[1]) : null;
    if (raw) {
        match = raw.match(/(\d+\.\d+)$/);
    }
    const gacid = (match) ? match[1] : null;
    if (gacid) {
        return gacid;
    }
};
