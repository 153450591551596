import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import style from './Main.module.scss';
import {Button, LayoutContainer} from 'components';
import {Info} from '../info';
import {useLandingMain} from '../../hooks';
import {useMedia} from 'hooks';
import {FastInfo} from '../fastInfo';
import {Registration} from '../registration';
import {LandingTypes} from '../../typings';
import {TakingYou} from '../takingYou';
import classNames from 'classnames/bind';
import {takeAdsName} from '../../consts';
import {ReactComponent as ChromeIcon} from 'assets/images/icons/chrome.svg';

export const Main: FC = () => {
    const isMobile = useMedia();
    const {t} = useTranslation();
    const {landingType, handleCancelClick, handleRegistrationClick} = useLandingMain();
    const mainClassName = classNames(style.main_wrapper, [style[`main_wrapper_${landingType}`]]);

    return (
        <div className={mainClassName}>
            <LayoutContainer>
                {!!landingType && <div className={style.main}>
                    <Info onCancelClick={handleCancelClick} onActivateClick={handleRegistrationClick}/>
                    {landingType.includes(takeAdsName)
                        ? <TakingYou onCancelClick={(eventName) => handleCancelClick(eventName)} onActivateClick={handleRegistrationClick}/>
                        : <Registration/>}
                    {isMobile && <FastInfo/>}
                    {(isMobile && landingType === LandingTypes.DIRECTV2) &&
                      <div className={style.btns}>
                        <p className={`textBodyMedium_01}`}>
                            {t('Never miss a cashback opportunity while shopping online')}
                        </p>
                          <Button onClick={handleCancelClick}
                                  theme={`${landingType === LandingTypes.DIRECTV2 ? 'stroke' : 'fill'}`}
                                  isFullWidth={true}
                                  kind={'ellipse'}
                                  textContent={t('No, I hate saving')}/>
                          <Button onClick={handleRegistrationClick}
                                  IconLeft={ChromeIcon}
                                  isIconAbsolute={true}
                                  isFullWidth={true}
                                  textContent={t('Yes, add Chrome extension')}
                                  kind='ellipse'/>
                      </div>

                    }
                </div>}
            </LayoutContainer>
        </div>
    );
};
