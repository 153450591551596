import {OfferInterface, SmartOfferInterface} from 'typings';

export const transformOffersUtil = (actionPayload: OfferInterface[] | SmartOfferInterface[]) => {
    return actionPayload.map((store: OfferInterface | SmartOfferInterface) => {
        const cashback = store.cashback_percent_size || store.cashback_currency_size;
        const cashbackOrigin = store.cashback_percent_size_origin || store.cashback_currency_size_origin;
        const cashbackOriginVal = cashback === cashbackOrigin ? null : cashbackOrigin;
        return {...store, ...{id: Math.random(), cashback_origin: cashbackOriginVal, cashback: store.cashback_percent_size ? `${cashback}%` : `${cashback} ${store.currency}`}};
    })
}
