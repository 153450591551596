import React, {FC} from 'react';
import actionsHeaderStyle from './ActionsHeader.module.scss';
import {useMedia} from 'hooks';
import {useTranslation} from 'react-i18next';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, UserInterface} from 'typings';
import {Button} from 'components';
import confirmedLogo from 'assets/images/account/dollar-confirmed.svg';
import awaitingLogo from 'assets/images/account/dollar-awaiting.svg';
import {AccountRoutesPathEnum, MainRoutesEnum} from 'core';
import {sendAnalyticEvents} from 'utils';
import { useNavigate } from 'react-router-dom';



export const ActionsHeader: FC<{ user: UserInterface | undefined; isWithdrawals?: boolean}> = (props) => {
    const {user, isWithdrawals = false} = props;
    const {t} = useTranslation();
    const isMobile = useMedia();
    const navigate = useNavigate();

    const imageSize = '40px';

    const handleWithdrawalsClick = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.MY_CASHBACK
        };
        const postHogOptions = {
            button_name: AnalyticEventOptionsEnum.WITHDRAWALS,
            button_location: AnalyticEventOptionsEnum.MY_CASHBACK
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_WITHDRAWALS, {gaOptions, postHogOptions});
        navigate(`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.WITHDRAWAL}`);
    }

    return (
        <div className={actionsHeaderStyle.content_wrapper}>
            <div className={actionsHeaderStyle.content}>
                <div className={`${actionsHeaderStyle.content__item} ${actionsHeaderStyle.content__confirmed}`}>
                    {!isMobile && <img src={confirmedLogo} alt={t('Confirmed')} height={imageSize} width={imageSize}/>}
                    <div className={actionsHeaderStyle.content__item_info}>
                        <span className={`textBodyMedium_04 ${actionsHeaderStyle.content__item_info_text}`}>{t('Confirmed')}:</span>
                        <span className={`textBodyMedium_01 ${actionsHeaderStyle.content__item_info_value} ${actionsHeaderStyle.content__item_info_value_brand}`}>{user?.balance?.confirmed}</span>
                    </div>
                    {(!isMobile && !isWithdrawals) && <Button className={actionsHeaderStyle.content__item_info_btn} kind={'ellipse'} textContent={t('Withdrawals')} onClick={handleWithdrawalsClick}/>}

                </div>
                <div className={`${actionsHeaderStyle.content__item} ${actionsHeaderStyle.content__awaiting}`}>
                    {!isMobile && <img src={awaitingLogo} alt={t('Awaiting')} height={imageSize} width={imageSize}/>}
                    <div className={actionsHeaderStyle.content__item_info}>
                        <span className={`textBodyMedium_04 ${actionsHeaderStyle.content__item_info_text}`}>{t('Awaiting')}:</span>
                        <span className={`textBodyMedium_01 ${actionsHeaderStyle.content__item_info_value}`}>{user?.balance?.awaiting}</span>
                    </div>
                </div>
            </div>
            {(isMobile && !isWithdrawals) && <Button kind={'ellipse'} textContent={t('Withdrawals')} isFullWidth={true} onClick={handleWithdrawalsClick}/>}
        </div>
    );
};
