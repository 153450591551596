import React, {FC, Fragment} from 'react';
import style from './BlockContainer.module.scss';
import {useMedia} from 'hooks';
import classNames from 'classnames/bind';
import {BlockContainerInterface} from './BlockContainer.interface';
import {LayoutContainer} from '../LayoutContainer/LayoutContainer';


export const BlockContainer: FC<BlockContainerInterface> = (props) => {
    const isMobile = useMedia();
    const {title, theme = 'light', withoutIndent = false, withLayout = false, endFragment} = props;
    const bcClassName =  classNames(
        style.block,
        {[style.block_without_indent]: withoutIndent},
    );
    return (
        <Fragment>
            {withLayout ?
                <div className={classNames([style[`block_wrap_theme_${theme}`]])}>
                    <LayoutContainer>
                        <div className={bcClassName}>
                            {title && <h2 className={`${style.block_title} ${isMobile ? 'textHeader_07' : 'textHeader_06'}`}>{title}</h2>}
                            {props.children}
                            {endFragment}
                        </div>
                    </LayoutContainer>
                </div>
                : <div className={bcClassName}>
                    {title && <h2 className={`${style.block_title} ${isMobile ? 'textHeader_07' : 'textHeader_06'}`}>{title}</h2>}
                    {props.children}
                    {endFragment}
                </div>
            }
        </Fragment>
    );
};
