import { MainRoutesEnum, setBreadcrumbs } from "core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BreadcrumbsInterface } from "typings";

export const useBreadcrumbs = (breadrumbs: BreadcrumbsInterface[]) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { title: t("Home"), to: `${MainRoutesEnum.HOME}/` },
        ...breadrumbs,
      ])
    );

    return () => {
      dispatch(setBreadcrumbs([]));
    };
  }, [dispatch, t, breadrumbs]);
};
