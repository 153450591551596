import {useEffect, useState} from 'react';

export const useMedia = (query = '(max-width: 1024px)') => {
    const [isNarrowScreen, setIsNarrowScreen] = useState(false);
    useEffect(() => {
        const mediaWatcher = window.matchMedia(query);
        setIsNarrowScreen(mediaWatcher.matches);

        function updateIsNarrowScreen(e: MediaQueryListEvent) {
            setIsNarrowScreen(e.matches);
        }

        mediaWatcher.addEventListener('change', updateIsNarrowScreen);
        return function cleanup() {
            mediaWatcher.removeEventListener('change', updateIsNarrowScreen);
        };
    }, [query]);
    return isNarrowScreen;
};
