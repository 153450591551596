import React, {FC, useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {MainRoutesEnum, RootState} from 'core';
import {
    OfferInterface,
    AppConfigInterface,
    UserInterface,
    AnalyticEventOptionsEnum,
    PostHogOptionsEnum,
    AnalyticEventNameEnum,
    CategoriesInterface,
    BreadcrumbsInterface
} from 'typings';
import {useBreadcrumbs, useCommonSelector, useDocumentTitle, useMedia, useTransObjects} from 'hooks';
import {Accordion, LoaderWithOverlay, StoreList} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import {SsoConst} from 'consts';
import {getOfferDetails, setOpenFromCategory} from '../../offer.reducer';
import {OfferContent, OfferHeader, OfferSidebar} from '../../components';
import {getSsoLocales, sendAnalyticEvents} from 'utils';



const OfferPage: FC = () => {
    const dispatch = useDispatch();
    const { offerId } = useParams();
    const isMobile = useMedia();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const {offerDetails, isLoading, isError, openFromCategory} = useSelector((state: RootState) => state.offerReducer);
    const stores: OfferInterface[] = useCommonSelector<OfferInterface[]>('trending_stores');
    const [user, setUser] = useState<UserInterface>();
    const location = useLocation();
    const categories = useCommonSelector<CategoriesInterface[]>('categories');
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsInterface[]>([])
    const {tableTexts, categoriesTrans} = useTransObjects();
    const programName = offerDetails.name;
    const offerCategory = useMemo(
        () => openFromCategory || categories.find(({ name }) => name === offerDetails.short_description),
        [categories, offerDetails, openFromCategory]
    );
    const offerCategoryName = offerCategory?.slug ? categoriesTrans[offerCategory.slug] : null;
    const handleCashbackClick = (isMain) => {
        const locationHref = !!user
            ? offerDetails?.traffic_url + ''
            : `${SsoConst.login}?${getSsoLocales()}&next=${location.pathname}`;
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.PROGRAM_CARD
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.BUY_WITH_CASHBACK,
            button_location: AnalyticEventOptionsEnum.PROGRAM_CARD
        };
        sendAnalyticEvents(isMain ? AnalyticEventNameEnum.CLICK_GET_CASHBACK : AnalyticEventNameEnum.CLICK_BUY_WITH_CASHBACK, {gaOptions, postHogOptions});
        const newWindow = window.open(locationHref, !!user ? '_blank' : '_self', 'noopener,noreferrer');
        if (newWindow)  {
            newWindow.opener = null;
        }
    }

    useDocumentTitle(programName);
    useBreadcrumbs(breadcrumbs);

    useEffect(() => {
        if (!appConfig?.lang) {
            return;
        }
        setUser(appConfig.user);
    }, [appConfig]);

    useEffect(() => {
        const controller = new AbortController();

        if (!offerId) {
            return;
        }
        dispatch(getOfferDetails({offerId, controller}));

        return () => {
            controller.abort();
            dispatch(setOpenFromCategory(null));
        };
    }, [offerId, dispatch]);

    useEffect(() => {
        setBreadcrumbs(
          offerCategory
            ? [
                { title: offerCategoryName, to: `${MainRoutesEnum.CATALOG}/${offerCategory.slug}` },
                { title: programName },
              ]
            : [{ title: programName }]
        );
      }, [programName, offerCategory, offerCategoryName]);

    return (
        <main className={'page page_aside-left'}>
            {!!programName && <OfferHeader offer={offerDetails} handleCashbackClick={handleCashbackClick}/>}
            {(!isMobile && !!programName) &&
              <OfferSidebar popularStores={stores}/>
            }
            <section className="page__content page__blank">
                <div className="loader__container">
                    {isError && !programName &&
                      <p className='page__error_container'>
                        <b className='textHeader_04'>{tableTexts.responseValidationFailedTitle}</b>
                        <br/>
                        <span className='textBody_01'>{tableTexts.responseValidationFailedDescription}</span>
                      </p>}
                    {!!offerDetails?.name
                        && <OfferContent offer={offerDetails} handleCashbackClick={handleCashbackClick}/>}
                    {isLoading && <LoaderWithOverlay/>}
                </div>
                {isMobile && <StoreList stores={stores}/>}
                <Accordion/>

            </section>
        </main>

    );
}

export {OfferPage};
export default OfferPage;
