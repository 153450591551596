import React, {FC, useEffect, useState} from 'react';
import {useRoutes} from 'react-router-dom';
import {accountRoutes} from './consts';
import {PageTitle, UserMenu} from 'components';
import {useBanned, useMedia} from 'hooks';
import {UserInterface} from 'typings';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {SsoConst} from 'consts';
import {getSsoLocales} from 'utils';


const Account: FC = () => {
    const isMobile = useMedia();
    const {accountTitle} = useSelector((state: RootState) => state.accountReducer);
    const {appConfig} = useSelector((state: RootState) => state.commonReducer);
    const [user, setUser] = useState<UserInterface>();

    useBanned();

    useEffect(() => {
        if (!appConfig?.lang) {
            return;
        }
        if (!appConfig.user) {
            window.location.href = `${SsoConst.login}?${getSsoLocales()}`;
            return;
        }
        setUser(appConfig.user);
    }, [appConfig]);

    return (
        <main className="page page_aside-left page_aside-left_account">
            <PageTitle showBackBtn={isMobile} text={accountTitle}/>
            {!isMobile && <div className='page__aside page__aside_account blank'>
                <UserMenu user={user} isAccount={true}/>
            </div>}
            {useRoutes(accountRoutes)}
        </main>
    );

};

export {Account};
export default Account;
