import {posthog} from 'posthog-js';
import {isLandingPage} from 'utils';
import {ServicesUrls, PostHogConst} from 'consts';

export const initPostHogScript = () => {
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'prod' && !isLandingPage(false, true)) {
        posthog.init(
            PostHogConst.token,
            {
                api_host: ServicesUrls.postHog,
                autocapture: false,
                capture_pageview: false,
                capture_pageleave: false,
            }
        );
    }
};
