import Keycloak from 'keycloak-js';

const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
// const KEYCLOAK_URL = 'https://stage-current.login.giftmio.com/auth/';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
const REALM = 'giftmio';

const _kc =  new Keycloak({
    url: KEYCLOAK_URL,
    realm: REALM,
    clientId: CLIENT_ID,
});

const initKeycloak = (onAuthenticatedCallback, onNotAuthCallback, onAuthenticatedFailed) => {
    _kc.init({pkceMethod: 'S256', onLoad: 'check-sso', checkLoginIframe: true})
        .then((authenticated) => {

            if (!authenticated) {
                onNotAuthCallback();
                return;
            }
            onAuthenticatedCallback();
        })
        .catch((err) => {
            onAuthenticatedFailed(err?.message || 'Failed to initialize in Keycloak');
        });
};

const createLoginUrl = (obj) => _kc.createLoginUrl(obj);
const idTokenParsed = () => _kc.idTokenParsed;
const clientId = () => _kc.profile;
const clientSecret = () => _kc.tokenParsed;

const KeycloakService = {
    initKeycloak,
    createLoginUrl,
    idTokenParsed,
    clientId,
    clientSecret,
};

export default KeycloakService;
