import {TooltipThemes} from '@design-system/ui-kit';
import axios, {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from 'axios';
import {DEFAULT_LANGUAGE, FallbackErrorTextConst, SsoConst} from 'consts';
import {MainRoutesEnum, notificationListPushItem, store} from 'core';
import {i18n} from 'i18n/config';
import {HttpStatusCodesEnum, SendRequestParamsInterface} from 'typings';
import {getCookie} from '../common';


export const sendRequest = async <ResponseInterface, RequestBodyInterface = undefined>({requestHeaders, request, callbacks}: SendRequestParamsInterface<RequestBodyInterface>) => {
    try {
        const csrfToken = getCookie('csrftoken');
        const csrfTokenHeader = request.method === 'POST' ? {'X-CSRFToken': csrfToken} as AxiosRequestHeaders : {} as AxiosRequestHeaders;
        const headers = {
            'Content-Type': 'application/json',
            'Accept-Language': DEFAULT_LANGUAGE,
            ...csrfTokenHeader,
            ...requestHeaders,
        };
        const requestParams: AxiosRequestConfig<RequestBodyInterface> = {
            headers: headers,
            ...request,
        };
        const response: AxiosResponse<ResponseInterface> = await axios(requestParams);
        return response.data;
    } catch (error: any) {
        const {handleServiceError} = callbacks;

        if (
            error.response?.status === HttpStatusCodesEnum.FORBIDDEN ||
            error.response?.status === HttpStatusCodesEnum.UNAUTHORIZED
        ) {
            window.location.href = SsoConst.login;
        }

        if (
            error.response?.status === HttpStatusCodesEnum.INTERNAL_SERVER_ERROR &&
            !window.location.href.includes(MainRoutesEnum.INTERNAL_ERROR)
        ) {
            window.location.href = MainRoutesEnum.INTERNAL_ERROR;
        }

        if (
            error.response?.status > HttpStatusCodesEnum.BAD_REQUEST &&
            error.response?.status < HttpStatusCodesEnum.INTERNAL_SERVER_ERROR
        ) {
            handleServiceError();
        }

        if (error.response?.status === HttpStatusCodesEnum.BAD_REQUEST) {
            throw (error.response.data);
        }

        const errorText = error.response?.status === HttpStatusCodesEnum.INTERNAL_SERVER_ERROR
            ? `${i18n.t('Service is unavailable')}: ${error.message})`
            : error?.request
                ? `${i18n.t('Could not process response from the server')}: ${error.message}`
                : `${i18n.t('Caught an internal unhandled exception')}: ${error.message || error}`;

        store.dispatch(notificationListPushItem({
            id: Date.now().toString(),
            title: FallbackErrorTextConst,
            description: `${errorText}`,
            theme: TooltipThemes.actionErrorPrimary,
        }));
        throw new Error(errorText);
    }
};
