import {useDispatch, useSelector} from 'react-redux';
import {
    CommonStoreInterface,
    getAppConfig,
    getCategories,
    getTopOffers,
    getTrendingStores,
    getGeo,
    RootState,
    setFetched
} from '../core';
import { useEffect } from 'react';

const dispatchMap = {
    appConfig: getAppConfig,
    categories: getCategories,
    trending_stores: getTrendingStores,
    top_offers: getTopOffers,
    geo: getGeo,
}

export const useCommonSelector = <T>(prop: (keyof CommonStoreInterface)): T => {
    const dispatch = useDispatch();    
    const fetched: boolean = useSelector((state: RootState) => state.commonReducer.fetched[prop]);

    useEffect(() => {
        if (!fetched) {
            dispatch(dispatchMap[prop]());
            dispatch(setFetched(prop));
        }
    }, [dispatch, fetched, prop])

    return useSelector((state: RootState) => state.commonReducer[prop]) as T;
};
