import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DirectUriInterface, IdentityProvidersDto, LandingStoreInterface, SearchDirectLinkDto} from './typings';


const defaultLandingState: LandingStoreInterface = {
    identityProviders: [],
    uriParams: {} as DirectUriInterface,
    searchOffer: {} as SearchDirectLinkDto,
    jwtToken: '',
    landingType: '',
    geoBonus: '',
    showCountryDialog: false,
    showUnloadAlert: true,
    isError: false,
    formErrors: {},
    isLoading: false,
};

export const landingSlice = createSlice({
    name: 'landing',
    initialState: defaultLandingState,
    reducers: {
        resetLandingToInitialState: state => defaultLandingState,
        hideLandingError: state => ({...state, isError: false}),
        hideLandingLoader: state => ({...state, isLoading: false}),
        showLandingError: state => ({...state, isError: true}),
        showLandingLoader: state => ({...state, isLoading: true}),
        setIdentityProviders: (state, action: PayloadAction<IdentityProvidersDto>) => ({...state, identityProviders: action.payload.identityProviders}),
        setSearchOffer: (state, action: PayloadAction<SearchDirectLinkDto>) => ({...state, searchOffer: action.payload}),
        setJwtToken: (state, action: PayloadAction<string>) => ({...state, jwtToken: action.payload}),
        setLandingType: (state, action: PayloadAction<string>) => ({...state, landingType: action.payload}),
        setShowUnloadAlert: (state, action: PayloadAction<boolean>) => ({...state, showUnloadAlert: action.payload}),
        setUriParams: (state, action: PayloadAction<DirectUriInterface>) => ({...state, uriParams: action.payload}),
        setRegistrationFormErrors: (state, action: PayloadAction<any>) => ({...state, formErrors: action.payload}),
        showCountryModal: state => ({...state, showCountryDialog: true}),
        hideCountryModal: state => ({...state, showCountryDialog: false}),
        setGeoBonus: (state, action: PayloadAction<string>) => ({...state, geoBonus: action.payload}),

        createSsoUser(state, action: PayloadAction<{ data: any; headers: any}>) {},
        getIdentityProviders()  {},
        getSearchOffer(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
    },
});

export const {
    getSearchOffer,
    getIdentityProviders,
    hideLandingError,
    hideLandingLoader,
    resetLandingToInitialState,
    showLandingError,
    showLandingLoader,
    setIdentityProviders,
    setUriParams,
    setJwtToken,
    setSearchOffer,
    setRegistrationFormErrors,
    setLandingType,
    createSsoUser,
    showCountryModal,
    hideCountryModal,
    setShowUnloadAlert,
    setGeoBonus
} = landingSlice.actions;

export const landingReducer = landingSlice.reducer;

