import {ServicesUrls, widgetLanguages} from 'consts';

export const initUseResponseWidget = (lang: string) => {
    const responseWidgetId = 'UR_initiator';
    const iid = 'uriid_da39a3ee5e6b4b0d3255bfef95601890afd80709';
    document.querySelector(`#${responseWidgetId}`)?.remove();
    const $giftmioApp = document.getElementById('giftmio-app') as HTMLDivElement;
    const $chatHandleTab =  $giftmioApp?.nextElementSibling?.className.includes('UR_chatHandleTab')
        ? $giftmioApp?.nextElementSibling : null;
    const $chatIcon =  $chatHandleTab?.nextElementSibling?.className.includes('UR_chatElement') ?
        $chatHandleTab?.nextElementSibling : null;
    const $chatIconNext =  $chatIcon?.nextElementSibling?.className.includes('UR_chatElement') ?
        $chatIcon?.nextElementSibling : null;
    $chatHandleTab?.remove();
    $chatIcon?.remove();
    $chatIconNext?.remove();
    window.sessionStorage.removeItem('urchat_tabId');
    window.sessionStorage.removeItem('urchat_isOpen');
    const langNumber = widgetLanguages[lang] || widgetLanguages.en;
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.id = responseWidgetId;
    script.src = ServicesUrls.responseWidgetUrl + iid + `-${langNumber}.js`;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
};
