import React, {FC} from 'react';
import style from './LayoutContainer.module.scss';
import classNames from 'classnames/bind';

interface ILayoutContainer {
    isLandingPage?: boolean;
}

export const LayoutContainer: FC<ILayoutContainer> = (props) => {
    const {isLandingPage = false} = props;
    return (
        <div className={classNames({[style.layoutContainer]: !isLandingPage})}>
            {props.children}
        </div>
    );
};
