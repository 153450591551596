import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setInfoTitle} from '../info.reducer';

export const useAccountTitle = (title: string) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!title) {
            return;
        }
        dispatch(setInfoTitle(title));
    }, [dispatch, title]);
    return null;
};
