import React, {FC} from 'react';
import offerSidebarStyle from './OfferSidebar.module.scss';
import {useTranslation} from 'react-i18next';
import {LinkComponent} from 'components';
import {OfferSidebarInterface} from './OfferSidebar.interface';


export const OfferSidebar: FC<OfferSidebarInterface> = (props) => {
    const {popularStores} = props;
    const {t} = useTranslation();

    return (
        <aside className="page__aside page__aside_sticky blank">
            {!!popularStores?.length && <div className={offerSidebarStyle.cs_sidebar}>
               <span className={`${offerSidebarStyle.cs_sidebar__title} textHeader_09`}>{t('Popular stores')}</span>
               {popularStores.map(store => (
                    <div className={offerSidebarStyle.cs_sidebar__item} key={store.id}>
                        <LinkComponent theme={'stroke'} textContent={store.name} to={`/offer/${store.slug}`}/>
                    </div>
               ))}
            </div>}
        </aside>
    );
};

