import React, {FC, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppConfigInterface} from 'typings';
import {useCommonSelector} from 'hooks';
import {getFakeUrls, MainRoutesEnum, RootState} from 'core';

const FakeLanding: FC = () => {
    const dispatch = useDispatch();
    const {fake_urls} = useSelector((state: RootState) => state.commonReducer);
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (fake_urls.traffic_url === undefined) {
            return;
        }

        if (fake_urls?.traffic_url) {
            window.location.href = fake_urls.traffic_url;
        } else {
            let link = `${MainRoutesEnum.HOME}/?`
            let utmSource = searchParams.get('utm_source');
            let utmMedium = searchParams.get('utm_medium');
            let utmCampaign = searchParams.get('utm_campaign');
            if (utmSource) {
                link += `&utm_source=${utmSource}`
            }
            if (utmMedium) {
                link += `&utm_medium=${utmMedium}`
            }
            if (utmCampaign) {
                link += `&utm_campaign=${utmCampaign}`
            }
            navigate(link);
        }
    }, [searchParams, fake_urls, navigate]);

    useEffect(() => {
        if (!appConfig?.lang) {
            return;
        }
        const controller = new AbortController();
        dispatch(getFakeUrls({queryParams: searchParams, controller}));
    }, [searchParams, appConfig, dispatch]);

    return <></>;
};

export {FakeLanding};
export default FakeLanding;
