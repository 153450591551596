import React, {FC, Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import style from './HeaderRegion.module.scss';
import {useCommonSelector, useMedia} from 'hooks';
import locationImg from 'assets/images/header/location.svg';
import {GeoInterface, IDropdownOption} from 'typings';
import {FlagCircleIcons} from 'consts';
import {useDispatch} from 'react-redux';
import {showCountryDialog} from 'core';

export const HeaderRegion: FC<{country: string;}> = (props) => {
    const {country} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useMedia();
    const geo: GeoInterface[] = useCommonSelector<GeoInterface[]>('geo');
    const [currentGeo, setCurrentGeo] = useState<IDropdownOption>();
    const [geoValue, setGeoValue] = useState<string>('');
    const [showGeoImage, setShowGeoImage] = useState<boolean>(false);
    const size = '24px';

    const handleGeoClick = () => {
        dispatch(showCountryDialog());
    }
    useEffect(() => {
        if (!geo?.length) {
            return;
        }
        let region;

        geo.forEach(geoValue => {
            if (!!region) {
                return;
            }
            region = geoValue.countries.find(c=> c.value === country);
        })
        setCurrentGeo(region);
        const regionValue = region?.value ? region.value + '' : '';

        setGeoValue(regionValue);
        setShowGeoImage(true)
    }, [country, geo]);

    return (
        <Fragment>
            {showGeoImage && <button onClick={handleGeoClick} className={style.region}>
                {!currentGeo && <img alt={'Location icon'} className={style.region__img} src={locationImg}/>}
                {currentGeo && <img alt={'Location icon'} className={style.region__img} height={size} width={size} src={FlagCircleIcons[geoValue]}/>}
                {(currentGeo && currentGeo.value === 'other' && !isMobile) && <span className={`textBodyMedium03`}>{currentGeo.text}</span>}
                {(currentGeo && currentGeo.value !== 'other') && <span className={`textBodyMedium03`}>{currentGeo.value}</span>}
                {(!currentGeo && !isMobile) && <span className={`textBodyMedium03`}>{t('Select region')}</span>}
            </button>}
        </Fragment>

    );
};
