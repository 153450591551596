export const MainLanguages = [
    {
        text: 'EN',
        value: 'en'
    },
    {
        text: 'RU',
        value: 'ru'
    },
    {
        text: 'DE',
        value: 'de'
    },
    {
        text: 'PL',
        value: 'pl'
    },
    {
        text: 'ES',
        value: 'es'
    },
    {
        text: 'PT',
        value: 'pt'
    }
];
