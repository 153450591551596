import React, {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SearchIcon} from 'assets/images/icons/search.svg';
import {Input} from '@design-system/ui-kit';
import {SearchInputInterface} from "./SearchInput.interface";
import {useDebounce} from 'hooks';


export const SearchInput: FC<SearchInputInterface> = (props) => {
    const { placeholder, onChange, searchValue = '', debounceTime = 500, clearValue = false} = props;
    const {t} = useTranslation();
    const [searchText, setSearchText] = useState(searchValue || '');
    const debouncedSearchTerm = useDebounce(searchText, debounceTime);

    const handleTextChange = useCallback(() => {
        onChange(debouncedSearchTerm);
    }, [debouncedSearchTerm, onChange]);

    useEffect(() => {
        handleTextChange();
    }, [debouncedSearchTerm, handleTextChange]);

    useEffect(() => {
        setSearchText(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (clearValue) {
            setSearchText('');
        }
    }, [clearValue]);


    return (
        <Input
            onChange={setSearchText}
            placeholder={placeholder || t('Search')}
            startIcon={SearchIcon}
            value={searchText}
            type='text'/>
    );
};
