import { Fragment } from "react";
import { RootState } from "core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import arrowRight from "assets/images/icons/arrow-right.svg";

import styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs = () => {
  const { breadrumbs } = useSelector((state: RootState) => state.commonReducer);

  if (breadrumbs.length < 2) {
    return null;
  }

  return (
    <ul className={styles.breadcrumbs}>
      {breadrumbs.map(({ title, to }) => (
        <Fragment key={`crumb ${title}`}>
          <li className={styles.crumb}>
            {to && (
              <Link className={styles.link} to={to}>
                {title}
              </Link>
            )}

            {!to && title}
          </li>

          {to && (
            <li className={styles.separ}>
              <img className={styles.arrow} src={arrowRight} alt="arrow" />
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  );
};
