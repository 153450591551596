import React, {FC, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDocumentTitle, useMedia} from 'hooks';
import style from './ExtensionLanding.module.scss';
import {Button} from 'components';
import {LandingTypes} from '../../typings';
import {setLandingType} from '../../landing.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {useLandingMain} from '../../hooks';
import {RootState} from 'core';
import {ReactComponent as ChromeIcon} from 'assets/images/icons/chrome.svg';


const ExtensionLanding: FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isMobile = useMedia();
    const {handleCancelClick, handleRegistrationClick} = useLandingMain();
    const {uriParams, searchOffer} = useSelector((state: RootState) => state.landingReducer);
    const action = searchOffer?.action || '10%';

    useDocumentTitle(uriParams.domain || t('External website landing'));

    useEffect(() => {
        dispatch(setLandingType(LandingTypes.DIRECT_EXTENSION));
    }, [dispatch]);

    return (
        <div className={style.landing}>
            <div className={style.content}>
                <div className={style.content_loader}>
                    <p className={`textBodyMedium_L ${style.brand_text}`}>
                        {t('Taking you to')}
                    </p>
                    <span className={style.loader}></span>
                </div>
                {
                    searchOffer?.image
                        ? <img src={searchOffer.image} className={style.inmage} height={100}/>
                        : <h1 className={`${isMobile ? 'textHeader_04' : 'textHeader_01'}`}>{t('External website')}</h1>
                }

                <p className={style.content_text}>
                    <Trans i18nKey='extensionLandingText' action={action}>
                        Do you want to earn {{action}} cashback on <b>every purchase?</b>
                    </Trans>
                </p>
                <div className={style.content_btns}>
                    <Button onClick={handleCancelClick} theme='stroke' textContent={t('No, I hate saving')}
                            kind='ellipse'/>
                    <Button onClick={handleRegistrationClick}
                            IconLeft={ChromeIcon}
                            isIconAbsolute={true}
                            textContent={t('Yes, add Chrome extension')}
                            kind='ellipse'/>
                </div>
            </div>
        </div>
    );
};

export {ExtensionLanding};
export default ExtensionLanding;
