import React, {FC, Fragment} from 'react';
import offerCardHeaderStyle from './OfferCardHeader.module.scss';
import {OfferCardHeaderInterface} from './OfferCardHeader.interface';
import classNames from 'classnames/bind';



export const OfferCardHeader: FC<OfferCardHeaderInterface> = (props) => {
    const {src} = props;

    const offerCardClassName = classNames(
        offerCardHeaderStyle.offer_card_header,
    );

    return (
        <Fragment>
            <div className={offerCardClassName}>
                <div className={offerCardHeaderStyle.offer_card_header__logo}>
                    <img className={offerCardHeaderStyle.offer_card_header__logo_img} src={src} alt={'offer logo'}/>
                </div>
            </div>
            <hr className={offerCardHeaderStyle.offer_card_header__hr}/>
        </Fragment>


    );
};
