import React, {FC, useMemo} from 'react';
import privacyStyle from './PrivacyPolicy.module.scss';
import {Trans, useTranslation} from "react-i18next";
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {useAccountTitle} from '../../hooks';
import {InfoMenuEnum} from 'typings';

const PrivacyPolicy: FC = () => {
    const {t} = useTranslation();
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(InfoMenuEnum.PRIVACY_POLICY, true);

    useDocumentTitle(documentTitles[InfoMenuEnum.PRIVACY_POLICY]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[InfoMenuEnum.PRIVACY_POLICY] }],
        [documentTitles[InfoMenuEnum.PRIVACY_POLICY]]);
    useBreadcrumbs(breadcrumbs);
    return (
        <div className={`${privacyStyle.content} textBody_01`}>
            <h2 className={'textHeader_06'}>{t('Introduction')}</h2>
            <p className={`${privacyStyle.content__mb_20}`}>{t('This Privacy Policy provides information on how Innovation Technology Incubator GMBH  (hereinafter “Giftmio” or “we/us/our”) concerns your personal and non-personal data if you visit our website or cashback service.')}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('1. Controller and Data Protection Officer')}</h3>
            <p className={privacyStyle.content__mb_20}>
                <Trans i18nKey='privacy_policy_data protection'>
                    Should you have any questions about our data protection measures, the processing of your data, or
                    the <a href='mailto:data-support@mitgame.com'>data-support@mitgame.com</a>&nbsp;
                    protection of your rights as a data subject, you can contact our Data Protection Officer: <a
                    href='mailto:data-support@giftmio.com'>data-support@giftmio.com</a>
                </Trans>
            </p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('2. Definitions')}</h3>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('2.1 Personal data.')}</h4>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`“Personal data” means any information relating to an identified or identifiable natural person. An identifiable natural person can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.`)}</p>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('2.2 Technical data.')}</h4>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`“Technical data” means any information that cannot be related to your identity, e.g., information on browser types, operating systems, domain names, access dates and times, referring website addresses, online transactions, and browsing and search activity.`)}</p>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('2.3 Processing')}</h4>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`“Processing” means any operation or set of operations performed on data such as the collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction of said data.`)}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('3. Collecting and processing personal data')}</h3>
            <p>{t(`The scope of how we collect and process your personal and non-personal data or other information depends on the way you interact with us or our technologies and services:`)}</p>
            <ul>
                <li>{t('as a visitor of Giftmio websites.')}</li>
                <li>{t('as a user of Giftmio cashback users.')}</li>
                <li>{t('as a publisher or an advertiser of the Giftmio')}</li>
            </ul>
            <p>{t(`With this Privacy Policy, we want to inform you of which information we receive and use at every stage.`)}</p>
            <p className={`${privacyStyle.content__mb_20} textBody_01`}>{t(`We use personal data only to administer and provide our technologies and services. We store personal and any other kind of data strictly in compliance with applicable data protection and privacy legislation and our contractual duties and will prevent unauthorized access to data to the best of our abilities.`)}</p>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('3.1 for visitor of  Giftmio websites')}</h4>
            <p>{t(`If you visit the our website, we log the following data to facilitate the use of our website and to ensure its security and the stability of our web servers:`)}</p>
            <ul>
                <li>{t('The IP address of the requesting device')}</li>
                <li>{t('The date and time of your visit')}</li>
                <li>{t('The data specifications of your browser and operating system ')}</li>
                <li>{t('The website you were referred from to our website (a so-called Referrer URL).  ')}</li>
            </ul>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`This information is stored in a log file until it is automatically deleted.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('Cookies')}</h5>
            <p>{t(`We use cookies to improve and optimize the usability of our website. Cookies do not cause damage to your device or contain viruses, trojans, or other malicious software. The cookie stores data linked to the respective device used. This does not mean, however, that we store personal data about you or are aware of your identity.`)}</p>
            <p className={`${privacyStyle.content__mb_20}`}>
                (<Trans i18nKey='privacy_policy_website_cookies'>
                    Our website can be visited and surfed without cookies. Most browsers automatically accept cookies. You can alter the security settings of your web browser in order to stop your device from receiving and storing cookies, to allow cookies to be received and stored from only selected websites, or to be notified before receiving cookies. Please note, however, that these settings may have negative effects on website usability and user guidance features and other online services. You can delete cookies stored in your browser at any time. Data stored in such cookies will be removed from your device.
                </Trans>)
            </p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('Social plugins and analytics tools')}</h5>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('Social plugins')}</h5>
            <p>{t(`We participates in and markets via social media networks and has implemented certain social media plugins and links to social networks on our websites.`)}</p>
            <p>{t(`These social networks are operated exclusively by third parties. If you follow the links, data may be transferred to these providers. All social media plugins are used so that you can interact with us using social network features. When you enter a page on our website that contains a social network plugin, your browser or app will establish a direct connection to the social network’s servers.`)}</p>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`This processing of (personal) data is necessary for our legitimate interests (i.e., marketing by increasing the range of our website/services), Art. 6(1)(f) of GDPR.`)}</p>
            <span className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('Analytics tools')}</span>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`We use Google Analytics, Microsoft Clarity, tools on our website for the purposes of the adequate design and continuous optimization of our website, as well as reporting on website usage.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('Personal data in the “contact details” form.')}</h5>
            <p>{t(`For the purpose of receiving feedback or any questions you may have, we may collect data about you that may include:`)}</p>
            <ul className={`${privacyStyle.content__mb_20}`}>
                <li>{t('Email address')}</li>
                <li>{t('Name')}</li>
                <li>{t('Include any additional data that you might in an email or comment. You provide this data to us by using the “Contact us” form or by the email you send.')}</li>
            </ul>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('Process')}</h5>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`In Short: We process your information to provide, improve, and administer our Site, communicate with you, for security and fraud prevention, and comply with the law. We may also process your information for other purposes with your consent.`)}</p>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('3.2. for Giftmio cashback users')}</h4>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>3.2.1. {t('Giftmio cashback users data processing')}</h4>
            <p>{t(`To obtain access cashback services, you must register in our website.`)}</p>
            <p>{t(`After that, in addition to the data described in the policy for visitors, we collect and process:`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`personal data you provide in the registration form:`)}</h5>
            <p>{t(`•  full name, e-mail address, user_id, country, language, mobile phone number`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`information about your purchases:`)}</h5>
            <p>{t(`• The website where purchases were made, amount, user IP address, user agent, user referrer, country code, and user ID.`)}</p>
            <p>{t(`We may share this data with our partner in order to confirm the purchase and calculate the cashback that is due to you.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`financial information for receiving payments:`)}</h5>
            <p>{t(`• bank data or  e-wallet data (card number, name or account ID required for payment)`)}</p>
            <p>{t(`We may transfer this data to payment services for charging you a rewardThe processing of personal data is necessary for the performance of a contract, Art. 6(1)(b) of GDPR. `)}</p>
            <p>{t(`We do not collect or use any personal data other than the data that you voluntarily provide.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Process`)}</h5>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`In Short: We process your information to provide, improve, and administer our service, provide useful features to simplify your experience when you use or service, deliver relevant content and advertising based on your preferences in our mobile application, providing you with rewards, communicate with you, for security and fraud prevention, and comply with the law.`)}</p>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>3.2.2. {t(`The Giftmio Extension for users`)}</h4>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`The Giftmio Extension processes your data to provide the following functionalities for users:`)}</h5>
            <ul className={`${privacyStyle.content__mb_20}`}>
                <li>{t('Providing a cashback service for the user')};</li>
                <li>{t('Informing the user about the possibility of receiving cashback on the site')};</li>
                <li>{t('Reminding the user about cashback')};</li>
                <li>{t('Searching for stores where cashback is available')};</li>
            </ul>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Downloading the Giftmio Extension`)}</h5>
            <p className={privacyStyle.content__mb_20}>{t('The app can be downloaded through browser web stores. This service is offered by third parties. When you download the Giftmio Extension, the store may collect your personal data. For information on the volume of data processed, the reason for processing it, as well as your rights and setup options, please see the stores’ respective privacy policies.')}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Legal bases and purposes for the processing of your personal data and the necessity of doing so:`)}</h5>
            <ul className={privacyStyle.content__mb_20}>
                <li>{t('We process your personal data to fulfill the Giftmio terms of use. We may also process your personal data with your permission in the instances and to the extent described below. You are not obliged to download or use the extension.')}</li>
                <li>{t('If you do not provide your personal data, some features of the Giftmio Extension may be unavailable.')}</li>
            </ul>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`The extension collects, processes, and stores:`)}</h5>
            <ul className={privacyStyle.content__mb_20}>
                <li>{t('User ID')},</li>
                <li>{t('IP address')},</li>
                <li>{t('URLs of websites you visit')},</li>
                <li>{t('Data is processed unless you log out or delete the extension')}</li>
            </ul>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`The extension uses tracking links provided by Giftmio to provide cashback services for you. `)}</h5>
            <p className={privacyStyle.content__mb_20}>{t('When you follow or click on tracking links, redirects may set a cookie. The cookie storage period is determined by the advertising networks to which Giftmio is connected. For information on the volume of data processed, the reason for processing it, as well as your rights and setup options, please see the respective privacy policies of the advertising networks.')}</p>
            <h4 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`3.3. For publishers or advertisers`)}</h4>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`If you’re a publisher or advertiser registering and participating in Giftmio. To obtain access to our technologies and services, you must register in our network. After that, in addition to the data described in the policy for visitors, we collect and process:`)}</p>
            <ul className={`${privacyStyle.content__mb_20}`}>
                <li>{t(`Personal data you provide in the registration form: your name, country, email address, username, etc. The username will be available to other Internet users when working with certain programs.`)}</li>
                <li>{t(`Data you provide during your participation with us, e.g., your phone number, postal address, and/or tax number.`)}</li>
            </ul>
            <p>{t(`The processing of personal data is necessary for the performance of a contract, Art. 6(1)(b) of GDPR.`)}</p>
            <p>{t(`We do not collect or use any personal data other than the data that you voluntarily provide.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Cookies`)}</h5>
            <p>{t(`In addition to the cookies specified in the section for visitors, we use strictly necessary cookies on publishers’ and advertisers’ account pages for the website to function properly.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Analyzing tools`)}</h5>
            <p>{t(`We use Google Analytics on our website for the purposes of the adequate design and continuous optimization of our website, as well as reporting on website usage.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Information on first-party and third-party cookies`)}</h5>
            <p>{t(`As a publisher or advertiser, you must inform users of your website about first-party and third-party cookies, including those placed by us, i.e., the names and purposes of the cookies, as well as how users may prevent cookies from being placed on their device. Users must consent to the use of both first-party and third-party cookies on your website before a cookie is placed.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Integration with Google Ads`)}</h5>
            <p>
                <Trans i18nKey='privacy_policy_adv_google'>
                    This is a tool for Publishers who buy traffic from the Google Ads contextual advertising service and drive it to our advertisers’ websites directly or through the landing page. For information on the volume of data processed and the reasons for which it is processed, as well as on your rights and setup options, please see Google’s Privacy Policy: https://policies.google.com/privacy?hl=en When you use the integration with Google Ads, your personal data is processed as described in this section. This integration type allows us to access data from your Google Ads advertising campaigns. Below, you can find information about the legal bases and purposes for the processing of your personal data and, if applicable, the legitimate interests involved and the necessity of doing so.
                </Trans>
            </p>
            <p>{t(`When you use the integration with Google Ads, we collect your personal data, including your Google account ID and Click ID (GCLID). This allows you to configure the sending of actions from Giftmio to the corresponding advertising campaigns in the Google Ads advertising account.| If you choose not to provide personal data, the “Integration with Google Ads” tool will not be able to function. There will be no other consequences for you.`)}</p>
            <h5 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`Payment processing software`)}</h5>
            <p>{t(`We use payment processing software, for billing purpose.`)}</p>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`Payment processing software, may store your bank account and/or credit card details (referred to as “Payment Information”). We connect with those third parties to bill you for payments associated with using the Service. We do not store or process payment information, such as your credit or debit card number, or your bank account number.`)}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('4. Newsletters')}</h3>
            <p>{t(`With the following information, we will inform you of the contents of our newsletter and the procedure for the registration and dissemination of newsletters, and the evaluation of statistics thereof, as well as your right to object to the procedure. By subscribing to our newsletter, you agree to the receipt of the newsletter and the procedures described.`)}</p>
            <p>{t(`We send out newsletters, emails, and other electronic notifications with promotional information (hereinafter “Newsletter”) subject to the recipient’s consent or legal permission.`)}</p>
            <p>{t(`Registering for our newsletter is handled through a so-called double opt-in procedure, which means you will receive an email after you register, in which you will be asked to confirm your registration once again. Registrations for the newsletter are recorded to prove adherence to the precise registration process according to legal requirements. This includes storing the time of logon and confirmation. Furthermore, changes to the data stored by Mindbox, a third-party service, are also recorded.`)}</p>
            <p>{t(`The newsletters can be sent by Giftmio or Mindbox.`)}</p>
            <p>{t(`Mindbox is a newsletter platform of the Dutch company Mindbox.Cloud B.V. (a Dutch private limited company) located at Strawinskylaan 613, 1077XX Amsterdam, The Netherlands.`)}</p>
            <p>
                <Trans i18nKey='privacy_policy_email_newsletter'>
                    The email addresses of our newsletter recipients and further data described in the context of this information are stored on Giftmio and Mindbox’s respective servers. Mindbox uses this information to send out and evaluate the newsletters on our behalf. In addition, Mindbox can use this data to optimize or improve its services, e.g., for the technical optimization of the dissemination and the presentation of the newsletter or for economic purposes to determine from which countries the recipients come. However, Mindbox does not use the data of our newsletter recipients to contact them independently or transfer data to third parties except in cases prescribed by Mindbox’s Privacy Policy
                </Trans>
                (<a href='https://mindbox.cloud/documents/privacy-policy/'>https://mindbox.cloud/documents/privacy-policy/</a>).
            </p>
            <p>{t(`To register for the newsletter, we only need your email address. Any other voluntarily provided information about you is only used to personalize the newsletter. You can change the personal data you share with us at any time.`)}</p>
            <p>{t(`There are cases when we direct newsletter recipients to Mindbox’s websites. For instance, our newsletters contain a link with which newsletter recipients can access newsletters online (for example, in case of problems displaying the newsletter in the email program).`)}</p>
            <p className={`${privacyStyle.content__mb_20}`}>
                <Trans i18nKey='privacy_policy_terminate_receipt'>
                    You may terminate the receipt of our newsletter at any time, which means you revoke your consent to receive it. By doing so, your consent to the dissemination of the newsletter via Mindbox and statistical analysis thereof expires at the same time. Unfortunately, it is not possible to cancel the dissemination of the newsletter via Mindbox or the statistical evaluation thereof through other means. You may withdraw your consent to receive our newsletter at any time by changing the notification settings in your account. In addition, you can find a link to unsubscribe from our newsletter at the bottom of each newsletter.
                </Trans>
            </p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('5. Legal Basis')}</h3>
            <p>{t(`The processing of your personal data relies on the following legal bases:`)}</p>
            <ul className={`${privacyStyle.content__mb_20}`}>
                <li>{t(`Your consent, if you have given us such consent (Art. 6 (1) (a) of GDPR)`)}</li>
                <li>{t(`The initiation or execution of a contract with you (Art. 6 (1) (b) of GDPR)`)}</li>
                {/*<li>{t(`The fulfillment of legal obligations (Art. 6 (1) (c) of GDPR)`)}</li>*/}
                <li>{t(`The implementation of our legitimate interests (Art. 6 (1) (f) of GDPR).`)}</li>
            </ul>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('6. Data protection and transmission to third parties')}</h3>
            <p>{t(`Giftmio takes all technical and organizational security measures required to protect your personal data from loss, unauthorized disclosure, or other forms of misuse. Data is stored in a safe environment that is not open to public access. Your personal data may be encrypted before transmission in certain cases (e.g., processing your login data). This means that communication between your device and our servers will include recognized encryption measures if your browser supports doing so.`)}</p>
            <p>{t(`Our staff has been trained in privacy matters and responsibly handling personal data and information to which they gain access.`)}</p>
            <p>{t(`Please be aware that should you contact us via email, the confidentiality of the information therein may not be ensured. Third persons may have access to the contents of emails.`)}</p>
            <p>{t(`For Giftmio to execute its business processes in a convenient and optimal manner, it may be necessary for certain data to be processed by trusted third parties and reliable partners. These third parties may:`)}</p>
            <ul className={`${privacyStyle.content__mb_20}`}>
                <li>{t(`Process payments`)}</li>
                <li>{t(`Fulfill orders`)}</li>
                <li>{t(`Send emails`)}</li>
                <li>{t(`Manage communications (e.g., newsletters, security notifications, chat, etc.)`)}</li>
                <li>{t(`Host websites`)}</li>
            </ul>
            <p>
                <Trans i18nKey='privacy_policy_conduct_activities'>
                    Conduct other related activities on behalf of Giftmio. However, Giftmio only shares data that is needed to serve the specific purpose for which the third parties are engaged. Giftmio ensures that these third parties are under similar obligations to maintain privacy and confidentiality as Giftmio’s own employees and that they will handle your information in the way and to the extent Giftmio is permitted to. Giftmio does not allow any third party to use your information for any purposes for which the information was not collected.
                </Trans>
            </p>
            <p>
                <Trans i18nKey='privacy_policy_pii'>
                    Giftmio will only disclose your personally identifiable information (PII) outside the country you live in if it is necessary for the intended purpose of processing. However, as a member of the worldwide group of companies, each local our entity may maintain or perform data processing operations in countries outside the European Economic Area (EEA) or in countries without an adequate level of data protection if doing so is required for the fulfillment of our obligations or the underlying contract with you. Furthermore, our subcontractors, which we engages to act on its behalf concerning the processing of your PII, may be domiciled in such areas.
                </Trans>
            </p>
            <p>
                <Trans i18nKey='privacy_policy_processing_pii'>
                    To secure the transfer and processing of PII under chapter 5 of GDPR, we  has implemented and requested the required technical and organizational measures and has entered into the appropriate contractual frameworks with our group companies and subcontractors, which ensure that the data recipient has implemented an adequate level of data privacy in its organization as required by GDPR. This includes the signing of Data Processing Agreements and European Union standard contractual clauses issued by the EU Commission. These precautions are appropriate safeguards as stipulated by Article 46 GDPR and local data protection laws in effect, ensuring that your information will be treated securely, confidentially, and under the applicable data protection laws.
                </Trans>
            </p>
            <p className={`${privacyStyle.content__mb_20}`}>
                <Trans i18nKey='privacy_policy_acquired_party'>
                    If we is acquired by or merges with a third party, we reserve the right, in either of these circumstances, to transfer or assign the information we have collected from you as part of such a merger, acquisition, sale, or other change of control. In the unlikely event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors’ rights generally, we may not be able to control how your information is treated, transferred, or used.
                </Trans>
            </p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t('7. Transfer to Third Countries')}</h3>
            <p className={privacyStyle.content__mb_20}>{t(`Data is transferred to countries outside the European Economic Area. We only transfer personal data to third countries where the EU Commission has confirmed an adequate level of protection or where we can ensure the careful handling of personal data by employing contractual agreements or other suitable guarantees, such as certifications or proven compliance with international security standards.`)}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`8. Retention Period`)}</h3>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or allowed by law.`)}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`9. User Rights`)}</h3>
            <p>{t(`You have the following rights concerning our processing of your personal data:`)}</p>
            <ul>
                <li>{t(`The right to access`)}</li>
                <li>{t(`The right to rectification`)}</li>
                <li>{t(`The right to erasure`)}</li>
                <li>{t(`The right to restrict processing`)}</li>
                <li>{t(`The right to data portability`)}</li>
                <li>{t(`The right to object (on grounds related to your particular situation) if your personal data is processed based on our legitimate interest `)}</li>
                <li>{t(`The right to withdraw your consent at any time in the event of any consent-based processing of your personal data without affecting the lawfulness of processing said data based on consent before your withdrawal`)}</li>
                <li>{t(`The right to lodge a complaint with a supervisory authority.`)}</li>
            </ul>
            <p>{t(`You may exercise your legal rights by contacting us via email at `)} <a
                href='mailto:data-support@giftmio.com'>data-support@giftmio.com</a></p>
            <p>{t(`If you are registered as a publisher or an advertiser on our website, you may access your account and correct or delete some of your data by yourself.`)}</p>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`While observing applicable privacy legislation, we will erase your data without any action required by you if retention of your data is no longer necessary for the purpose pursued or retention is no longer allowed for any legal reason. We may restrict processing instead of erasing your data where it is legally not allowed to erase (e.g., legal obligations to maintain your personal data).`)}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`10. Amendments`)}</h3>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`We may modify this Privacy Policy and our data security provisions at any time, particularly if new laws and/or regulations need to be adopted or if we regard amendments as reasonable.`)}</p>
            <h3 className={`${privacyStyle.content__sub_title} textHeader_08`}>{t(`11. Questions/Contacts`)}</h3>
            <p className={`${privacyStyle.content__mb_20}`}>{t(`For all questions and requests related to the security of your personal data: `)}<a
                href='mailto:data-support@giftmio.com'>data-support@giftmio.com</a></p>
        </div>
    );
}

export {PrivacyPolicy};
export default PrivacyPolicy;
