import { Controller, useFormContext } from 'react-hook-form'
import {
    Checkbox as CheckboxUIKit,
    CheckboxProps,
} from '@design-system/ui-kit'

interface ICheckboxProps extends CheckboxProps {
    name: string;
}

export const Checkbox = (props: ICheckboxProps) => {
    const { name, onChange: customOnChange, ...optionalProps } = props;
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <CheckboxUIKit
                    {...field}
                    checked={field.value ?? ''}
                    onChange={(event) => {
                        customOnChange && customOnChange(event)
                        field.onChange(event)
                    }}
                    {...optionalProps}
                />
            )}
        />
    )
}
