import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';
import {
    AppConfigInterface,
    CategoriesInterface,
    FakeUrlsInterface,
    OfferInterface,
    RequestPayloadType,
    SmartOfferInterface
} from 'typings';

export const getAppConfigRequest = async (): Promise<AppConfigInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('app_config/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getCategoriesRequest = async (): Promise<CategoriesInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('meta/category/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getTrendingStoresRequest = async (): Promise<OfferInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('trending_store/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getTopOffersRequest = async (): Promise<OfferInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('top_offer/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getAutocompleteOffersRequest = async (payload: RequestPayloadType<any>): Promise<OfferInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('catalog/autocomplete/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getCountriesRequest = async (): Promise<OfferInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('meta/country/'));
    return await sendRequest<any>(sendRequestParams);
};

export const postCurrencyGeoRequest = async (payload: RequestPayloadType<any>): Promise<null> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('account/settings/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const getOfferSearchRequest = async (payload: RequestPayloadType<any>): Promise<SmartOfferInterface[]> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('catalog/search/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getFakeUrlsRequest = async (payload: RequestPayloadType<any>): Promise<FakeUrlsInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('r/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getMegaOfferRequest = async (uuid: string): Promise<FakeUrlsInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`catalog/mega/${uuid}/`));
    return await sendRequest<any>(sendRequestParams);
};
