export const LanguagesConst: string[] = [
    'en',
    'pl',
    'pt',
    'de',
    'es',
    'fr',
    'it',
    'ru',
];
