import { FC } from "react";
import { useTranslation } from "react-i18next";
import page404 from "assets/images/errors/error-404.svg";
import { Button } from "components";
import { Error } from "../Error";

const PageNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <Error
      title={t("Page not found")}
      text={t("The requested URL was not found. You may have made a typo, or maybe that page never existed.")}
      buttons={[<Button key="404_to_home" to={"/"} textContent={t("Go to main page")} />]}
      image={<img src={page404} alt={t("Page not found")} width="338" height="197" />}
    />
  );
};

export { PageNotFound };
export default PageNotFound;
