import React, {FC, useState} from 'react';
import style from './Banners.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Button} from 'components';
import {SsoConst} from 'consts';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, PostHogOptionsEnum} from 'typings';
import {getSsoLocales, sendAnalyticEvents} from 'utils';
import {BannerExtensionMain} from "../bannerExtensionMain/BannerExtensionMain";
import {ReactComponent as ArrowLeftIcon} from 'assets/images/icons/button-arrow-left.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/images/icons/button-arrow-right.svg';



export const Banners: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const [sliderRef, setSliderRef] = useState<any>(null);

    const sliderSettings = {
        arrows: false,
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // const handleActivatePromoClick = (e) => {
    //     e.stopPropagation();
    //     const gaOptions = {
    //         event_option2: AnalyticEventOptionsEnum.BANNER,
    //     };
    //     const postHogOptions = {
    //         button_name: PostHogOptionsEnum.ACTIVATE_PROMO_CODE,
    //         button_location: AnalyticEventOptionsEnum.HOMEPAGE
    //     };
    //     sendAnalyticEvents(AnalyticEventNameEnum.CLICK_ACTIVATE_PROMO_CODE, {gaOptions, postHogOptions});
    //     const encodeUri = encodeURIComponent(`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.PROMO}?promo=${promoCodesConst.startPromoCode}`);
    //     window.location.href = `${SsoConst.login}?next=${encodeUri}`;
    // }

    const handleGetCashbackClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.BANNER,
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.GET_CASHBACK,
            button_location: AnalyticEventOptionsEnum.HOMEPAGE
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_GET_CASHBACK, {gaOptions, postHogOptions});
        window.location.href = `${SsoConst.login}?${getSsoLocales()}`;
    }

    return (
        <div className={style.content}>
            <>
            {!isMobile && <button className={`${style.controls} ${style.controls_prev}`} onClick={sliderRef?.slickPrev}>
                    <ArrowLeftIcon/>
                </button>}
                <button className={`${style.controls} ${style.controls_next}`} onClick={sliderRef?.slickNext}>
                    <ArrowRightIcon/>
                </button>
            </>
            <Slider ref={setSliderRef} {...sliderSettings}>
                {/*<div className={`${style.banner} ${style.banner_first}`} onClick={handleActivatePromoClick}>*/}
                {/*    <div className={style.banner_content}>*/}
                {/*        <span className={`${isMobile ? 'textHeader_02': 'textHeader_01'} ${style.banner_content_text}`}><span className={style.banner_brand_color}>{t('Extra Cashback?')}</span> {t('Yes, Please!')}</span>*/}
                {/*        <span className={`${style.banner_content_desc} textBody_01`}>*/}
                {/*            <span><Trans i18nKey='bannerFirstText'>*/}
                {/*                Use promo code <span className={style.banner_brand_color}>«RETURNMYCASH»</span> for new users.*/}
                {/*            </Trans></span>*/}
                {/*        </span>*/}
                {/*        <div className={style.banner_footer}><Button onClick={handleActivatePromoClick} theme={'black'} size={!isMobile ? 'xxl' : 'xl'} kind={'ellipse'} textContent={t('Activate promo code')}></Button></div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <BannerExtensionMain/>
                <div className={`${style.banner} ${style.banner_second}`} onClick={handleGetCashbackClick}>
                    <div className={`${style.banner_content} ${style.banner_second_content}`}>
                        <span className={`${isMobile ? 'textHeader_03': 'textHeader_01'} ${style.banner_content_text}`}>
                            <Trans i18nKey='bannerSecondText'>
                            Over <span className={style.banner_brand_color}>1000</span> local and international stores
                        </Trans>
                        </span>
                        <div className={style.banner_footer}>
                            <Button onClick={handleGetCashbackClick}
                                    kind={'ellipse'}
                                    textContent={t('Get cashback')}>
                            </Button>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>

    );
};
