import { HTMLAttributes, ReactNode } from "react";
import cn from "classnames";

import styles from "./Error.module.scss";

interface ErrorPropsInterface extends HTMLAttributes<HTMLDivElement> {
  title: string;
  text: string;
  link?: ReactNode;
  buttons?: ReactNode[];
  image?: ReactNode;
}

const Error = ({ className, title, text, link, buttons, image, ...props }: ErrorPropsInterface) => {
  return (
    <div {...props} className={cn(className, styles.error)}>
      <div className={styles.content}>
        <h1 className={`textHeaderMedium_01 ${styles.title}`}>{title}</h1>
        <p className={`textBody_01 ${styles.text}`}>
          {text} {link}
        </p>
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>

      <div className={styles.image}> {image}</div>
    </div>
  );
};

export { Error };
export default Error;
