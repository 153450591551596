import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames/bind';
import {Dropdown} from '@design-system/ui-kit';
import {ReactComponent as ArrowsIcon} from 'assets/images/icons/arrows.svg';
import {Button, LinkComponent, MobileOverlayLayout, SearchInput} from 'components';
import {useMedia} from 'hooks';
import filterSearchSortStyle from './FilterSearchSort.module.scss';
import {SearchBlockInterface} from './SearchBlock.interface';
import {OrderingCatalogOptions} from 'consts';


export const FilterSearchSort: FC<SearchBlockInterface> = ({isSeparate, search, sort}) => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const [isSortOpen, setIsSortOpen] = useState<boolean>(false);

    const handleSortToggleClick = () => {
        setIsSortOpen(!isSortOpen);
    };

    const handleSortClick = (value) => {
        sort.onChange(value, true);
        setIsSortOpen(false);
    }

    const fssBlockClassName = classNames(
        filterSearchSortStyle.fssBlock,
        {
            [filterSearchSortStyle.fssBlock_separate]: isSeparate,
        },
    );


    return (
        <div className={fssBlockClassName}>
            {  isMobile
                ? <Button theme={'ghost'}
                          isIconWithoutText={true}
                          IconLeft={ArrowsIcon}
                          kind={'round'}
                          size={'s'}
                          onClick={handleSortToggleClick}/>
                : <Dropdown className={filterSearchSortStyle.fssBlockSort}
                      value={sort.value || ''}
                      placeholder={sort.placeholder || t('Sort by')}
                      uncheckOptionByClick
                      optionsListMaxHeight={sort.options?.length ? 300 : undefined}
                      onChange={sort.onChange}
                      options={sort.options}/>}
            {!!search && <div className={filterSearchSortStyle.fssBlockSearch}>
              <SearchInput onChange={search.onChange} placeholder={t('Search store')}/>
            </div> }
            {isSortOpen &&
              <MobileOverlayLayout title={t('Sort by')} onCloseClick={handleSortToggleClick}>
                <div className={filterSearchSortStyle.fssOrderList}>
                    {OrderingCatalogOptions.map(option => (
                        <div className={filterSearchSortStyle.fssOrderList_link} key={option.value.toString()}>
                            <LinkComponent textContent={option.text} onClick={() => handleSortClick(option.value)}/>
                        </div>
                    ))
                    }
                </div>
              </MobileOverlayLayout>}
        </div>
    );
};
