export enum MainRoutesEnum {
    HOME = '',
    CATALOG = '/catalog',
    BRAND = '/offer',
    ACCOUNT = '/account',
    INFO = '/info',
    DIRECT_LINK = '/direct-link',
    DIRECT_LANDING = '/direct-landing',
    DIRECT_EXTENSION = '/direct-extension',
    TAKE_ADS_LANDING_V1 = '/promo-landing-v1',
    TAKE_ADS_LANDING_V2 = '/promo-landing-v2',
    WELCOME_LINK = '/welcome',
    SEARCH_LANDING = '/search-landing',
    FAKE_LINK = '/r',
    MEGA = '/offer-mega',
    INTERNAL_ERROR = '/error-500',
    ACCOUNT_BLOCKED = '/account-blocked',
    EXTENSION = '/extension',
}
