import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InfoStoreInterface} from './typings';


const defaultAccountState: InfoStoreInterface = {
    infoTitle: '',
};

export const infoSlice = createSlice({
    name: 'info',
    initialState: defaultAccountState,
    reducers: {
        setInfoTitle: (state, action: PayloadAction<string>) => ({...state, infoTitle: action.payload}),

    },
});

export const {
    setInfoTitle
} = infoSlice.actions;

export const infoReducer = infoSlice.reducer;

