import classNames from 'classnames';
import React, { forwardRef } from 'react';
import {ReactComponent as CloseIcon} from 'assets/images/icons/close.svg';
import styles from './ModalContent.module.scss';
import { IModalContentProps } from './ModalContent.types';


export const ModalContent = forwardRef<HTMLDivElement, IModalContentProps>((props, ref) => {
  const {
    active,
    title,
    children,
    closable = false,
    titleIcon = false,
    onClose,
    className,
    size = 's',
    closeButtonRef,
  } = props;

  if (!active) {
    return null;
  }

  const modalContentClassName = classNames(
      styles.modalContent,
      className,
  );
  return (
    <div
      ref={ref}
      // size={firstScreen ? 'modalContent_size_s' : 'modalContent_size_m'}

      className={`${modalContentClassName} ${size === 's' && styles.modalContent_size_s} ${size === 'm' && styles.modalContent_size_m} ${size === 'l' && styles.modalContent_size_l}`}
    >
      {closable && (
          <button ref={closeButtonRef} className={styles.closeButton} onClick={onClose}>
             <CloseIcon/>
          </button>
      )}
      {(titleIcon || title) && (
        <div className={styles.header}>
          <div
            className={classNames(styles.headerText, {
              [styles.headerText_withMarginRight]: closable,
            })}
          >
            {title && (
              <h2>
                {title}
              </h2>
            )}
          </div>
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
});
