import {isLandingPage} from 'utils';
import {ServicesUrls} from 'consts';

export const initConsentToolScript = () => {
    if (['prod'].includes(`${process.env.REACT_APP_ENVIRONMENT_NAME}`) && !isLandingPage()) {
        setTimeout(() => {
            const consentMain = document.createElement('script');
            consentMain.setAttribute('type', 'text/javascript');
            consentMain.setAttribute('data-cmp-ab', '1');
            consentMain.src = ServicesUrls.consentManager;
            consentMain.setAttribute('data-cmp-host', 'b.delivery.consentmanager.net');
            consentMain.setAttribute('data-cmp-cdn', 'cdn.consentmanager.net');
            consentMain.setAttribute('data-cmp-codesrc', '1');
            document.getElementsByTagName('head')[0].appendChild(consentMain);
        }, 3000);
    }
};
