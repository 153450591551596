export enum ENotificationsThemes {
  critical = 'critical',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export enum ENotificationsBackgroundColors {
  black = 'black',
  white = 'white'
}

export interface INotificationsItem {
  id: string,
  title?: string,
  description?: string,
  theme?: ENotificationsThemes,
  backgroundColor?: ENotificationsBackgroundColors,
  onClose?: (id: string | number) => void,
  closable?: boolean,
  buttonProps?: {
    text: string,
    onClick: (e: any) => void,
  },
}

export interface INotificationsProps {
  className?: string,
  items: INotificationsItem[],
  maxVisibleCount?: number,
  onItemsChange: (newItemsArr: INotificationsItem[]) => void,
  backgroundColor?: ENotificationsBackgroundColors,
}
