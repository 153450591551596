import React, {FC} from 'react';
import actionCardStatusStyle from './ActionCardStatus.module.scss';
import {ActionCardStatusInterface} from './ActionCardStatus.interface';
import cn from 'classnames';
import approvedLogo from 'assets/images/statuses/approved.svg';
import declinedLogo from 'assets/images/statuses/declined.svg';
import pendingLogo from 'assets/images/statuses/pending.svg';
import {useTranslation} from 'react-i18next';



export const ActionCardStatus: FC<ActionCardStatusInterface> = (props) => {
    const {t} = useTranslation();
    const {status} = props;
    const imageSize = '20px';
    const actionStatusTextClassName = cn(
        'textHeader_11',
        actionCardStatusStyle[`action_status__text_${status}`],
    );
    const actionStatuses = {
        approved: {
            imgPath: approvedLogo, title: t('approved')
        },
        declined: {
            imgPath: declinedLogo, title: t('declined')
        },
        pending: {
            imgPath: pendingLogo, title: t('pending')
        }
    };
    return (
        <div className={actionCardStatusStyle.action_status}>
            <img src={actionStatuses[status]?.imgPath} height={imageSize} width={imageSize} alt={actionStatuses[status]?.title}/>
            <span className={actionStatusTextClassName}>{actionStatuses[status]?.title?.toUpperCase()}</span>
        </div>
    );
};
