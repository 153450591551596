import React, {FC, Fragment} from 'react';
import {BannerMega, BannerNarrow, Banners, HelpBlock} from '../../componets';
import {useTranslation} from 'react-i18next';
import style from './HomePage.module.scss';
import {Accordion, BlockContainer, CategoryCard, StoreList, OfferList} from 'components';
import {CategoriesInterface, OfferInterface} from 'typings';
import {useCommonSelector, useDocumentTitle, useTransObjects} from 'hooks';
import {useSelector} from 'react-redux';
import {RootState} from 'core';

const HomePage: FC = () => {
    const {t} = useTranslation();
    const {appConfig} = useSelector((state: RootState) => state.commonReducer);
    const categories: CategoriesInterface[] = useCommonSelector<CategoriesInterface[]>('categories');
    const stores: OfferInterface[] = useCommonSelector<OfferInterface[]>('trending_stores');
    const offers: OfferInterface[] = useCommonSelector<OfferInterface[]>('top_offers');
    const {documentTitles, categoriesTrans} = useTransObjects();
    const showUnAuthBlocks = !appConfig?.user;
    const popularCategories = categories.filter(cat => cat.is_popular) || [];

    useDocumentTitle(documentTitles.main);

    return (
        <Fragment>
            <BlockContainer>
              <Banners/>
            </BlockContainer>
            {showUnAuthBlocks && <HelpBlock/>}
            {!!popularCategories?.length && <BlockContainer title={t('Popular categories')}>
                <div className={style.home__categories}>
                    {popularCategories.map(popularCategory => (
                        <CategoryCard
                            key={popularCategory.slug}
                            slug={popularCategory.slug}
                            text={categoriesTrans[popularCategory.slug]}/>
                    ))}
                </div>
            </BlockContainer> }
            <StoreList stores={stores}/>
            <BannerNarrow/>
            <OfferList offers={offers}/>
            <Accordion/>
        </Fragment>
    );
};

export {HomePage};
export default HomePage;
