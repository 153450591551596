import React, {FC, useRef, useState} from 'react';
import style from './HeaderLanguage.module.scss';
import {DropdownBox} from 'components';
import {FlagIcons, LanguagesConst,} from 'consts';
import {useMedia, useOutsideClick} from 'hooks';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowDownIcon} from 'assets/images/icons/arrow-down.svg';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, setSiteLanguage} from 'core';

export const HeaderLanguage: FC = () => {
    const isMobile = useMedia();
    const { i18n , t} = useTranslation();
    const dispatch = useDispatch();
    const [showLangList, setShowLangList] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const { siteLang } = useSelector((state: RootState) => state.commonReducer);

    useOutsideClick(ref, () => {
        setShowLangList(false);
    });

    const handleLangChange = (value: string) => {
        dispatch(setSiteLanguage(value))
        setShowLangList(false);
        i18n.changeLanguage(value);
    }

    const handleLangLink = () => {
        setShowLangList(!showLangList);
    }
    return (
        <div className={style.lang_selector}>
            {!isMobile && <span className={`${style.lang_selector_text} textBody_03`}>{t('Select language')}</span>}
            <div ref={ref}>
                <button className={`${style.lang_selector_btn} textBody_02`} onClick={handleLangLink}>
                    {siteLang}
                    <ArrowDownIcon/>
                </button>
                {showLangList && <DropdownBox isAbsolute={true} kind={'square'}>
                  <div className={`${style.lang_list} textBody_02`}>
                      {LanguagesConst.map(lang => (
                          <button className={style.lang_list_btn} key={lang} onClick={() => {handleLangChange(lang)}}>
                              <img alt={'Location icon'} className={style.dialog__img_icon} src={FlagIcons[lang?.toUpperCase()]}/>
                              {lang}
                          </button>))
                      }
                  </div>
                </DropdownBox> }
            </div>
        </div>
    );
};
