import React, {FC} from 'react';
import cSSidebarStyle from './CatalogSidebar.module.scss';
import {useTranslation} from 'react-i18next';
import {LinkComponent} from 'components';
import {CatalogSidebarInterface} from './CatalogSidebar.interface';
import {useTransObjects} from 'hooks';


export const CatalogSidebar: FC<CatalogSidebarInterface> = (props) => {
    const {categories, popularStores} = props;
    const {t} = useTranslation();
    const {categoriesTrans} = useTransObjects();

    return (
        <aside className="page__aside page__aside_sticky blank">
            <div className={cSSidebarStyle.cs_sidebar}>
                {!!categories?.length &&
                  <div>
                    <span className={`${cSSidebarStyle.cs_sidebar__title} textHeader_09`}>{t('Categories')}</span>
                    {categories.map(category => (
                        <div className={cSSidebarStyle.cs_sidebar__item} key={category.slug}>
                            <LinkComponent
                                theme={'stroke'}
                                isNavLink={true}
                                textContent={categoriesTrans[category.slug]}
                                to={`/catalog/${category.slug}`}/>
                        </div>
                    ))}
                  </div>
                }
                {!!popularStores?.length && <div>
                   <span className={`${cSSidebarStyle.cs_sidebar__title} textHeader_09`}>{t('Popular stores')}</span>
                   {popularStores.map(store => (
                        <div className={cSSidebarStyle.cs_sidebar__item} key={store.id}>
                            <LinkComponent theme={'stroke'} textContent={store.name} to={`/offer/${store.slug}`}/>
                        </div>
                   ))}
               </div>}
            </div>
        </aside>
    );
};

