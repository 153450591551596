import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {OfferListInterface} from './OfferList.interface';
import style from "./OfferList.module.scss";
import {BlockContainer} from '../BlockContainer/BlockContainer';
import {OfferCard} from '../OfferCard';
import {PostHogOptionsEnum} from 'typings';

export const OfferList: FC<OfferListInterface> = (props) => {
    const {title, offers, endFragment = null, isWelcomeLanding = false} = props;
    const {t} = useTranslation();
    return (
        <>
            {!!offers?.length &&
                <BlockContainer title={title || t('Top offers')} endFragment={endFragment}>
                    <div className={style.offers}>
                        {offers.map(offer => (
                            <OfferCard key={offer.id} offer={offer} location={PostHogOptionsEnum.HOMEPAGE} isWelcomeLanding={isWelcomeLanding}/>
                        ))}
                    </div>
                </BlockContainer>
            }
        </>
    );
};
