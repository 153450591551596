import React, {FC, Fragment} from 'react';
import actionsTableStyle from './ActionsTable.module.scss';
import {useMedia} from 'hooks';
import {ActionsTableInterface} from './ActionsTable.interface';
import {ActionCardStatus} from '../actionCardStatus/ActionCardStatus';
import {ActionCard} from '../actionCard/ActionCard';
import {LinkComponent} from 'components';
import {Link} from 'react-router-dom';
import {ActionsTableHeaderEnum} from '../../typings';
import {MainRoutesEnum} from 'core';
import {useTranslation} from 'react-i18next';



export const ActionsTable: FC<ActionsTableInterface> = (props) => {
    const {actionList} = props;
    const {t} = useTranslation();
    const isMobile = useMedia();

    const ActionsTableTexts = {
        [ActionsTableHeaderEnum.AMOUNT]: t('Amount'),
        [ActionsTableHeaderEnum.BRAND]: t('Name'),
        [ActionsTableHeaderEnum.DATE]: t('Date'),
        [ActionsTableHeaderEnum.STATUS]: t('Status'),
    };
    const amountTextStyle = (status) => {
        switch (status) {
            case 'approved':
                return actionsTableStyle.amount_text__approved;
            case 'declined':
                return actionsTableStyle.amount_text__declined;
            default:
                return actionsTableStyle.amount_text;
        }
    };

    return (
        <Fragment>
            {!isMobile &&
              <table className={actionsTableStyle.actions_table}>
                  <thead className={actionsTableStyle.actions_table__header}>
                      <tr className={`textHeader_10 ${actionsTableStyle.actions_table__header_tr}`}>
                        <th className={`${actionsTableStyle.actions_table__header_th}`}>{ActionsTableTexts[ActionsTableHeaderEnum.BRAND]}</th>
                        <th className={`${actionsTableStyle.actions_table__header_th}`}>{ActionsTableTexts[ActionsTableHeaderEnum.STATUS]}</th>
                        <th className={`${actionsTableStyle.actions_table__header_th}`}>{ActionsTableTexts[ActionsTableHeaderEnum.DATE]}</th>
                        <th className={`${actionsTableStyle.actions_table__header_th} ${actionsTableStyle.actions_table__header_th_last}`}>{ActionsTableTexts[ActionsTableHeaderEnum.AMOUNT]}</th>
                      </tr>
                  </thead>
                    <tbody className={actionsTableStyle.actions_table__body}>
                    {actionList.map(actionItem => (
                        <tr key={Math.random()} className={`textBodyMedium_03 ${actionsTableStyle.actions_table__body_tr}`}>
                            <td className={`${actionsTableStyle.actions_table__body_td}`}>
                                {actionItem?.slug || actionItem?.uuid
                                    ? <LinkComponent
                                        textContent={actionItem?.title || actionItem?.payment_system_type}
                                        to={actionItem?.slug ? `${MainRoutesEnum.BRAND}/${actionItem?.slug}` : `${MainRoutesEnum.MEGA}/${actionItem?.uuid}`}/>
                                    : actionItem?.title || actionItem?.payment_system_type
                                }
                            </td>
                            <td className={`${actionsTableStyle.actions_table__body_td}`}>
                                <ActionCardStatus status={actionItem?.status}/>
                            </td>
                            <td className={`textBody_03 ${actionsTableStyle.actions_table__body_td}`}>{actionItem?.date}</td>
                            <td className={`textHeader_08 ${actionsTableStyle.actions_table__body_td} ${amountTextStyle(actionItem?.status)}`}>{actionItem?.amount}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>}
            {isMobile && actionList.map(actionItem => (
                actionItem?.slug
                    ? <Link className={actionsTableStyle.action_card_link} to={`${MainRoutesEnum.BRAND}/${actionItem?.slug}`}>
                        <ActionCard key={Math.random()} name={actionItem?.title || actionItem?.payment_system_type} status={actionItem?.status} date={actionItem?.date} amount={actionItem?.amount} description={actionItem?.additional_info}/>
                    </Link>
                    : <ActionCard key={Math.random()} name={actionItem?.title || actionItem?.payment_system_type} status={actionItem?.status} date={actionItem?.date} amount={actionItem?.amount} description={actionItem?.additional_info}/>
            ))}
        </Fragment>
    );
};
