import React, {FC} from 'react';
import infoMenuStyle from './InfoMenu.module.scss';
import {LinkComponent} from 'components';
import { MainRoutesEnum, InfoRoutesPathEnum } from 'core';
import {useTransObjects} from 'hooks';


export const InfoMenu: FC = () => {
    const {infoMenuTabs} = useTransObjects();

    return (
        <aside className={`${infoMenuStyle.info_menu} page__aside blank`}>
                {infoMenuTabs.map(menuItem => (
                        <LinkComponent key={menuItem.key} to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum[menuItem.urlPath]}`}
                                       isNavLink={true}
                                       textContent={menuItem.title}
                                       theme={'menu'}/>
                ))}
        </aside>
    );
};

