import {PaginationEnum} from '../../typings';

export const setPaginationParams = (params: any, searchParams: URLSearchParams): URLSearchParams => {
    const limit = params.itemsPerPage || searchParams.get(PaginationEnum.LIMIT) || 20;
    const offset = `${limit * (params.pageNumber - 1)}`;
    if (searchParams.get(PaginationEnum.OFFSET) !== offset || Number(searchParams.get(PaginationEnum.OFFSET)) !== params.itemsPerPage) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(PaginationEnum.LIMIT, limit);
        newSearchParams.set(PaginationEnum.OFFSET, offset);
        return newSearchParams;
    }
    return searchParams;
};
