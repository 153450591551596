import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {
    getCatalogOfferListRequest,
} from './catalog.api';
import {
    getCatalogOfferList,
    hideCatalogLoader,
    setCatalogOfferList,
    setCatalogOfferListCount,
    showCatalogLoader,
} from './catalog.reducer';


function* getCatalogOfferListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showCatalogLoader());
        const requestParams: any = {
            queryParams: payload.payload.queryParams,
        };
        const data: any = yield call(getCatalogOfferListRequest, requestParams);
        if (data) {
            yield put(setCatalogOfferList(data.results as any));
            yield put(setCatalogOfferListCount(data.count));
            yield put(hideCatalogLoader());
        }
    } catch (error) {
        yield put(hideCatalogLoader());
    }
}



export function* catalogWatcher() {
    yield takeEvery(getCatalogOfferList.type, getCatalogOfferListWorker);
}
