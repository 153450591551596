import React, {FC} from 'react';
import accordionStyle from './Accordion.module.scss';
import {AccordionItem} from './components';
import {BlockContainer} from '../BlockContainer/BlockContainer';
import {useTranslation} from 'react-i18next';

interface IAccordion {
    title?: string;
    withoutIndent?: boolean;
    isLanding?: boolean;
    withExtension?: boolean;
}

export const Accordion: FC<IAccordion> = ({title = '', withoutIndent = false, isLanding = false, withExtension = false}) => {
    const {t} = useTranslation();
    let accordionData = [
        {
            title: t('How does cashback Work?'),
            key: 'howWorks',
        },
        {
            title: t('How quickly can I receive my cashback?'),
            key: 'howReceive',
        },
        {
            title: t('How should I make purchases to ensure guaranteed cashback?'),
            key: 'howMakePurchases',
        },
        {
            title: t(`I followed all the rules but still didn't receive cashback, what should I do?`),
            key: 'didntReceive',
        },
        {
            title: t(`How can I withdraw my money?`),
            key: 'withdrawMoney',
        },
    ];

    const extensionData =
    {
        title: t('What is Giftmio extension, what are its benefits, how to use it?'),
        key: 'whatIsExtension',
    };

    if (withExtension) {
        accordionData.unshift(extensionData)
    }

    return (
        <BlockContainer withoutIndent={withoutIndent} title={title || t('Frequently asked questions')}>
            <div className={accordionStyle.accordion}>
                {isLanding ?
                    <>
                        <AccordionItem key={Math.random()} title={t('Is Giftmio free?')} contentKey='isGiftmioFree'/>
                        <AccordionItem key={Math.random()} title={t('How do I earn cashback?')} contentKey='howCashbackEarn'/>
                        <AccordionItem key={Math.random()} title={t('Does Giftmio work on mobile devices?')} contentKey='doesGiftmioWorksMobile'/>
                        <AccordionItem key={Math.random()} title={t('Why is Giftmio better than other cashback services??')} contentKey='whyGiftmioBetter'/>
                    </> :
                    <>
                        {accordionData.map(({ title, key }) => (
                            <AccordionItem key={key} title={title} contentKey={key}/>
                        ))}
                    </>
                }

            </div>
        </BlockContainer>
    );
};
