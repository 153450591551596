import React, {FC, Fragment, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import style from './HeaderSearch.module.scss';
import {Button, SearchModal} from 'components';
import {ReactComponent as SearchIcon} from 'assets/images/icons/search.svg';

export const HeaderSearch: FC = () => {
    const {t} = useTranslation();
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

    const handleToggleSearchPopup = () => {
        setShowSearchModal(!showSearchModal);
    };

    return (
        <Fragment>
            <div className={style.search}>
                <Button onClick={handleToggleSearchPopup} theme='stroke' textContent={t('Search offer')} IconLeft={SearchIcon} kind='ellipse'/>
                <button className={`textBody_02 ${style.search_btn}`} onClick={handleToggleSearchPopup}>
                    <Trans i18nKey='header_search_text'>
                        Search by brand or <span className='brand_text'>insert your links</span>
                    </Trans>
                </button>
            </div>
            { showSearchModal && <SearchModal showModal={showSearchModal} setShowModal={setShowSearchModal} closeModal={handleToggleSearchPopup}></SearchModal>}
        </Fragment>

    );
};
