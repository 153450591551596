import { useEffect } from "react";
import { AppConfigInterface } from "typings";
import { useCommonSelector } from "./useCommonSelector";
import { useNavigate } from "react-router-dom";
import { MainRoutesEnum } from "core";

export const useBanned = () => {
  const appConfig = useCommonSelector<AppConfigInterface>("appConfig");
  const isBanned = appConfig?.user?.banned;
  const navigate = useNavigate();

  useEffect(() => {
    isBanned && navigate(MainRoutesEnum.ACCOUNT_BLOCKED);
  }, [isBanned, navigate]);
};
