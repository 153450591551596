import React, {HTMLAttributes} from 'react';
import styles from './BillingInfoTabs.module.scss';
import {setWithdrawalMethod} from '../../account.reducer';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {RootState} from 'core';
import {isWithdrawalMethodAvailable} from 'utils';
import {AppConfigInterface, LanguagesEnum, WithdrawalMethodsEnum} from 'typings';
import {useCommonSelector, useTransObjects} from 'hooks';
export interface IBillingInfoTabsProps extends HTMLAttributes<HTMLDivElement> {}

export const BillingInfoTabs = ({ className }: IBillingInfoTabsProps) => {
    const dispatch = useDispatch();
    const {withdrawalMethod} = useSelector((state: RootState) => state.accountReducer);
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const isRegionRu = appConfig?.country === LanguagesEnum.RU.toUpperCase();
    const {withdrawalMethods} = useTransObjects();
    return (
        <div className={`${className ? className : ''} ${styles.list}`}>
            {withdrawalMethods
                .filter(method => isWithdrawalMethodAvailable(method))
                .map(method => (
                <button
                    key={method.key}
                    onClick={()=> dispatch(setWithdrawalMethod({method: method.key as WithdrawalMethodsEnum, isRegionRu: isRegionRu}))}
                    className={cn(
                        'textBodyMedium_02',
                        styles.item,
                        {[styles.item_active]: withdrawalMethod.includes(method.key)}
                    )}>
                    {method.title}
                </button>
            ))}
        </div>
    );
};
