import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {TabInterface} from 'typings';
import tabsStyle from './Tabs.module.scss';


export const LinkTabs: FC<{ className?: string; tabs: Omit<TabInterface, 'paramValue'>[]; }> = ({className, tabs}) => {
    const activeClassName = `textBodyMedium_02 ${tabsStyle.tabItem} ${tabsStyle.tabItem_active}`;
    const passiveClassName = `textBodyMedium_02 ${tabsStyle.tabItem}`;

    return (
        <div className={`${className ? className : ''} ${tabsStyle.tabList}`}>
            {tabs.map((tab: Omit<TabInterface, 'paramValue'>) => (
                <NavLink to={tab.linkValue}
                         key={tab.linkValue}
                         className={({isActive}) => isActive ? activeClassName : passiveClassName}>
                    {tab.linkText}
                </NavLink>
            ))}
        </div>
    );
};
