import {LandingTypes} from '../../modules/Landing/typings';

export const getGaABTestLanding = (landingType: string, ab: string | undefined): string => {
    if (landingType === LandingTypes.DIRECTV1 || landingType === LandingTypes.DIRECTV2) {
        const abValue = ab !== undefined ? ';true' : '';
        return landingType === LandingTypes.DIRECTV1 ? `1${abValue}` : `2${abValue}`;
    }
    if (landingType === LandingTypes.TAKEADSV1 || landingType === LandingTypes.TAKEADSV2) {
        const abValue = ab !== undefined ? ';true' : '';
        return landingType === LandingTypes.TAKEADSV1 ? `1${abValue}` : `2${abValue}`;
    }
    return 'undefined';
};
