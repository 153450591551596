import { FC } from "react";
import { useTranslation } from "react-i18next";
import error500 from "assets/images/errors/error-500.svg";
import { Button } from "components";
import { Error } from "../Error";
import { useNavigate } from "react-router-dom";

const InternalError: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Error
      title={t("500 Error")}
      text={t("The server encountered an internal error or misconfiguration and was unable to complete your request.")}
      link={<a href="https://developer.mozilla.org/docs/Web/HTTP/Status/500" target="_blank" rel="noreferrer">Read more</a>}
      buttons={[<Button key="500_reload" onClick={() => navigate(-1)} textContent={t("Reload")} />]}
      image={<img src={error500} alt={t("500 Error")} width="330" height="257" />}
    />
  );
};

export { InternalError };
export default InternalError;
