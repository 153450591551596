import React, {FC, useState} from 'react';
import {Modal} from 'components';
import {DEFAULT_LANGUAGE, ModalContentIdConst, regionLanguages, WrapperPageContainerIdConst} from 'consts';
import {CountryRegistrationModalInterface} from './CountryRegistrationModal.interface';
import changeDialogStyle from './CountryRegistrationModal.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {CountryInterface} from 'typings';
import {ChooseCountryReg} from './components';
import {RootState, setSelectedRegion, setSiteLanguage} from 'core';
import {setGeoBonus} from 'modules';
import i18n from "i18next";
import {setCookie} from "../../utils";

export const CountryRegistrationModal: FC<CountryRegistrationModalInterface> = (props) => {
    const {setShowModal, showModal, currentGeo}= props;
    const dispatch = useDispatch();
    const [loggedIn] = useState<boolean>(false);
    const {countries, geo} = useSelector((state: RootState) => state.commonReducer);

    const handleGeoChange = (value) => {
        const userRegionObj = countries.find(country => country.value === value);
        dispatch(setSelectedRegion(userRegionObj as CountryInterface));
        dispatch(setGeoBonus(userRegionObj?.bonus || ''));
        setCookie('country', userRegionObj?.value || '');
        setShowModal(false);
        const userLang = regionLanguages[userRegionObj?.value || ''] || DEFAULT_LANGUAGE;
        dispatch(setSiteLanguage(userLang));
        i18n.changeLanguage(userLang);
    };

    const handleBackClick = () => {
        setShowModal(false);
    };

    return (
        <Modal active={showModal}
               setActive={setShowModal}
               closable={false}
               size={'l'}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <div className={changeDialogStyle.dialog}>
                <ChooseCountryReg geo={geo}
                                  currentGeo={currentGeo}
                                  loggedIn={loggedIn}
                                  onChangeClick={(e) => handleGeoChange(e)}
                                  onBackClick={handleBackClick}/>
            </div>
        </Modal>
    );
};
