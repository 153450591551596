import React, {FC} from 'react';
import style from './BannerExtension.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import {Button} from 'components';
import {InternalLinksEnum} from 'typings';
import {ReactComponent as ChromeIcon} from 'assets/images/banners/chrome-logo.svg';
import adobeStokeLogo from 'assets/images/banners/adobe-stoke.png';
import {openNewLocation} from 'utils';


export const BannerExtension: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const handleBannerClick = () => {
        openNewLocation(InternalLinksEnum.EXTENSION, true);
    };
    return (
        <div className={style.banner} onClick={handleBannerClick}>
            <div className={style.banner_description}>
                <h3 className={`${isMobile ? 'textHeader_07' : 'textHeader_06'} ${style.banner_title}`}>
                    <Trans i18nKey={'banner_account_extension_title'}>
                        Never miss <span className='brand_text'>a cashback opportunity</span> while shopping online
                    </Trans>
                </h3>
                <p
                  className={`${isMobile ? 'textBody_04' : 'textBody_03'}`}>
                    {t(`Install Giftmio extension for smart shopping: get notified on cashback opportunities, activate cashback in one click, and save money on purchases`)}
                </p>
            </div>
            {isMobile &&
              <div className={style.buttons}>
                <Button textContent={t('Add to chrome')}
                        IconLeft={ChromeIcon}
                        theme={'stroke'}
                        kind={'ellipse'}>
                </Button>
                <img src={adobeStokeLogo} alt={'Giftmio bells'} height={80} width={85}></img>
              </div>
            }
        </div>
);
};
