import React, {FC, useState} from 'react';
import accordionItemStyle from './AccordionItem.module.scss';
import plusImg from 'assets/images/icons/plus.svg';
import minusImg from 'assets/images/icons/minus.svg';
import {AccordionContent} from '../accordionContent/AccordionContent';
import {useMedia} from 'hooks';
import classNames from 'classnames/bind';


export const AccordionItem: FC<{title: string; contentKey: string; withoutBorder?: boolean;}> = (props) => {
    const {title, contentKey, withoutBorder = false} = props;
    const [isActive, setIsActive] = useState(false);
    const isMobile = useMedia();

    const titleClassName = classNames(
        accordionItemStyle.accordionItem__title,
        {[accordionItemStyle.accordionItem__title_without_border]: withoutBorder},
    );

    const onAccordionHandler = () => {
        setIsActive(!isActive);
    }

    return (
        <details className={accordionItemStyle.accordionItem}>
            <summary className={titleClassName} onClick={onAccordionHandler}>
                <h3 className={`${isMobile ? 'textBodyMedium_02': 'textBodyMedium_01'}`}>{title}</h3>
                <img src={isActive ? minusImg : plusImg} alt={isActive ? 'minus' : 'plus'}></img>
            </summary>
             <div className={`${isMobile ? 'textBody_03': 'textBody_02'} ${accordionItemStyle.accordionItem__content}`}>
              <AccordionContent contentKey={contentKey}/>
            </div>
        </details>
    );

};
