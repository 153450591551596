import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {getAvailableIdentityProvidersRequest, createUserRequest, getDirectLinkSearchRequest} from './landing.api';
import {
    getIdentityProviders,
    hideLandingLoader,
    setIdentityProviders,
    showLandingLoader,
    getSearchOffer,
    setSearchOffer, createSsoUser, setJwtToken, setRegistrationFormErrors
} from './landing.reducer';
import {notificationListPushItem} from 'core';
import {FallbackErrorTextConst} from 'consts';
import {ENotificationsThemes} from '../../components/Notifications';
import {CreateUserDto} from './typings';


function* getAvailableIdentityProvidersWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showLandingLoader());
        const res: any = yield call(getAvailableIdentityProvidersRequest, {});
        yield put(setIdentityProviders(res?.data || {}));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideLandingLoader());
    }
}

function* getSearchOfferWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showLandingLoader());
        const searchOffer = yield call(getDirectLinkSearchRequest, {queryParams: payload.payload.queryParams});
        yield put(setSearchOffer(searchOffer));

    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideLandingLoader());
    }
}

function* createSsoUserWorker(payload: any): Generator<StrictEffect, void, CreateUserDto> {
    try {
        yield put(showLandingLoader());
        const response = yield call(createUserRequest, {data: payload.payload.data, headers: payload.payload.headers});
        yield put(setJwtToken(response.data?.magicJwt));
    } catch (error: any) {
        if (error?._meta) {
            yield put(setRegistrationFormErrors(error._meta));
        }
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error?.message || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));

    } finally {
         yield put(hideLandingLoader());
    }
}



export function* landingWatcher() {
    yield takeEvery(getIdentityProviders.type, getAvailableIdentityProvidersWorker);
    yield takeEvery(getSearchOffer.type, getSearchOfferWorker);
    yield takeEvery(createSsoUser.type, createSsoUserWorker);
}
