import React, {FC} from 'react';
import style from './OfferHeaderMega.module.scss';
import offerHeaderStyle from '../header/OfferHeader.module.scss';
import {useMedia} from 'hooks';
import {Trans, useTranslation} from 'react-i18next';
import {OfferHeaderMegaInterface} from "./OfferHeaderMega.interface";



export const OfferHeaderMega: FC<OfferHeaderMegaInterface> = (props) => {
    const {offer} = props;
    const {cashback = '10%', cashback_origin} = offer;
    const {t} = useTranslation();
    const isMobile = useMedia();

    return (
        <div className={offerHeaderStyle.offer_header}>
            <div className={offerHeaderStyle.offer_header__logo}>
                <span className={`textHeader_08 ${style.logo_text}`}>
                    <Trans i18nKey='megaCashback_name'><span className='brand_text'>Super</span>Cashback</Trans>
                </span>
            </div>
            <div className={offerHeaderStyle.offer_header__content}>
                <h1 className={`${isMobile ? 'textHeader_06' : 'textHeader_04'}`}>
                    <span>{t(`SuperCashback. {{name}}.`, {name: offer?.name || 'X'})}</span>&nbsp;
                    <span className={style.title_text}>
                        {!cashback_origin
                            ? <Trans i18nKey='offerDetailsUpTo' cashback={cashback}>
                                up to {{cashback}} Cashback
                            </Trans>
                            : <Trans i18nKey='offerDetailsUpToWithOrigin' cashback={cashback} cashback_origin={cashback_origin}>
                                up to <span className={offerHeaderStyle.offer_header__with_origin}>{{cashback_origin}}</span> {{cashback}} Cashback
                            </Trans>
                        }
                    </span>
                </h1>
                <span className={`${isMobile ? 'textBody_04' : 'textBody_02'} ${style.description}`}>{t(`SuperCashback allows you to earn cashback even in stores that are not listed in the Giftmio catalog. Just click on the "Get cashback" button and enjoy shopping.`)}</span>
            </div>
        </div>
    );
};
