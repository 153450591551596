import React, {FC, Fragment} from 'react';
import styles from './UserMenu.module.scss';
import {ReactComponent as LogoutIcon} from 'assets/images/menu/logout.svg';
import {menuIcons} from 'utils';
import {Avatar, LinkComponent} from 'components';
import {useTranslation} from 'react-i18next';
import {SsoConst} from 'consts';
import {UserMenuInterface} from './UserMenu.interface';
import {useMedia, useTransObjects} from 'hooks';
import {MainRoutesEnum} from 'core';
import {AccountRoutesPathEnum} from 'core/Routes/typings/enums/AccountRoutesPath.enum';
import {InternalLinksEnum} from "../../typings";
import {ReactComponent as ChromeIcon} from 'assets/images/banners/chrome-logo.svg';
import cn from 'classnames';

export const UserMenu: FC<UserMenuInterface> = (props) => {
    const {t} = useTranslation();
    const {user, isAccount = false, onCloseClick} = props;
    const {userMenuTabs} = useTransObjects();
    const isMobile = useMedia();
    const myDetailsUrl= ['prod'].includes(`${process.env.REACT_APP_ENVIRONMENT_NAME}`)
        ? 'https://id.giftmio.com/personal-data'
        : 'https://stage-current.id.giftmio.com/personal-data';
    const handleUserMenuClose = () => {
        if (isAccount) {
            return;
        }
        if (onCloseClick) {
            onCloseClick();
        }
    }

    const getMenuUrl = (menuItem) => {
        const EARNINGS = 'earnings'
        return `${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum[menuItem.urlPath]}${menuItem.key === EARNINGS ? EARNINGS : ''}`;
    }

    return (
        <Fragment>
            <div className={isAccount ? styles.menu__header : styles.menu__list_header}>
                {(isMobile || isAccount) && <Avatar type={'logo'}/>}
                <div className={styles.menu__header_user_info}>
                    {/*<span className={`textBodyMedium_07 ${styles.menu__header_user_status}`}>Beginner</span>*/}
                    <span className={styles.menu__header_user_email}>{user?.email}</span>
                </div>
            </div>
            <div className={isAccount ? '' : styles.menu__list}>
                {userMenuTabs.map(menuItem => (
                    <div className={isAccount ? '' : styles.menu__list_link} key={menuItem.key}>
                        <LinkComponent onClick={handleUserMenuClose}
                                       href={menuItem.key === 'myDetails' ? myDetailsUrl : undefined}
                                       to={menuItem.key !== 'myDetails' ? getMenuUrl(menuItem) : undefined}
                                       isNavLink={isAccount}
                                       withArrow={isMobile}
                                       textContent={menuItem.title}
                                       Icon={menuIcons[menuItem.key]}
                                       iconTheme={'tertiary'}
                                       theme={isAccount ? 'menu' : 'stroke'}
                                       iconSize={'m'}/>
                    </div>
                ))}
            </div>
            {(isMobile && !isAccount) &&
              <div className={cn(styles.menu__list_link, styles.menu__list_link_chrome)}>
                <LinkComponent
                  Icon={ChromeIcon}
                  iconSize={'m'}
                  href={InternalLinksEnum.EXTENSION}
                  target={'_blank'}
                  theme={'stroke'}
                  withArrow={isMobile}
                  textContent={t('Chrome Extension')}/>
              </div>
            }
            <div className={isAccount ? styles.menu__logout : styles.menu__list_logout} >
                <LinkComponent Icon={LogoutIcon} iconSize={'m'} href={SsoConst.logout} onClick={handleUserMenuClose} theme={'error'} textContent={t('Log out')}/>
            </div>
        </Fragment>

    );
};
