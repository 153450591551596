export const regionLanguages = {
    AR: 'es',
    AT: 'de',
    BE: 'fr',
    BR: 'pt',
    CH: 'de',
    CL: 'es',
    CO: 'es',
    DE: 'de',
    ES: 'es',
    FR: 'fr',
    IT: 'it',
    MX: 'es',
    PE: 'es',
    PL: 'pl',
    PT: 'pt',
    RU: 'ru',
};
