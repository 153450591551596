import React, {FC, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {UIKitContextProvider} from '@design-system/ui-kit';
import './App.module.scss';
import appStyle from './App.module.scss';
import {Breadcrumbs, CountryModal, Footer, FooterLight, Header, HeaderLight, LayoutContainer, LoaderWithOverlay} from 'components';
import {AppRouter} from '../Routes';
import {NotificationsList} from '../NotificationList';
import {DEFAULT_LANGUAGE, LanguagesConst, MainLanguages, ModalContentIdConst, regionLanguages} from 'consts';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store';
import {
    clientid,
    getPostHogCookie,
    initConsentToolScript,
    initGaScript,
    initPostHogScript,
    isLandingPage, sendGaEvent,
    setPostHogPageView
} from 'utils';
import cashbackImg from "assets/images/landing/cashback.svg";
import {AnalyticEventNameEnum, AppConfigInterface} from 'typings';
import {useCommonSelector} from 'hooks';
import * as Sentry from '@sentry/react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {setSiteLanguage} from '../common';
import { LandingTypes } from 'modules/Landing/typings';



const App: FC = () => {
    const { i18n} = useTranslation();
    const {showCountryDialog, isLoading} = useSelector((state: RootState) => state.commonReducer);
    const [showCountryModal, setShowCountryModal] = useState<boolean>(false);
    const [stopRecursion, setStopRecursion] = useState<boolean>(false);
    const [pageType, setPageType] = useState<string>('');
    const dispatch = useDispatch();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const {landingType} = useSelector((state: RootState) => state.landingReducer);
    const [distinctId, setDistinctId] = useState<string>('');
    const timerRef = useRef(undefined);
    const intervalRef = useRef(undefined);
    const location = useLocation();
    const configCountry = appConfig?.country;
    const setGaPageView = useCallback(() => {
        if (!window.gtag) {
            initGaScript();
        }
        const clientIdGa = clientid();
        if ((typeof clientIdGa === 'undefined' || !clientIdGa) && !stopRecursion) {
            // @ts-ignore
            timerRef.current = setTimeout(function(){
                setGaPageView();
            }, 500 );
        } else {
            clearTimeout(timerRef.current);
            clearInterval(intervalRef.current);
            sendGaEvent(AnalyticEventNameEnum.PAGEVIEW);
        }
    }, [timerRef, stopRecursion, intervalRef]);

    useEffect(() => {
        if (!appConfig.lang) {
            return;
        }
        const userId = appConfig.user?.user_id;
        const isLandingURI = isLandingPage();
        const isFakeURI = isLandingPage(true);
        if (isLandingURI) {
            setPageType('light');
        } else if (isFakeURI) {
            setPageType('fake');
        } else {
            setPageType('normal');
        }
        if (process.env.REACT_APP_ENVIRONMENT_NAME === 'prod' && !isLandingURI) {
            let phCookie = getPostHogCookie();
            if (!phCookie) {
                initPostHogScript();
            }
            phCookie = getPostHogCookie();
            let dId: string;
            try {
                dId = JSON.parse(phCookie)?.distinct_id || '';
            } catch {
                dId = '';
            }
            if (distinctId === dId) {
                return;
            }
            setDistinctId(dId);
            setPostHogPageView(dId, userId);
        }
        if (!isLandingURI) {
            setGaPageView();
            // @ts-ignore
            intervalRef.current = setInterval(() => {setStopRecursion(true)}, 10000);

            if (!window['cmp_id']) {
                initConsentToolScript();
            }
        }
        return () => {
            clearTimeout(timerRef.current);
            clearInterval(intervalRef.current);
        };

        // initMindboxScript();
    }, [location, appConfig, setGaPageView]);

    useEffect(() => {
        if (configCountry === undefined) {
            return;
        }
        const isLandingURI = isLandingPage();
        const browserLang = (window.localStorage.i18nextLng || '').toLowerCase();
        if ( (!isLandingURI && MainLanguages.find(lang => lang.value === browserLang))
            || (isLandingURI && LanguagesConst.find(lang => lang === browserLang))) {
            dispatch(setSiteLanguage(browserLang));
            return;
        } else {
            const langValue = regionLanguages[configCountry as string] || DEFAULT_LANGUAGE;
            dispatch(setSiteLanguage(langValue));
            setTimeout(()=> {
                i18n.changeLanguage(langValue);
            });
        }
    }, [i18n, configCountry, dispatch]);

    useEffect(() => {
        if (pageType !== 'normal') {
            return;
        }
        setShowCountryModal(showCountryDialog);
    }, [showCountryDialog, pageType]);

    return (
       <UIKitContextProvider theme="giftmioTheme">
           <Fragment>
               <div className={`${appStyle.page}`}>
                   {pageType === 'normal' && <Header/>}
                   {pageType === 'light' && <HeaderLight/>}
                   <LayoutContainer isLandingPage={pageType === 'light'}>
                       <div className={`loader__container`}>
                           <AppRouter/>
                           <Breadcrumbs/>
                           {isLoading && <LoaderWithOverlay/>}
                       </div>
                   </LayoutContainer>
                   {pageType === 'normal' && <Footer/>}
                   {pageType === 'light' && <FooterLight/>}
                   {showCountryModal && <CountryModal setShowModal={setShowCountryModal} showModal={showCountryModal}/>}
               </div>
               <NotificationsList/>
               <div id={ModalContentIdConst}/>
           </Fragment>
      </UIKitContextProvider>
    );
}

export default Sentry.withProfiler(App);
