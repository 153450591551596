import {all} from 'redux-saga/effects';
import {commonWatcher} from '../common';
import {accountWatcher, catalogWatcher, landingWatcher, offerWatcher} from 'modules';


export function* rootWatcher() {
    yield all([
        commonWatcher(),
        catalogWatcher(),
        offerWatcher(),
        accountWatcher(),
        landingWatcher(),
    ]);
}
