export enum AnalyticEventOptionsEnum {
    WEBSITE = 'website',
    ACCOUNT = 'account',
    HEADER = 'header',
    TOP_OFFERS = 'top offers',
    PROGRAM_CARD = 'program card',
    MY_CASHBACK = 'my cashback',
    HOMEPAGE = 'homepage',
    WITHDRAWALS = 'withdrawals',
    CONFIRM_EMAIL = 'confirm email',
    BANNER = 'banner',
    BILLING_INFO = 'billing info',
    PROMO_CODES = 'promo codes',
    REFERRAL_PROGRAM = 'referral program',
    DIRECT_LINK = 'direct-link',
    DIRECT_LINK_V2 = 'direct-link;registration',
    TAKE_ADS_V1 = 'promo landing 1',
    TAKE_ADS_V2 = 'promo landing 2',
    EXTENSION = 'extension',
    WELCOME_LINK = 'welcome page;registration',
}
