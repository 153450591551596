import React, {FC} from 'react';
import styles from './BannerNarrow.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import {Button} from 'components';
import {InternalLinksEnum} from 'typings';
import {ReactComponent as ChromeIcon} from 'assets/images/banners/chrome-logo.svg';
import adobeStokeLogo from 'assets/images/banners/adobe-stoke.png';
import {openNewLocation} from 'utils';


export const BannerNarrow: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const handleBannerClick = () => {
        openNewLocation(InternalLinksEnum.EXTENSION, true);
    };
    return (
        <div className={styles.banner} onClick={handleBannerClick}>
            {!isMobile &&
              <img src={adobeStokeLogo} className={styles.image} alt={'Giftmio bells'} height={100} width={107}></img>
            }
            <div className={styles.content}>
                <h3 className={`${isMobile ? 'textHeader_07' : 'textHeader_06'} ${styles.banner_title}`}>
                    <Trans i18nKey={'banner_narrow_extension_title'}>
                        Get notified on <span className='brand_text'>cashback opportunities</span> and activate cashback in one click
                    </Trans>
                </h3>
              <div className={styles.buttons}>
                <Button textContent={t('Add to chrome')}
                        IconLeft={ChromeIcon}
                        theme={'fill'}
                        kind={'ellipse'}>
                </Button>
                {isMobile && <img src={adobeStokeLogo} alt={'Giftmio bells'} height={80} width={85}></img>}
              </div>
            </div>


        </div>
    );
};
