import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {
    getOfferDetails,
    setOfferDetails,
    hideOfferLoader,
    showOfferLoader,
    showOfferError,
    hideOfferError
} from './offer.reducer';
import {getOfferDetailsRequest} from './offer.api';


function* getOfferDetailsWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showOfferLoader());
        yield put(hideOfferError());
        const offerId: string =  payload.payload.offerId;
        const data: any = yield call(getOfferDetailsRequest, offerId);
        if (data) {
            yield put(setOfferDetails(data as any));
            yield put(hideOfferLoader());
        }
    } catch (error) {
        yield put(showOfferError());
        yield put(hideOfferLoader());
    }
}



export function* offerWatcher() {
    yield takeEvery(getOfferDetails.type, getOfferDetailsWorker);
}
