import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {StoreListInterface} from './StoreList.interface';
import storeListStyle from './StoreList.module.scss';
import {StoreCard} from '../StoreCard/StoreCard';
import {BlockContainer} from '../BlockContainer/BlockContainer';


export const StoreList: FC<StoreListInterface> = (props) => {
    const {title, stores, currerntCategory, endFragment = null} = props;
    const {t} = useTranslation();
    return (
        <>
            {!!stores?.length && <BlockContainer title={title || t('Popular stores')} endFragment={endFragment}>
                <div className={storeListStyle.stores}>
                    {stores.map(store => (
                        <StoreCard
                            key={store.id}
                            src={store.image}
                            cashback={store.cashback}
                            name={store.name}
                            slug={store.slug}
                            currerntCategory={currerntCategory}
                        />
                    ))}
                </div>
            </BlockContainer>}
        </>

    );
};
