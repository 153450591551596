import React, {FC, Fragment, useState} from 'react';
import style from './MegaCard.module.scss';
import {Button, MenuMobileSearch, SearchModal} from 'components';
import {ReactComponent as SearchIcon} from 'assets/images/icons/search.svg';
import {Trans, useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import {OfferInterface} from 'typings';


export const MegaCard: FC<{stores: OfferInterface[]}>= ({stores}) => {
    const {t} = useTranslation();
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
    const isMobile = useMedia();

    const handleToggleSearchPopup = () => {
        setShowSearchModal(!showSearchModal);
    };

    return (
        <Fragment>
            <div className={style.offer} onClick={handleToggleSearchPopup}>
                <div className={style.offer_header}>
                    <span className={`textHeader_08 ${style.offer_header_logo}`}>
                        <Trans i18nKey='megaCashback_name'><span className='brand_text'>Super</span>Cashback</Trans>
                    </span>
                </div>
                <hr  className={style.offer_hr}></hr>
                <div className={style.offer_body}>
                    <p className={`textBodyMedium_02`}>{t('SuperCashback - Quick search across more than 30,000 stores.')}</p>
                    <p className={`textBody_03 ${style.offer_body_text}`}>{t(`Copy the link to the store you're interested in and check here to see if you can get cashback from it.`)}</p>
                </div>
                <div className={style.offer_footer}>
                    <Button onClick={()=> {}} textContent={t('Check for Cashback')} IconLeft={SearchIcon} kind='ellipse' isFullWidth={true}/>
                </div>
            </div>
            {!isMobile && showSearchModal && <SearchModal showModal={showSearchModal} setShowModal={setShowSearchModal} closeModal={handleToggleSearchPopup}></SearchModal>}
            {isMobile && showSearchModal && <MenuMobileSearch popularStores={stores} onHandleToggleSearch={handleToggleSearchPopup}/>}
        </Fragment>
    );
};
