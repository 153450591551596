import React, {FC, Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import giftMioLogo from 'assets/images/header/header-logo.svg';
import headerStyle from './Header.module.scss';
import {LayoutContainer} from "../LayoutContainer/LayoutContainer";
import {useCommonSelector, useMedia} from 'hooks';
import {CategoriesMenu, CategoriesMenuMobile, UserHeaderAuth, UserHeaderProfile, HeaderSearch} from './components';
import {AppConfigInterface, UserInterface} from 'typings';


export const Header: FC = () => {
    const isMobile = useMedia();
    // const scrollPosition = useScrollPosition();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const [user, setUser] = useState<UserInterface>();
    const [country, setCountry] = useState<string>('');
    const [showUserHeaderProfile, setShowUserHeaderProfile] = useState<boolean>(false);
    // const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

    useEffect(() => {
        if (!appConfig?.lang) {
            return;
        }
        setUser(appConfig.user);
        setCountry(appConfig.country || '');
        setShowUserHeaderProfile(true);
    }, [appConfig]);

    // useEffect(() => {
    //     setShowMobileMenu(isMobile && scrollPosition > 0);
    // }, [scrollPosition, isMobile]);

    return (
        <Fragment>
            <header className={headerStyle.header}>
                <LayoutContainer>
                    <div className={headerStyle.header__content}>
                        <Link to={'/'} className={headerStyle.header__logo}>
                            <img src={giftMioLogo} alt="GiftMio"/>
                        </Link>
                        {!isMobile && <HeaderSearch/>}
                        {showUserHeaderProfile && (!!user ? <UserHeaderProfile user={user}/> : <UserHeaderAuth country={country}/>) }
                    </div>
                </LayoutContainer>
            </header>
            {isMobile ? <CategoriesMenuMobile/> : <CategoriesMenu/>}
        </Fragment>
    );
};
