import React, {FC} from 'react';
import style from './HelpBlock.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import {openNewLocation} from 'utils';
import {InternalLinksEnum} from 'typings';


export const HelpBlock: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const handleExtensionClick = () => {
        openNewLocation(InternalLinksEnum.EXTENSION, true);
    };
    return (
        <div className={style.help__wrapper}>
            <h2 className={`${style.help__title} ${isMobile ? 'textHeader_07' : 'textHeader_06'}`}>
                {t('How to earn money with giftmio')}
            </h2>
            <div className={`${isMobile ? 'textHeader_09' : 'textHeader_05'} ${style.help}`}>
                <div onClick={handleExtensionClick} className={`${style.help__item} ${style.help__item_one}`}>
                    {t('Install Chrome extension')}
                </div>
                <div className={`${style.help__item} ${style.help__item_two}`}>
                    <Trans i18nKey={'help_block_register_free'}>
                        Register <br/> for free
                    </Trans>
                </div>
                <div className={`${style.help__item} ${style.help__item_three}`}>
                    {t('Shop through our links')}
                </div>
                <div className={`${style.help__item} ${style.help__item_four}`}>
                    {t('Make purchases and earn money')}
                </div>
            </div>
        </div>

    );
};
