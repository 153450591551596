import React, {FC} from 'react';
import style from './JoinGiftmio.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from '../Button/Button';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {LandingTypes} from "../../modules/Landing/typings";
import {SsoConst} from 'consts';
import {getSsoLocales} from 'utils';

export const JoinGiftmio: FC = () => {
    const {t} = useTranslation();
    const { landingType, geoBonus} = useSelector((state: RootState) => state.landingReducer);

    const handleJoinGiftmioCLick = () => {
        if (landingType === LandingTypes.WELCOME || landingType === LandingTypes.DIRECTV2) {
            window.scrollTo(0, 0);
        } else {
            window.location.href = `${SsoConst.login}?${getSsoLocales()}`;
        }
    };
    return (
        <div className={style.content}>
            {!!geoBonus && <p className={'textButton_02'}>
                <Trans i18nKey='welcomeLandingGetBonus' geoBonus={geoBonus}>
                    Register now and get a <span className='brand_text'>{{geoBonus}} bonus</span>
                </Trans>
            </p>}
            <Button textContent={t('Join Giftmio')} kind={"ellipse"} onClick={handleJoinGiftmioCLick}/>
        </div>
    );
};
