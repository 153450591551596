import React, {FC, useMemo} from 'react';
import supportStyle from './Support.module.scss';
import {useTranslation} from "react-i18next";
import {Accordion} from 'components';
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {useAccountTitle} from '../../hooks';
import {InfoMenuEnum} from 'typings';

const Support: FC = () => {
    const {t} = useTranslation();
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(InfoMenuEnum.SUPPORT, true);

    useDocumentTitle(documentTitles[InfoMenuEnum.SUPPORT]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[InfoMenuEnum.SUPPORT] }],
        [documentTitles[InfoMenuEnum.SUPPORT]]);
    useBreadcrumbs(breadcrumbs);
    return (
        <div>
            <div className={supportStyle.content}>
                <p className={`textBody_01`}>{t('If you need support regarding your account, cashback tracking or claims, or you have any problems with anything else, you can use the FAQ sections or email us ')}
                    <a href="mailto:support@giftmio.com">support@giftmio.com</a>.
                </p>
            </div>
            <Accordion withExtension={true}/>
        </div>
    );
}

export {Support};
export default Support;
