import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Input, Pagination} from '@design-system/ui-kit';
import {
    AnalyticEventNameEnum,
    AnalyticEventOptionsEnum,
    PaginationEnum,
    PostHogOptionsEnum,
    PromocodesFormInterface,
    TabInterface,
    UserMenuEnum
} from 'typings';
import {useBreadcrumbs, useTransObjects, useDocumentTitle, useMedia} from 'hooks';
import style from './PromoCodes.module.scss';
import {
    getPromoCodeList,
    clearAccountFormError,
    registerPromoCode,
} from '../../account.reducer';
import {AccountRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {Button, Empty, LoaderWithOverlay, TabsComponent} from 'components';
import {sendAnalyticEvents, setPaginationParams} from 'utils';
import {PromocodesControlsEnum, PromoFilterEnum, PromoStateEnum} from '../../typings';
import {PromoCard} from '../../componets';
import {promoCodesConst} from 'consts';
import {useAccountTitle} from '../../hooks';


const PromoCodes: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const dispatch = useDispatch();
    const {appConfig} = useSelector((state: RootState) => state.commonReducer);
    const {promoCodeList, promoCodeCount, isLoading, formErrors} = useSelector((state: RootState) => state.accountReducer);
    const [formState, setFormState] = useState<PromocodesFormInterface>({} as PromocodesFormInterface);
    const [disableButton, setDisableButton] = useState<boolean>(true);
    const [promoCount, setPromoCount] = useState<number>(0);
    const [hasUserBalance, setHasUserBalance] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const {getPageTitle, documentTitles, tableTexts} = useTransObjects();
    const title = getPageTitle(UserMenuEnum.PROMO_CODES);

    const tabs: Omit<TabInterface, 'linkValue'>[] = [{
        linkText: t('All'),
        paramValue: PromoStateEnum.ALL,
    }, {
        linkText: t('tabsActive', 'Active'),
        paramValue: PromoStateEnum.ACTIVE,
    }, {
        linkText: t('tabsInactive', 'Inactive'),
        paramValue: PromoStateEnum.IN_ACTIVE,
    }];
    const handleInputChange = (controlName: PromocodesControlsEnum) => {
        dispatch(clearAccountFormError(controlName));
    };

    const handleRegistrationChange = useCallback((promoParam, newSearchParams) => {
        const promoForm = {[PromocodesControlsEnum.PROMOCODE]: promoParam};
        setFormState((prevState) => ({...prevState, ...promoForm}));
        dispatch(registerPromoCode({data: promoForm}));
        newSearchParams.delete('promo');

        navigate({search: newSearchParams.toString(),}, {replace: true});
    }, [dispatch, navigate]);
    const onInputChange = (controlName: PromocodesControlsEnum) => (newValue: string) => {
        setFormState({
            ...formState,
            [controlName]: newValue});
        handleInputChange(controlName);
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };
    const handleRegisterPromoRequest = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.PROMO_CODES,
            event_option4: formState[PromocodesControlsEnum.PROMOCODE]
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.APPLY,
            button_location: AnalyticEventOptionsEnum.PROMO_CODES
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_APPLY_PROMO_CODE, {gaOptions, postHogOptions});

        dispatch(registerPromoCode({data: formState}));
    };

    const handlePaginationChange = (params: any) => {
        setSearchParams(setPaginationParams(params, searchParams));
    };

    useDocumentTitle(documentTitles[UserMenuEnum.PROMO_CODES]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[UserMenuEnum.PROMO_CODES] }],
        [documentTitles[UserMenuEnum.PROMO_CODES]]);
    useBreadcrumbs(breadcrumbs);
    useEffect(() => {
        if (!formState || !Object.entries(formState).length) {
            return;
        }
        let isDisabled = !formState?.promocode;
        Object.entries(formState).forEach(entry => {
            const [value] = entry;
            if (!value) {
                isDisabled = true;
                return;
            }
        });
        setDisableButton(isDisabled);
    }, [formState]);

    useEffect(() => {
        if (!appConfig?.user) {
            return;
        }
        setPromoCount(appConfig.user.promo_count || 0);
        setHasUserBalance(!!appConfig?.user?.balance);
    }, [appConfig]);

    useEffect(() => {
        if (!hasUserBalance) {
            return;
        }
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        const promoParam = newSearchParams.get('promo');

        if (!!promoParam && promoParam === promoCodesConst.startPromoCode && hasUserBalance) {
            handleRegistrationChange(promoParam, newSearchParams);
            return;
        }

        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        dispatch(getPromoCodeList({queryParams: newSearchParams, controller}));

        return () => {
            controller.abort();
        };
    }, [dispatch, searchParams, hasUserBalance, handleRegistrationChange]);

    return (
        <section className='page__content'>
            <div className={`${style.content} page__blank loader__container page__content_container`}>
                <div className={style.promo}>
                    <h2 className={`${style.promo__title} textHeader_08`}>{t('Add promo codes')}</h2>
                    <p className={`textBody_01`}>{t('Promo codes allow you to increase the amount of your cashback. If you have a Promo code, enter it and click «Apply».')}</p>
                    <div className={style.promo_add}>
                        <div className={style.promo_add__field}>
                            <Input name={PromocodesControlsEnum.PROMOCODE}
                                   placeholder={t('Add promo code')}
                                   required
                                   value={formState[PromocodesControlsEnum.PROMOCODE]}
                                   onChange={onInputChange(PromocodesControlsEnum.PROMOCODE)}
                                   errorMessage={formErrors?.[PromocodesControlsEnum.PROMOCODE]?.toString()}
                                   onBlur={onInputBlur}/>
                        </div>
                        <div className={style.promo_add__btn}>
                            <Button disabled={disableButton || !!Object.keys(formErrors)?.length || isLoading} textContent={t('Apply')} isFullWidth={isMobile} onClick={handleRegisterPromoRequest}/>
                        </div>
                    </div>
                </div>
                {!!promoCount &&
                  <div className={style.promocodes}>
                    <div className="tabsBlock">
                      <TabsComponent className="tabsBlockTabs"
                                     defaultParamValue={PromoStateEnum.ALL}
                                     link={`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.PROMO}`}
                                     paramName={PromoFilterEnum.STATUS}
                                     allParam={PromoStateEnum.ALL}
                                     tabs={tabs}/>
                    </div>
                    <h2 className={`${style.promo__title} textHeader_08`}>{t('Added promo codes')}</h2>
                    {!isLoading && !promoCodeCount && <Empty title={tableTexts.noRowsPlaceholder}/>}
                    {!!promoCodeCount && promoCodeList.map((promo) => (
                        <PromoCard key={Math.random()} promo={promo}/>
                    ))}
                      {(Number(promoCodeCount) > 20)
                          && <Pagination itemsCount={promoCodeCount || 0}
                                      itemsPerPage={Number(searchParams.get(PaginationEnum.LIMIT)) || 20}
                                      pageNumber={Number(searchParams.get(PaginationEnum.OFFSET) || 0) / Number(searchParams.get(PaginationEnum.LIMIT) || 20) + 1}
                                      onChange={handlePaginationChange}
                                      hidePerPageDropdown={isMobile}
                                      isMobile={isMobile}
                                      perPageDropdownOptions={[20]}
                                      texts={tableTexts}/>
                      }
                  </div>
                }
                {isLoading && <LoaderWithOverlay/>}
            </div>
        </section>
    );
}

export {PromoCodes};
export default PromoCodes;
