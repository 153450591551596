import React, {FC} from 'react';
import confirmEmail from './ConfirmEmail.module.scss';
import {useTranslation} from 'react-i18next';
import {Button} from 'components';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, PostHogOptionsEnum} from 'typings';
import {sendAnalyticEvents} from 'utils';


export const ConfirmEmail: FC = (props) => {
    const {t} = useTranslation();
    const prodUrl = ' https://id.giftmio.com/security/email/verify';
    const stageUrl = 'https://stage-current.id.giftmio.com/security/email/verify';

    const handleConfirmClick = () => {
        const url = process.env.REACT_APP_ENVIRONMENT_NAME === 'prod' ? prodUrl : stageUrl;
        const gaOptions = {
            event_option2: `${AnalyticEventOptionsEnum.WITHDRAWALS}; ${AnalyticEventOptionsEnum.CONFIRM_EMAIL}`,
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.SEND_AGAIN,
            button_location: AnalyticEventOptionsEnum.WITHDRAWALS
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_SEND_AGAIN, {gaOptions, postHogOptions});
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow)  {
            newWindow.opener = null;
        }
    };
    return (
        <div className={confirmEmail.amount}>
            <div className={confirmEmail.amount__left}></div>
            <div className={confirmEmail.amount__right}>
                <div className={confirmEmail.amount__content}>
                    <span className={`textHeader_08`}>{t('Confirm your email')}</span>
                    <span className={`textBodyMedium_04 ${confirmEmail.amount__content_text}`}>{t(`To cash out, please confirm your email address by clicking the link we sent to your inbox. Didn't receive it? `)}</span>
                </div>
                <Button kind={'ellipse'} theme={'stroke'} textContent={t('Resend the email')} onClick={handleConfirmClick}></Button>
            </div>
        </div>
    );
};
