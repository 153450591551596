import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import style from './ReferralHelp.module.scss';


export const ReferralHelp: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();

    return (
        <div className={style.help__wrapper}>
            <h2 className={`${style.help__title} ${isMobile ? 'textHeader_07' : 'textHeader_06'}`}>
                {t('How our referral program works')}
            </h2>
            <div className={`textHeader_08 ${style.help}`}>
                <div className={`${style.help__item} ${style.help__item_one}`}>
                    <span className={style.help__item_text1}>{t('Your friend registers an account ')}</span>
                </div>
                <div className={`${style.help__item} ${style.help__item_two}`}>
                    <span className={style.help__item_text2}>{t('He make qualifying purchases for $10 within 90 days')}</span>

                </div>
                <div className={`${style.help__item} ${style.help__item_three}`}>
                    <span className={style.help__item_text3}>{t('Each of you receive $5 to your account balance')}</span>
                </div>
            </div>
        </div>

    );
};
