import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import style from './SearchInfo.module.scss';
import {Button, DropdownBox, LinkComponent} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import {getSmartOffers, MainRoutesEnum, RootState} from 'core';
import giftMioLogo from '../../../../assets/images/header/header-logo.svg';
import {Link} from 'react-router-dom';
import {ReactComponent as SearchIcon} from 'assets/images/icons/search.svg';
import {Input} from '@design-system/ui-kit';
import {useMedia} from 'hooks';

export const SearchInfo: FC = () => {
    const dispatch = useDispatch();
    const isMobile = useMedia();
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState<string | undefined>('');
    const [showSearchList, setShowSearchList] = useState<boolean>(false);
    const {smartOffers} = useSelector((state: RootState) => state.commonReducer);
    const {uriParams} = useSelector((state: RootState) => state.landingReducer);


    useEffect(() => {
        if (!smartOffers?.length) {
            return;
        }
        setShowSearchList(true);
    }, [smartOffers]);

    useEffect(() => {
        const controller = new AbortController();
        if (!uriParams?.site_url) {
            return;
        }
        setSearchValue(uriParams.site_url);
        dispatch(getSmartOffers({queryParams: {s: uriParams.site_url}, controller}));
    }, [uriParams, dispatch]);
    const handleSearchChange = (event) => {
        event.preventDefault();
        const controller = new AbortController();
        dispatch(getSmartOffers({queryParams: {s: searchValue}, controller}));
    };
    const handleLinkClick = () => {
    }
    return (
        <div className={style.main_wrapper}>
            <div className={style.main}>
                <Link to={'/'} className={style.main_logo}>
                    <img src={giftMioLogo} width='270px' alt="GiftMio"/>
                </Link>
                <p className={'textHeader_07'}>{t('Check for cashback to save with the brands you love')}</p>
                <form className={style.search_form} onSubmit={handleSearchChange}>
                    <div className={style.search_input}>
                        <Input
                            className={style.input}
                            placeholder={t('Search brands or insert your links')}
                            startIcon={SearchIcon}
                            onChange={setSearchValue}
                            value={searchValue}
                            type='text'/>
                        {showSearchList
                            && <DropdownBox>
                                {!!smartOffers?.length ?
                                    <>{smartOffers.map(offer => (
                                        <div className={style.search_item} key={offer.uuid}>
                                            <LinkComponent
                                                onClick={handleLinkClick}
                                                target={'_blank'}
                                                to={offer.mega ? `${MainRoutesEnum.MEGA}/${offer.uuid}/` : `${MainRoutesEnum.BRAND}/${offer.slug}/`}
                                                textContent={offer.name}/>
                                        </div>
                                    ))}</>
                                    : <div className={style.search_item}>{t(`Unfortunately, we couldn't find the store or we are unable to offer you cashback for purchases there. Please check the brand spelling or try inserting the store's link.`)}</div>
                                }
                          </DropdownBox>
                        }
                    </div>
                    {!isMobile && <Button disabled={!searchValue} onClick={() => {}} type="submit" textContent={t('Search brands')} isFullHeight={!isMobile}/>}
                </form>
                {!showSearchList &&  <p className={'textBodyMedium_02'}>{t('insert brand name or link to internet store')}</p>}
            </div>
        </div>
    );
};
