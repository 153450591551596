import {sendGaEvent} from './sendGaEvent';
import {sendPostHogEvent} from './sendPostHogEvent';
import {store} from 'core';
import {
    AnalyticEventOptionsEnum,
    AppConfigInterface,
    GAEventOptionsInterface,
    PostHogEventOptionsInterface
} from 'typings';

interface AnalyticEvents {
    gaOptions: GAEventOptionsInterface;
    postHogOptions: PostHogEventOptionsInterface
}
export const sendAnalyticEvents = (eventName: string, eventOptions: AnalyticEvents) => {
    const state = store.getState();
    const appConfig: AppConfigInterface = state.commonReducer.appConfig;
    const userId = appConfig?.user?.user_id;
    const eventOption1 = eventOptions.gaOptions?.event_option1;

    eventOptions.gaOptions.event_option1 = userId && eventOption1 === undefined
        ? `${AnalyticEventOptionsEnum.ACCOUNT};${AnalyticEventOptionsEnum.WEBSITE}`
        : eventOption1
            ? `${AnalyticEventOptionsEnum.ACCOUNT};${eventOption1}`
            : AnalyticEventOptionsEnum.WEBSITE;

    sendGaEvent(eventName, eventOptions.gaOptions);
    sendPostHogEvent(eventName, eventOptions.postHogOptions, userId);
}
