import React, {FC} from 'react';
import withdrawalAmount from './WithdrawalAmount.module.scss';
import {useTranslation} from 'react-i18next';
import {Button} from 'components';
import {InfoRoutesPathEnum, MainRoutesEnum} from 'core';
import {Input} from '@design-system/ui-kit';
import {WithdrawalAmountInterface} from './WithdrawalAmount.interface';
import {ConfirmEmail} from '../confirmEmail/ConfirmEmail';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, PostHogOptionsEnum} from 'typings';
import {sendAnalyticEvents} from 'utils';
import {Link, useNavigate} from 'react-router-dom';



export const WithdrawalAmount: FC<WithdrawalAmountInterface> = (props) => {
    const {amount = '', minAmount = '', currency = '', isFirstCashback = false, isNothingToWithdraw = false, emailConfirmed = false} = props;
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleGoShoppingClick = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.WITHDRAWALS,
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.GO_SHOPPING,
            button_location: AnalyticEventOptionsEnum.WITHDRAWALS
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_GO_SHOPPING, {gaOptions, postHogOptions});
        navigate(`${MainRoutesEnum.HOME}/`);
    }


    return (
        <div className={withdrawalAmount.amount}>
            {(!isNothingToWithdraw && emailConfirmed) && <h2 className={`textHeader_08 ${withdrawalAmount.amount__title}`}>{t('Specify amount')}</h2>}
            {(!isNothingToWithdraw && emailConfirmed) && <div className={withdrawalAmount.amount__input}>
                <Input placeholder={t('Amount')} value={amount} disabled={true}/>
                <span className={'textBody_03'}>{t(`Minimum withdrawal amount is {{minWithdrawal}} {{currentCurrency}}.`, {minWithdrawal: minAmount, currentCurrency: currency})}&nbsp;<Link className={withdrawalAmount.amount__link} to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.FAQS}`}>{t('Learn more')}</Link></span>
            </div>}
            {isFirstCashback && <div className={withdrawalAmount.amount__first_cashback}>
                <span className={`textHeader_09 ${withdrawalAmount.amount__first_cashback_title}`}>{t('First cashback withdrawals')}</span>
                <span className={`textBodyMedium_03`}>
                    {t('For the first time, you can withdraw up to $5 of confirmed cashback without a limit on the minimum withdrawal amount.')}&nbsp;
                    <a className={withdrawalAmount.amount__link} href={'/'}>{t('Learn more about limits')}</a>
                </span>
            </div>}
            {(isNothingToWithdraw && emailConfirmed) && <div className={withdrawalAmount.amount__not_enough}>
                <div className={withdrawalAmount.amount__not_enough_text}>
                    <span className={`textHeader_09 ${withdrawalAmount.amount__not_enough_text_title}`}>{t('Not enough funds to withdraw')}</span>
                    <span className={`textBodyMedium_03`}>{t('Save up more cashback to withdraw money.')}</span>
                </div>
                <Button kind={'ellipse'} theme={'stroke'} textContent={t('Go shopping')} isFullWidth={true} onClick={handleGoShoppingClick}></Button>
            </div>}
            {!emailConfirmed && <ConfirmEmail/>}
        </div>
    );
};
