import {ReactComponent as EarningsIcon} from 'assets/images/menu/earnings.svg';
import {ReactComponent as ProfileIcon} from 'assets/images/menu/profile.svg';
import {ReactComponent as WithdrawalsIcon} from 'assets/images/menu/withdrawals.svg';
import {ReactComponent as BillingInfoIcon} from 'assets/images/menu/billing-info.svg';
import {ReactComponent as PromoCodesIcon} from 'assets/images/menu/promocodes.svg';
import {ReactComponent as ReferralIcon} from 'assets/images/menu/referral.svg';
import {ReactComponent as MyDetailsIcon} from 'assets/images/menu/my-details.svg';


export const menuIcons = {
    earnings: EarningsIcon,
    profile: ProfileIcon,
    withdrawals: WithdrawalsIcon,
    billingInfo: BillingInfoIcon,
    promoCodes: PromoCodesIcon,
    referral: ReferralIcon,
    myDetails: MyDetailsIcon,
}
