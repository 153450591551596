import {isLandingPage} from 'utils';
import {ServicesUrls} from 'consts';
declare const window: any;

const GA4_TAG = 'gtag';

export const initGaScript = () => {
    if (!['dev'].includes(`${process.env.REACT_APP_ENVIRONMENT_NAME}`) && !isLandingPage(false, true)) {
        const gtagId = process.env.REACT_APP_GA_MEASUREMENT_ID;
        const script_src = `${ServicesUrls.googleTagManagerUrl}?id=${gtagId}`;
        const gtagScriptMain = document.createElement('script');
        gtagScriptMain.async = true;
        gtagScriptMain.src = script_src;
        document.getElementsByTagName('head')[0].appendChild(gtagScriptMain);

        window.dataLayer = window.dataLayer || [];

        window[GA4_TAG] = window[GA4_TAG] || function () {
            window.dataLayer.push(arguments)
        }
        window[GA4_TAG]('js', new Date());

        window[GA4_TAG]('config', gtagId, {
            'send_page_view': false,
        });

        window[GA4_TAG]('event', 'initialize_cookie', {
            'send_to': gtagId,
            'non_interaction': true,
            'event_callback': function() {
                console.log('Custom event sent, _ga cookie should be set.');
            }
        });

    }
};
