import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {MainRoutesEnum, CatalogRoutesPathEnum} from './typings';
import {InternalError, PageNotFound, UserBanned} from 'core';
import {
    Account,
    CatalogPage,
    Info,
    HomePage,
    OfferPage,
    OfferMega,
    ExtensionLanding,
    DirectLandingV1,
    DirectLandingV2,
    FakeLanding,
    WelcomeLanding,
    TakeAdsV1,
    TakeAdsV2,
    SearchLanding,
    Extension,
} from 'modules';

export const AppRouter: FC = () => {
    const RoutesConfig = [
        {
            path: `${MainRoutesEnum.HOME}`,
            index: true,
            element: <HomePage/>,
        },
        {
            path: `${MainRoutesEnum.CATALOG}/`,
            children: [
                {
                    path: `${CatalogRoutesPathEnum.CATALOG_OFFER_LIST}`,
                    element: <CatalogPage/>,
                },
                {
                    path: `${CatalogRoutesPathEnum.CATALOG_OFFER}`,
                    element: <CatalogPage/>,
                },
                {
                    path: `*`,
                    index: true,
                    element: <PageNotFound/>,
                },
            ]
        },
        {
            path: `${MainRoutesEnum.BRAND}/:offerId/*`,
            index: true,
            element: <OfferPage/>,
        },
        {
            path: `${MainRoutesEnum.MEGA}/:uuId/`,
            index: true,
            element: <OfferMega/>,
        },
        {
            path: `${MainRoutesEnum.ACCOUNT}/*`,
            index: true,
            element: <Account/>,
        },
        {
            path: `${MainRoutesEnum.INFO}/*`,
            index: true,
            element: <Info/>,
        },
        {
            path: `${MainRoutesEnum.DIRECT_LANDING}`,
            index: true,
            element: <DirectLandingV2/>,
        },
        {
            path: `${MainRoutesEnum.DIRECT_LINK}`,
            index: true,
            element: <DirectLandingV1/>,
        },
        {
            path: `${MainRoutesEnum.DIRECT_EXTENSION}`,
            index: true,
            element: <ExtensionLanding/>,
        },
        {
            path: `${MainRoutesEnum.WELCOME_LINK}`,
            index: true,
            element: <WelcomeLanding/>,
        },
        {
            path: `${MainRoutesEnum.SEARCH_LANDING}`,
            index: true,
            element: <SearchLanding/>,
        },
        {
            path: `${MainRoutesEnum.TAKE_ADS_LANDING_V1}`,
            index: true,
            element: <TakeAdsV1/>,
        },
        {
            path: `${MainRoutesEnum.EXTENSION}`,
            index: true,
            element: <Extension/>,
        },
        {
            path: `${MainRoutesEnum.TAKE_ADS_LANDING_V2}`,
            index: true,
            element: <TakeAdsV2/>,
        },
        {
            path: `${MainRoutesEnum.FAKE_LINK}`,
            index: true,
            element: <FakeLanding/>,
        },
        {
            path: `${MainRoutesEnum.INTERNAL_ERROR}`,
            index: true,
            element: <InternalError/>,
        },
        {
            path: `${MainRoutesEnum.ACCOUNT_BLOCKED}`,
            index: true,
            element: <UserBanned/>,
        },
        {
            path: `/*`,
            element: <PageNotFound/>,
        },

    ];

    return useRoutes(RoutesConfig);
};
