import {posthog} from 'posthog-js';
import {AnalyticEventNameEnum} from 'typings';
import {getPlatform} from './getPlatform';
import {getTimeStamp} from './getTimeStamp';

export const setPostHogPageView = (dId, userId, eventOptions = {}) => {
    const params = {
        platform: getPlatform(),
        page_name: window.location.pathname,
        user_id: userId || 'undefined',
        page_viewed_at: getTimeStamp()
    };
    posthog?.identify(dId, { user_id: userId });
    posthog?.capture(`$${AnalyticEventNameEnum.PAGEVIEW}`, {...params, ...eventOptions});
};
