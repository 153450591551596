import {RouteObject, Navigate} from 'react-router-dom';
import {Actions, Withdrawal, BillingInfo, PromoCodes, ReferralProgram} from '../pages';
import {PageNotFound} from 'core';
import { AccountRoutesPathEnum} from 'core/Routes/typings/enums/AccountRoutesPath.enum';
import { MainRoutesEnum} from 'core/Routes/typings/enums/MainRoutes.enum';

export const accountRoutes: RouteObject[] = [
    {
        path: `${AccountRoutesPathEnum.ACTIONS}/`,
        children: [
            {
                path: ``,
                element: (<Navigate to={`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.ACTIONS}earnings`} />)
            },
            {
                element: <Actions/>,
                path: `:actionId`,
            }
        ]
    },
    {
        element: <Withdrawal/>,
        path: `${AccountRoutesPathEnum.WITHDRAWAL}/*`,
    },
    {
        element: <BillingInfo/>,
        path: `${AccountRoutesPathEnum.BILLING_INFO}/*`,
    },
    {
        element: <PromoCodes/>,
        path: `${AccountRoutesPathEnum.PROMO}/*`,
    },
    {
        element: <ReferralProgram/>,
        path: `${AccountRoutesPathEnum.REFERRAL_PROGRAM}/*`,
    },

    {element: <PageNotFound/>, path: '/*'},
];
