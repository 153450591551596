import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {ENotificationsThemes} from 'components/Notifications/Notifications.types';
import {FallbackErrorTextConst} from 'consts';
import {notificationListPushItem} from '../NotificationList';
import {
    getAppConfigRequest,
    getAutocompleteOffersRequest,
    getCategoriesRequest,
    getTopOffersRequest,
    getTrendingStoresRequest,
    getCountriesRequest,
    postCurrencyGeoRequest,
    getOfferSearchRequest,
    getFakeUrlsRequest,
    getMegaOfferRequest,
} from './common.api';
import {
    getAppConfig,
    getCategories,
    getTopOffers,
    getTrendingStores,
    getSearchedOffers,
    getGeo,
    setAppConfig,
    setCategories,
    setTopOffers,
    setTrendingStores,
    setSearchedOffers,
    setGeo,
    hideCountryDialog,
    showCountryDialog,
    showLoader,
    hideLoader,
    postGeoCurrency,
    changeRegion,
    setSmartOffers,
    getSmartOffers,
    getFakeUrls,
    setFakeUrls,
    setMegaOffer,
    getMegaOffer,
    setConfigCountry,
} from './common.reducer';
import {
    AppConfigInterface,
    CategoriesInterface,
    FakeUrlsInterface,
    GeoResponseInterface,
    OfferInterface,
    RequestPayloadType,
    ShortOfferInterface,
    SmartOfferInterface
} from 'typings';
import i18n from "i18next";
import {getCookie} from 'utils';


function* getAppConfigWorker(): Generator<StrictEffect, void, any> {
    try {
        const appConfig: AppConfigInterface = yield call(getAppConfigRequest);
        yield put(setAppConfig(appConfig));
        yield put(setConfigCountry(appConfig.country || ''));
        if (appConfig.user) {
            if (appConfig.user.balance) {
                yield put(hideCountryDialog());
            } else {
                yield put(showCountryDialog());
            }
        } else {
            const countryCookie = getCookie('country');
            if (!!countryCookie) {
                yield put(hideCountryDialog());
            } else {
                yield put(showCountryDialog());
            }
        }
    } catch (error: unknown) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getCategoriesWorker(): Generator<StrictEffect, void, any> {
    try {
        const categories: CategoriesInterface[] = yield call(getCategoriesRequest);
        yield put(setCategories(categories));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getTrendingStoresWorker(): Generator<StrictEffect, void, any> {
    try {
        const trendingStores: OfferInterface[] = yield call(getTrendingStoresRequest);
        yield put(setTrendingStores(trendingStores));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getTopOffersWorker(): Generator<StrictEffect, void, any> {
    try {
        const topOffers: OfferInterface[] = yield call(getTopOffersRequest);
        yield put(setTopOffers(topOffers));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getGeoWorker(): Generator<StrictEffect, void, any> {
    try {
        const countries: GeoResponseInterface[] = yield call(getCountriesRequest);
        yield put(setGeo(countries));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getSearchedOffersWorker(payload): Generator<StrictEffect, void, any> {
    try {
        const requestParams: any = {
            queryParams: payload.payload.queryParams,
        };
        const searchedOffers: ShortOfferInterface[] = yield call(getAutocompleteOffersRequest, requestParams);
        yield put(setSearchedOffers(searchedOffers));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getSmartOffersWorker(payload): Generator<StrictEffect, void, any> {
    try {
        const requestParams: any = {
            queryParams: payload.payload.queryParams,
        };
        const smartOffers: SmartOfferInterface[] = yield call(getOfferSearchRequest, requestParams);
        yield put(setSmartOffers(smartOffers));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    }
}

function* getMegaOfferWorker(payload): Generator<StrictEffect, void, any> {
    try {
        yield put(showLoader());
        const uuId: any = payload.payload.uuId;
        const megaOffer: SmartOfferInterface = yield call(getMegaOfferRequest, uuId);
        yield put(setMegaOffer(megaOffer));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideLoader());
    }
}

function* getFakeUrlsWorker(payload): Generator<StrictEffect, void, any> {
    try {
        yield put(showLoader());
        const requestParams: any = {
            queryParams: payload.payload.queryParams,
        };
        const fakeUrls: FakeUrlsInterface = yield call(getFakeUrlsRequest, requestParams);
        yield put(setFakeUrls(fakeUrls));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideLoader());
    }
}

function* createGeoCurrencyWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showLoader());
        yield put(hideCountryDialog());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        yield call(postCurrencyGeoRequest, requestParams);
        yield put(changeRegion(payload.payload.data));
        yield put(getAppConfig());
        yield put(getTrendingStores());
        yield put(getTopOffers());
        yield put(getCategories());
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Region has been successfully added'),
            theme: ENotificationsThemes.success,
        }));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
        yield put(showCountryDialog());
    } finally {
        yield put(hideLoader());
    }
}

export function* commonWatcher() {
    yield takeEvery(getAppConfig.type, getAppConfigWorker);
    yield takeEvery(getCategories.type, getCategoriesWorker);
    yield takeEvery(getTrendingStores.type, getTrendingStoresWorker);
    yield takeEvery(getTopOffers.type, getTopOffersWorker);
    yield takeEvery(getSearchedOffers.type, getSearchedOffersWorker);
    yield takeEvery(getGeo.type, getGeoWorker);
    yield takeEvery(postGeoCurrency.type, createGeoCurrencyWorker);
    yield takeEvery(getSmartOffers.type, getSmartOffersWorker);
    yield takeEvery(getFakeUrls.type, getFakeUrlsWorker);
    yield takeEvery(getMegaOffer.type, getMegaOfferWorker);
}
