import React, {FC} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {TabInterface} from 'typings';
import tabsStyle from './Tabs.module.scss';
import {TabListInterface} from './TabList.interface';


export const TabsComponent: FC<TabListInterface> = ({className, defaultParamValue, link, paramName, tabs, withParams = false, allParam = ''}) => {
    const [searchParams] = useSearchParams();
    const getParams = (paramValue) => {
        const newSearchParams = new URLSearchParams();
        if (paramValue === allParam) {
            newSearchParams.delete(paramName)
        } else {
            newSearchParams.set(paramName, paramValue);
        }
        return newSearchParams.toString();
    };


    return (
        <div className={`${className} ${tabsStyle.tabList}`}>
            {tabs.map((tab: Omit<TabInterface, 'linkValue'>) => (
                <Link to={`${link}${withParams ? '&' : '?'}${getParams(tab.paramValue)}`}
                      key={tab.paramValue}
                      className={`textBodyMedium_02 ${tabsStyle.tabItem} ${(tab.paramValue === (searchParams.get(paramName) || defaultParamValue)) ? tabsStyle.tabItem_active : ''}`}>
                    {tab.linkText}
                </Link>
            ))}
        </div>
    );
};
