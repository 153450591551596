import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';
import {CurrencyRestrictionsInterface, RequestPayloadType} from 'typings';

export const getEarningsListRequest = async (payload: any): Promise<any | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('history/actions/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getWithdrawalsListRequest = async (payload: any): Promise<any | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('history/withdrawals/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getCurrencyRestrictionsRequest = async (): Promise<CurrencyRestrictionsInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('meta/restrictions/'));
    return await sendRequest<any>(sendRequestParams);
};

export const getReferralRequest = async (): Promise<CurrencyRestrictionsInterface> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('referral/'));
    return await sendRequest<any>(sendRequestParams);
};

export const createWithdrawalRequest = async (payload: RequestPayloadType<any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('withdrawal/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const getBillingInfoRequest = async (withdrawalMethod: string): Promise<any> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`billing_info/${withdrawalMethod}/`));
    return await sendRequest<any>(sendRequestParams);
};

export const postBillingInfoPaypalRequest = async (payload: RequestPayloadType<any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute(`billing_info/paypal/`), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const postBillingInfoCardRequest = async (payload: RequestPayloadType<any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('billing_info/card/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const postBillingInfoCardRuRequest = async (payload: RequestPayloadType<any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('billing_info/ru_card/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};

export const getPromoCodesRequest = async (payload: RequestPayloadType<any, undefined, any>): Promise<any | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('promocode/'), payload);
    return await sendRequest<any>(sendRequestParams);
};

export const registerPromoCodeRequest = async (payload: RequestPayloadType<any>): Promise<any> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('promocode/register/'), payload);
    return await sendRequest<any, any>(sendRequestParams);
};
