import { useEffect } from 'react';
import {CountryInterface} from 'typings';
import {getGeo, RootState, setSelectedRegion, setSiteLanguage} from 'core';
import {setGeoBonus} from '../modules';
import {useDispatch, useSelector} from 'react-redux';
import {DEFAULT_LANGUAGE, regionLanguages} from '../consts';
import i18n from 'i18next';

export const useGeo = () => {
    const dispatch = useDispatch();
    const {countries, appCountry} = useSelector((state: RootState) => state.commonReducer);

    useEffect(() => {
        if (appCountry && countries.length) {
            const userRegionObj = countries.find(country => country.value === appCountry);
            dispatch(setSelectedRegion(userRegionObj || {} as CountryInterface));
            dispatch(setGeoBonus(userRegionObj?.bonus || ''));
            const userLang = regionLanguages[userRegionObj?.value || ''] || DEFAULT_LANGUAGE;
            dispatch(setSiteLanguage(userLang));
            i18n.changeLanguage(userLang);
        }
    }, [dispatch, appCountry, countries]);

    useEffect(() => {
        dispatch(getGeo());
    }, [dispatch]);
};
