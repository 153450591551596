
export enum PostHogOptionsEnum {
    PAGEVIEW = 'pageview',
    HOMEPAGE = 'homepage',
    CATALOG = 'catalog',

    SIGN_IN = 'sign in',
    GET_CASHBACK = 'get cashback',
    BUY_WITH_CASHBACK = 'buy with cashback',
    SEND_AGAIN = 'send again',
    GO_SHOPPING = 'go shopping',
    ACTIVATE_PROMO_CODE = 'activate promo code',
    CREATE_REQUEST = 'create request',
    APPLY = 'apply',
    COPY_LINK = 'copy link',
    SHARE = 'share',
}
