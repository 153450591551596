import React, {FC, useEffect, useState} from 'react';
import {useRoutes} from 'react-router-dom';
import {PageTitle} from 'components';
import {useMedia} from 'hooks';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {infoRoutes} from './consts/infoRoutes';
import {InfoMenu} from './components/infoMenu/InfoMenu';


const Info: FC = () => {
    const isMobile = useMedia();
    const {infoTitle} = useSelector((state: RootState) => state.infoReducer);
    const [pageTitle, setPageTitle] = useState<string>('');


    useEffect(() => {
        if (!infoTitle) {
            return;
        }
        setPageTitle(infoTitle);
    }, [infoTitle]);


    return (
        <main className="page page_aside-left page_aside-left_account">
            <PageTitle showBackBtn={isMobile} text={pageTitle}/>
            {!isMobile && <InfoMenu/>}
            {useRoutes(infoRoutes)}
        </main>
    );

};

export {Info};
export default Info;
