import {combineReducers} from '@reduxjs/toolkit';
import {commonReducer} from '../common';
import {notificationListReducer} from '../NotificationList';
import {catalogReducer, offerReducer, accountReducer, infoReducer, landingReducer} from 'modules';


export const rootReducer = combineReducers({
    commonReducer,
    catalogReducer,
    offerReducer,
    accountReducer,
    notificationListReducer,
    infoReducer,
    landingReducer
});
