import React, {FC} from 'react';
import style from './ThreeSteps.module.scss';
import {useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import signUpGiftmioLogo from 'assets/images/landing/sign-up.svg';
import getCashbackGiftmioLogo from 'assets/images/landing/get-cashback.svg';
import paypalGiftmioLogo from 'assets/images/landing/paypal.svg';
import { LayoutContainer } from 'components';


export const ThreeSteps: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const size = isMobile ? '200px' : '300px'
    return (
        <LayoutContainer>
            <div className={style.content}>
                <h2 className={` ${isMobile ? 'textHeader_07' : 'textHeader_04'}`}>
                    {t('How does giftmio cashback work?')}
                </h2>
                <div className={`${isMobile ? 'textHeader_07' : 'textHeader_05'} ${style.content_list}`}>
                    <div className={style.content_item}>
                        <img className={style.content_img} height={size} width={size} src={signUpGiftmioLogo} alt={'sign up giftmio'}/>
                        <h3 className={`${isMobile ? 'textHeader_08' : 'textHeader_07'}`}>1. {t('Sign up in Giftmio')}</h3>
                        <p className={`${isMobile ? 'textBody_02' : 'textBody_01'}`}>{t('You register with Giftmio and follow the links from its catalog to the stores.')}</p>
                    </div>
                    <div className={style.content_item}>
                        <img className={style.content_img} height={size} width={size} src={getCashbackGiftmioLogo} alt={'get cashback up giftmio'}/>
                        <h3 className={`${isMobile ? 'textHeader_08' : 'textHeader_07'}`}>2. {t('Get cashback')}</h3>
                        <p className={`${isMobile ? 'textBody_02' : 'textBody_01'}`}>{t('Giftmio returns you a portion of the amount from each purchase.')}</p>
                    </div>
                    <div className={style.content_item}>
                        <img className={style.content_img} height={size} width={size} src={paypalGiftmioLogo} alt={'withdraw funds at giftmio'}/>
                        <h3 className={`${isMobile ? 'textHeader_08' : 'textHeader_07'}`}>3. {t('Withdraw fund')}</h3>
                        <p className={`${isMobile ? 'textBody_02' : 'textBody_01'}`}>{t('You receive the accumulated money on Paypal.')}</p>
                    </div>
                </div>
            </div>
        </LayoutContainer>
    );
};
