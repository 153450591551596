import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {
    AnalyticEventNameEnum,
    AnalyticEventOptionsEnum,
    BillingInfoInterface,
    PostHogOptionsEnum,
    UserMenuEnum,
    WithdrawalMethodsEnum
} from 'typings';
import {useBreadcrumbs, useDocumentTitle, useMedia, useTransObjects} from 'hooks';
import styles from './BillingInfo.module.scss';
import {clearAccountFormError, getBillingInfo, postBillingInfo, setNeedBillingInfo,} from '../../account.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'core';
import {Button, LoaderWithOverlay} from 'components';
import {sendAnalyticEvents} from 'utils';
import {useTranslation} from 'react-i18next';
import {Input} from '@design-system/ui-kit';
import {BillingFormControlsEnum} from '../../typings';
import {useAccountTitle} from '../../hooks';
import {BillingInfoTabs} from '../../componets';


const BillingInfo: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const dispatch = useDispatch();
    const {
        billingInfo,
        withdrawalMethod,
        isNeedBillingInfo,
        isLoading,
        formErrors} = useSelector((state: RootState) => state.accountReducer);
    const [formState, setFormState] = useState<BillingInfoInterface>({} as BillingInfoInterface);
    const [disableButton, setDisableButton] = useState<boolean>(true);
    const [isFromWithdrawal, setIsFromWithdrawal] = useState<boolean>(false);
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(UserMenuEnum.BILLING_INFO);
    const handleInputChange = (controlName: BillingFormControlsEnum) => {
        dispatch(clearAccountFormError(controlName));
    };

    const handleFormStateChange = useCallback(() => {
        if (!formState || !Object.entries(formState).length) {
            return;
        }

        let isDisabled = false;
        Object.entries(formState).forEach(entry => {
            const [ ,value] = entry;
            if (!value) {
                isDisabled = true;
                return;
            }
        });
        setDisableButton(isDisabled);
    }, [formState]);

    const onInputChange = (controlName: BillingFormControlsEnum) => (newValue: string | undefined) => {
        setFormState({
            ...formState,
            [controlName]: newValue,
        });
        handleInputChange(controlName);
    };

    const onInputBlur = ({target: {name, value}}: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormState({
            ...formState,
            [name]: value.trim(),
        });
    };
    const handleCreateRequest = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.BILLING_INFO,
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.CREATE_REQUEST,
            button_location: AnalyticEventOptionsEnum.BILLING_INFO
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_CREATE_REQUEST, {gaOptions, postHogOptions});
        if (!!formState[BillingFormControlsEnum.RU_CARD_NUMBER]) {
            formState[BillingFormControlsEnum.RU_CARD_NUMBER] = formState[BillingFormControlsEnum.RU_CARD_NUMBER].replace(/\s+/g, '');
        }
        dispatch(postBillingInfo({
            data: formState,
            withdrawalMethod: withdrawalMethod,
            isGoToWithdrawal: isFromWithdrawal
        }));
    };

    useDocumentTitle(documentTitles[UserMenuEnum.BILLING_INFO]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[UserMenuEnum.BILLING_INFO] }],
        [documentTitles[UserMenuEnum.BILLING_INFO]]);
    useBreadcrumbs(breadcrumbs);

    useEffect(() => {
        handleFormStateChange();
    }, [formState, handleFormStateChange]);

    useEffect(() => {
        setFormState(billingInfo);
    }, [billingInfo]);

    useEffect(() => {
        if (!withdrawalMethod) {
            return;
        }
        dispatch(getBillingInfo({withdrawalMethod}));
    }, [dispatch, withdrawalMethod]);

    useEffect(() => {
        if (!isNeedBillingInfo) {
            return;
        }
        setIsFromWithdrawal(true);
        dispatch(setNeedBillingInfo(false));
    }, [dispatch, isNeedBillingInfo]);
    return (
        <section className='page__content'>
            <div className={`${styles.content} page__blank loader__container page__content_container`}>
                <BillingInfoTabs/>
                { withdrawalMethod !== WithdrawalMethodsEnum.RU_CARD && <div>
                    <h2 className={`textHeader_08 ${styles.title}`}>{t('Personal data')}</h2>
                    <div className={styles.wrapper}>
                        {formState[BillingFormControlsEnum.FIRST_NAME] !== undefined &&
                          <Input name={BillingFormControlsEnum.FIRST_NAME}
                                placeholder={t('First Name')}
                                 required
                                value={formState[BillingFormControlsEnum.FIRST_NAME]}
                                onChange={onInputChange(BillingFormControlsEnum.FIRST_NAME)}
                                errorMessage={formErrors?.[BillingFormControlsEnum.FIRST_NAME]?.toString()}
                                onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.LAST_NAME] !== undefined &&
                          <Input name={BillingFormControlsEnum.LAST_NAME}
                                 required
                                 placeholder={t('Last Name')}
                                 value={formState[BillingFormControlsEnum.LAST_NAME]}
                                 onChange={onInputChange(BillingFormControlsEnum.LAST_NAME)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.LAST_NAME]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.CARD_FIRST_NAME] !== undefined &&
                          <Input name={BillingFormControlsEnum.CARD_FIRST_NAME}
                                 placeholder={t('Cardholder Name')}
                                 required
                                 value={formState[BillingFormControlsEnum.CARD_FIRST_NAME]}
                                 onChange={onInputChange(BillingFormControlsEnum.CARD_FIRST_NAME)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.CARD_FIRST_NAME]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.CARD_LAST_NAME] !== undefined &&
                          <Input name={BillingFormControlsEnum.CARD_LAST_NAME}
                                 required
                                 placeholder={t('Cardholder Surname')}
                                 value={formState[BillingFormControlsEnum.CARD_LAST_NAME]}
                                 onChange={onInputChange(BillingFormControlsEnum.CARD_LAST_NAME)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.CARD_LAST_NAME]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                    </div>
                </div> }
                <div>
                    <h2 className={`textHeader_08 ${styles.title}`}>{t('Tax and payment data')}</h2>
                    <div className={styles.wrapper}>
                        {formState[BillingFormControlsEnum.PAYPAL_ACCOUNT] !== undefined &&
                            <Input name={BillingFormControlsEnum.PAYPAL_ACCOUNT}
                                   required
                                   placeholder={t('PayPal account')}
                                   value={formState[BillingFormControlsEnum.PAYPAL_ACCOUNT]}
                                   onChange={onInputChange(BillingFormControlsEnum.PAYPAL_ACCOUNT)}
                                   errorMessage={formErrors?.[BillingFormControlsEnum.PAYPAL_ACCOUNT]?.toString()}
                                   onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.PAYPAL_ZIP_CODE] !== undefined &&
                            <Input name={BillingFormControlsEnum.PAYPAL_ZIP_CODE} required
                                   placeholder={t('Recipient address with zip code')}
                                   value={formState[BillingFormControlsEnum.PAYPAL_ZIP_CODE]}
                                   onChange={onInputChange(BillingFormControlsEnum.PAYPAL_ZIP_CODE)}
                                   errorMessage={formErrors?.[BillingFormControlsEnum.PAYPAL_ZIP_CODE]?.toString()}
                                   onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.CARD_ZIP_CODE] !== undefined &&
                            <Input name={BillingFormControlsEnum.CARD_ZIP_CODE}
                                   required
                                   placeholder={t('Recipient address with zip code')}
                                   value={formState[BillingFormControlsEnum.CARD_ZIP_CODE]}
                                   onChange={onInputChange(BillingFormControlsEnum.CARD_ZIP_CODE)}
                                   errorMessage={formErrors?.[BillingFormControlsEnum.CARD_ZIP_CODE]?.toString()}
                                   onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.CARD_IBAN] !== undefined &&
                          <Input name={BillingFormControlsEnum.CARD_IBAN}
                                 required
                                 placeholder={t('IBAN/account number')}
                                 value={formState[BillingFormControlsEnum.CARD_IBAN]}
                                 onChange={onInputChange(BillingFormControlsEnum.CARD_IBAN)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.CARD_IBAN]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.CARD_SWIFT] !== undefined &&
                          <Input name={BillingFormControlsEnum.CARD_SWIFT}
                                 required
                                 placeholder={t('Bank SWIFT code')}
                                 value={formState[BillingFormControlsEnum.CARD_SWIFT]}
                                 onChange={onInputChange(BillingFormControlsEnum.CARD_SWIFT)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.CARD_SWIFT]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.RU_CARD_HOLDER] !== undefined &&
                          <Input name={BillingFormControlsEnum.RU_CARD_HOLDER}
                                 required
                                 placeholder={t('Placeholder Card Name and Surname')}
                                 value={formState[BillingFormControlsEnum.RU_CARD_HOLDER]}
                                 onChange={onInputChange(BillingFormControlsEnum.RU_CARD_HOLDER)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.RU_CARD_HOLDER]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                        {formState[BillingFormControlsEnum.RU_CARD_NUMBER] !== undefined &&
                          <Input name={BillingFormControlsEnum.RU_CARD_NUMBER}
                                 required
                                 placeholder={t('Card Number')}
                                 value={formState[BillingFormControlsEnum.RU_CARD_NUMBER]}
                                 onChange={onInputChange(BillingFormControlsEnum.RU_CARD_NUMBER)}
                                 errorMessage={formErrors?.[BillingFormControlsEnum.RU_CARD_NUMBER]?.toString()}
                                 onBlur={onInputBlur}/>
                        }
                    </div>
                    {withdrawalMethod === WithdrawalMethodsEnum.RU_CARD
                        && <span className={styles.commission}>{t('Note! The service commission will be 3.5%.')}</span>
                    }
                </div>
                <div className={styles.button_wrapper}>
                    <Button
                        disabled={disableButton || !!Object.keys(formErrors)?.length || isLoading}
                        textContent={isFromWithdrawal ? t('Сreate request') : t('Save info')}
                        isFullWidth={isMobile}
                        onClick={handleCreateRequest}/>
                </div>
                {isLoading && <LoaderWithOverlay/>}
            </div>
        </section>
    );
}

export {BillingInfo};
export default BillingInfo;
