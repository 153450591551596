import React, {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Input} from '@design-system/ui-kit';
import {useBreadcrumbs, useDocumentTitle, useMedia, useTransObjects} from 'hooks';
import style from './ReferralProgram.module.scss';
import {
    resetAccountToInitialState,
    getReferralInfo,
} from '../../account.reducer';
import {notificationListPushItem, RootState} from 'core';
import {AccordionItem, Button, LoaderWithOverlay} from 'components';
import {sendAnalyticEvents} from 'utils';
import {
    ReferralControlsEnum,
    ReferralFormInterface
} from '../../typings';
import {ReferralHelp, ReferralSocial, ReferralStats} from "../../componets";
import {copyToClipboard} from 'utils';
import {ENotificationsThemes} from '../../../../components/Notifications';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, PostHogOptionsEnum, UserMenuEnum} from 'typings';
import {useAccountTitle} from '../../hooks';

const ReferralProgram: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const dispatch = useDispatch();
    const {referralInfo, isLoading} = useSelector((state: RootState) => state.accountReducer);
    const [formState, setFormState] = useState<ReferralFormInterface>({} as ReferralFormInterface);
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(UserMenuEnum.REFERRAL_PROGRAM);
    const handleCopyToClipboard = async() => {
        try {
            await copyToClipboard(formState[ReferralControlsEnum.REFERRAL_LINK]);
            const gaOptions = {
                event_option2: AnalyticEventOptionsEnum.REFERRAL_PROGRAM,
                event_option4: formState[ReferralControlsEnum.REFERRAL_LINK]
            };
            const postHogOptions = {
                button_name: PostHogOptionsEnum.COPY_LINK,
                button_location: AnalyticEventOptionsEnum.REFERRAL_PROGRAM
            };
            sendAnalyticEvents(AnalyticEventNameEnum.CLICK_COPY_LINK, {gaOptions, postHogOptions});

            dispatch(notificationListPushItem({
                id: Date.now().toString(),
                title: t('Referral link copied!'),
                theme: ENotificationsThemes.success,
            }));
        } catch(error) {
            dispatch(notificationListPushItem({
                id: Date.now().toString(),
                title: t(`Referral link didn't copy!`),
                theme: ENotificationsThemes.critical,
            }));
        }
    }

    useDocumentTitle(documentTitles[UserMenuEnum.REFERRAL_PROGRAM]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[UserMenuEnum.REFERRAL_PROGRAM] }],
        [documentTitles[UserMenuEnum.REFERRAL_PROGRAM]]);
    useBreadcrumbs(breadcrumbs);
    useEffect(() => {
        dispatch(getReferralInfo());
        return () => {
            dispatch(resetAccountToInitialState());
        }
    }, [dispatch]);

    useEffect(() => {
        setFormState({[ReferralControlsEnum.REFERRAL_LINK]: referralInfo.referral_link})
    }, [referralInfo]);


    return (
        <section className='page__content'>
            <div className={`${style.content} page__blank loader__container page__content_container`}>
                <ReferralStats referralInfo={referralInfo}/>

                <div className={style.referral}>
                    <h2 className={`${style.referral__title} textHeader_08`}>{t('Refer and earn')}</h2>
                    <p className={`${isMobile ? 'textBody_02' : 'textBody_01'}`}>{t('Refer Giftmio to your friend and each of you will receive a $5 bonus for that. ')}</p>
                    <div className={style.referral_copy}>
                        <div className={style.referral_copy__field}>
                            <Input name={ReferralControlsEnum.REFERRAL_LINK}
                                   value={formState[ReferralControlsEnum.REFERRAL_LINK]}/>
                        </div>
                        <div className={style.referral_copy__btn}>
                            <Button textContent={t('Copy Link')} isFullWidth={isMobile} onClick={handleCopyToClipboard}/>
                        </div>
                        <div className={style.referralSocial}>
                            <ReferralSocial referralUrl={formState[ReferralControlsEnum.REFERRAL_LINK]}/>
                        </div>
                    </div>
                </div>
                <ReferralHelp/>


                <div className={style.referralFaq}><AccordionItem title={t(`Bonus payment conditions`)} contentKey={'bonusPaymentConditions'} withoutBorder={true}/></div>
                {isLoading && <LoaderWithOverlay/>}
            </div>
        </section>
    );
}

export {ReferralProgram};
export default ReferralProgram;
