import React, {FC, Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import userHeaderProfile from './UserHeaderProfile.module.scss';
import {Avatar, Button, MobileOverlayLayout, UserMenu} from 'components';
import {Popover, TooltipPlacements} from '@design-system/ui-kit';
import {UserHeaderProfileInterface} from './UserHeaderProfile.interface';
import {useMedia} from 'hooks';
import {AccountRoutesPathEnum, MainRoutesEnum} from 'core';
import {InternalLinksEnum} from 'typings';
import {ReactComponent as ChromeIcon} from 'assets/images/icons/chrome.svg';


export const UserHeaderProfile: FC<UserHeaderProfileInterface> = (props) => {
    const {user} = props;
    const {t} = useTranslation();
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
    const isMobile = useMedia();

    const handleToggleSignin = () => {
        setShowUserMenu(!showUserMenu);
    };

    return (
        <Fragment>
            <div className={userHeaderProfile.user_profile}>
                {!isMobile &&
                  <Button href={InternalLinksEnum.EXTENSION}
                          IconLeft={ChromeIcon}
                          isIconAbsolute={true}
                          textContent={t('Add to chrome')}
                          kind='ellipse'/>
                }
                {user?.balance?.balance && <div className={userHeaderProfile.user_profile__info}>
                    <span className='textBodyMedium_04'>{t('Earnings')}</span>
                    <a href={`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.WITHDRAWAL}`} className={userHeaderProfile.user_profile__earnings}>
                        {user?.balance?.confirmed} <span className={userHeaderProfile.user_profile__earnings_awaiting}>/ {user?.balance?.awaiting}</span>
                    </a>
                </div>}

                {isMobile &&
                    <button className={userHeaderProfile.user_profile__logo_btn} onClick={handleToggleSignin}>
                         <Avatar type={'logo'}></Avatar>
                    </button>
                }
                <div className={userHeaderProfile.user_profile__desktop_view}>
                    <Popover
                        content={<UserMenu user={user} onCloseClick={handleToggleSignin}/>}
                        open={showUserMenu}
                        width={300}
                        placement={TooltipPlacements.bottomEnd}>
                        <button className={userHeaderProfile.user_profile__logo_btn} onClick={handleToggleSignin}>
                            {<Avatar type={'logo'}></Avatar>}
                        </button>
                    </Popover>
                </div>

            </div>
            {showUserMenu && isMobile && <MobileOverlayLayout onCloseClick={handleToggleSignin}>
              <UserMenu user={user} onCloseClick={handleToggleSignin}/>
            </MobileOverlayLayout> }
        </Fragment>
    );
};
