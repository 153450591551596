import React, {FC, Fragment, useEffect, useState} from 'react';
import menuMobileStyle from './CategoriesMenuMobile.module.scss';
import {useTranslation} from 'react-i18next';
import {ReactComponent as MenuIcon} from 'assets/images/icons/menu.svg';
import {ReactComponent as SearchIcon} from 'assets/images/icons/search.svg';
import {Button, LinkComponent, MobileOverlayLayout} from 'components';
import {categoriesIcons} from 'utils'
import {CategoriesInterface, OfferInterface} from 'typings';
import {useCommonSelector, useTransObjects} from 'hooks';
import {MenuMobileSearch} from '../menuMobileSearch/MenuMobileSearch';


export const CategoriesMenuMobile: FC = () => {
    const {t} = useTranslation();
    const appCategories: CategoriesInterface[] = useCommonSelector<CategoriesInterface[]>('categories');
    const stores: OfferInterface[] = useCommonSelector<OfferInterface[]>('trending_stores');
    const {categoriesTrans} = useTransObjects();

    const [categories, setCategories] = useState<CategoriesInterface[]>([]);
    const [popularStores, setPopularStores] = useState<OfferInterface[]>([]);
    const [showAllCategories, setShowAllCategories] = useState<boolean>(false);
    const [showSearchList, setShowSearchList] = useState<boolean>(false);


    useEffect(() => {
        setCategories(appCategories);
    }, [appCategories]);

    useEffect(() => {
        setPopularStores(stores);
    }, [stores]);

    const handleAllCategories = () => {
        setShowAllCategories(!showAllCategories);
    }

    const handleToggleSearch = () => {
        setShowSearchList(!showSearchList);
    }

    return (
        <Fragment>
            <div className={menuMobileStyle.categoriesMenu}>
                <div className={menuMobileStyle.categoriesMenu__wrapper}>
                <Button onClick={handleAllCategories} textContent={t('All categories')} kind={'ellipse'} isFullWidth={true} theme={'stroke'} IconLeft={MenuIcon}/>
                <Button IconLeft={SearchIcon} isIconWithoutText={true} kind={'round'} size={'s'} onClick={handleToggleSearch}/>
                </div>
            </div>
            {showAllCategories &&
              <MobileOverlayLayout title={t('Categories')} onCloseClick={handleAllCategories}>
              <div className={menuMobileStyle.categoriesMenu__category_list}>
                  {categories.map(category => (
                      <div className={menuMobileStyle.categoriesMenu__category_list_link} key={category.slug}>
                          <LinkComponent withArrow={true} textContent={categoriesTrans[category.slug]} to={`/catalog/${category.slug}`} onClick={handleAllCategories} imageSrc={categoriesIcons[category.slug]}/>
                      </div>
                    ))
                  }
              </div>
            </MobileOverlayLayout>}
            {showSearchList && <MenuMobileSearch popularStores={popularStores} onHandleToggleSearch={handleToggleSearch}/>}
        </Fragment>
    );
};
