import React, {FC} from 'react';
import style from './ReferralStats.module.scss';
import {ReferralInfoInterface} from 'typings';
import {ReferralStat} from './ReferralStat';
import confirmedLogo from 'assets/images/account/dollar-confirmed.svg';
import shoppingLogo from 'assets/images/account/shopping.svg';
import userGroupLogo from 'assets/images/account/user-group.svg';
import {useTranslation} from 'react-i18next';



export const ReferralStats: FC<{ referralInfo: ReferralInfoInterface}> = ({referralInfo}) => {
    const {t} = useTranslation();

    const referralStats = [
        {text: t('Registered referrals'), key: 'registered_count',  image: confirmedLogo},
        {text: t('Fulfilled conditions'), key: 'fulfilled_condition',  image: shoppingLogo},
        {text: t('Bonus payment'), key: 'bonus_payment',  image: userGroupLogo},
    ];

    return (
        <div className={style.content}>
            {referralStats.map((stat) => (
                <ReferralStat key={stat.key} keyValue={stat.key} text={stat.text} value={referralInfo[stat.key]} image={stat.image}/>
            ))}
        </div>
    );
};
