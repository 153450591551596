import {createSendRequestParams, getApiRoute, sendRequest} from 'utils';

const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const getAvailableIdentityProvidersRequest = async (payload: any): Promise<any | undefined> => {
    const sendRequestParams = createSendRequestParams<any>( `${PUBLIC_API_URL}realm/available-identity-providers`, payload);
    return await sendRequest<any>(sendRequestParams);
};

export const createUserRequest = async (payload: any): Promise<any | undefined> => {
    payload.method = 'POST';
    const sendRequestParams = createSendRequestParams<any>( `${PUBLIC_API_URL}user`, payload);
    return await sendRequest<any>(sendRequestParams);
};

export const getDirectLinkSearchRequest = async (payload: any): Promise<any | undefined> => {
    const sendRequestParams = createSendRequestParams<any>(getApiRoute('direct-link/search/'), payload);
    return await sendRequest<any>(sendRequestParams);
};
