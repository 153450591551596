import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './InfoGraphic.module.scss';
import firstBlockLogo from 'assets/images/extension/first.png';
import secondBlocLogo from 'assets/images/extension/second.png';
import thirdBlocLogo from 'assets/images/extension/third.png';

export const InfoGraphic: FC = () => {
    const {t} = useTranslation();

    const blocks = [
        {
            image: firstBlockLogo,
            key: 'cashbackNotifications',
            title: t('Cashback notifications'),
            text: t('Giftmio will alert you to cashback offers on store pages.')
        },
        {
            image: secondBlocLogo,
            key: 'shopsWithCashback',
            title: t('Shops with cashback'),
            text: t('A quick search for online stores with cashback.')
        },
        {
            image: thirdBlocLogo,
            key: 'searchHints',
            title: t('Search hints'),
            text: t('See if online stores provide cashback in Google search results.')
        }
    ];

    return (
        <div className={styles.blocks}>
            {blocks.map( block => (
                    <div className={styles.block} key={block.key}>
                        <img alt={block.key} src={block.image} className={styles.image}/>
                        <p className={`${styles.title} textBodyMedium_01`}>{block.title}</p>
                        <p className={`${styles.text} textBodyMedium_01`}>{block.text}</p>
                    </div>
                )
            )}
        </div>
    );
};
