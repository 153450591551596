import React, {FC, Fragment} from 'react';
import offerCardStyle from './OfferCard.module.scss';
import {OfferCardInterface} from './OfferCard.interface';
import {OfferCardBody, OfferCardFooter, OfferCardHeader} from './components';
import {Link} from 'react-router-dom';
import classNames from 'classnames/bind';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum} from 'typings';
import {sendAnalyticEvents} from 'utils';
import { useDispatch } from 'react-redux';
import { setOpenFromCategory } from 'modules';


export const OfferCard: FC<OfferCardInterface> = (props) => {
    const {offer, location, isWelcomeLanding, currerntCategory} = props;
    const dispatch = useDispatch();

    const offerClassName = classNames(
        offerCardStyle.offerCard,
        {[offerCardStyle.offerCard__special]: offer.is_special},
    );

    const handleLinkClick = () => {
        dispatch(setOpenFromCategory(currerntCategory || null));

        if (!isWelcomeLanding) {
            return;
        }
        const gaOptions = {
            event_option2:  AnalyticEventOptionsEnum.WELCOME_LINK,
            event_option4: offer?.name || ''
        };
        const postHogOptions = {
            button_name: AnalyticEventNameEnum.CLICK_PROGRAM,
            program_name: offer?.name || '',
            button_location: AnalyticEventOptionsEnum.WELCOME_LINK
        };
        if (!!location || isWelcomeLanding) {
            sendAnalyticEvents(AnalyticEventNameEnum.CLICK_PROGRAM, {gaOptions, postHogOptions});
        }
    };

    return (
        <Fragment>
            <Link to={`/offer/${offer?.slug}`} onClick={handleLinkClick} className={offerClassName}>
                <OfferCardHeader src={offer.image}/>
                <OfferCardBody name={offer.name} description={offer.short_description} cashback={offer.cashback} cashbackOrigin={offer.cashback_origin}/>
                <OfferCardFooter name={offer.name} end_date={offer.end_date} trafficUrl={offer.traffic_url} slug={offer.slug} location={location} isWelcomeLanding={isWelcomeLanding}/>
            </Link>
        </Fragment>
    );
};
