import { FC } from "react";
import { useTranslation } from "react-i18next";
import errorBanned from "assets/images/errors/error-banned.svg";
import { Button } from "components";
import { Error } from "../Error";

const UserBanned: FC = () => {
  const { t } = useTranslation();

  return (
    <Error
      title={t("The account was blocked ")}
      text={t("Your account has been blocked. If you have any questions, you can write to support.")}
      buttons={[
        <Button key="banned_to_support" href="mailto:support@giftmio.com" textContent={t("Contact support")} />,
        <Button key="banned_to_home" to="/" theme="stroke" textContent={t("Go to main page")} />,
      ]}
      image={<img src={errorBanned} alt={t("Account blocked")} width="330" height="234" />}
    />
  );
};

export { UserBanned };
export default UserBanned;
