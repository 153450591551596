import React, {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {getMegaOffer, RootState} from 'core';
import {
    OfferInterface,
    UserInterface,
} from 'typings';
import {useBreadcrumbs, useCommonSelector, useDocumentTitle, useMedia} from 'hooks';
import {Accordion, LoaderWithOverlay, SearchModal} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import {SsoConst} from 'consts';
import {OfferContent, OfferHeaderMega, OfferSidebar} from '../../components';
import {MenuMobileSearch} from 'components';
import {getSsoLocales, transformOffersUtil} from 'utils';



const OfferMega: FC = () => {
    const dispatch = useDispatch();
    const isMobile = useMedia();
    const { uuId } = useParams();
    const {appConfig} = useSelector((state: RootState) => state.commonReducer);
    const {megaOffer, isLoading} = useSelector((state: RootState) => state.commonReducer);
    const stores: OfferInterface[] = useCommonSelector<OfferInterface[]>('trending_stores');
    const [user, setUser] = useState<UserInterface>();
    const [offerDetails, setOfferDetails] = useState<OfferInterface>({} as OfferInterface);
    const location = useLocation();
    const [programName, setProgramName] = useState<string>('');
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

    const breadcrumbs = useMemo(
        () => [{ title: programName }],
        [programName]
    );

    const handleToggleSearchPopup = () => {
        setShowSearchModal(!showSearchModal);
    };

    const handleCashbackClick = () => {
        const locationHref = !!user
            ? offerDetails?.traffic_url + ''
            : `${SsoConst.login}?${getSsoLocales()}&next=${location.pathname}`;
        const newWindow = window.open(locationHref, !!user ? '_blank' : '_self', 'noopener,noreferrer');
        if (newWindow)  {
            newWindow.opener = null;
        }
    }

    useDocumentTitle(programName);
    useBreadcrumbs(breadcrumbs);

    useEffect(() => {
        if (!appConfig?.lang) {
            return;
        }
        setUser(appConfig.user);
    }, [appConfig]);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(getMegaOffer({uuId: uuId, controller}));

        return () => {
            controller.abort();
        };
    }, [uuId, dispatch]);

    useEffect(() => {
        if (!megaOffer.name) {
            return;
        }
        const offerCashback = transformOffersUtil([megaOffer])[0];
        setOfferDetails({
            ...{
            name: megaOffer.name,
            cashback: megaOffer.action,
            slug: megaOffer.slug || megaOffer.uuid,
            traffic_url: megaOffer.traffic_url,
            image: '',
            short_description: '',
            },
            ...offerCashback});
        setProgramName(`Mega - ${megaOffer?.name}`);
    }, [megaOffer]);

    return (
        <Fragment>
            <main className={'page page_aside-left'}>
                <OfferHeaderMega offer={offerDetails}/>
                {(!isMobile) &&
                  <OfferSidebar popularStores={stores}/>
                }
                <section className="page__content page__blank">
                    <div className="loader__container">
                        {/*<div className={style.details}>*/}
                        {/*    <h2 className='textHeader_06'>{t('The link you specified')}</h2>*/}
                        {/*    <div className={style.details_content}>*/}
                        {/*        <div className={style.details_input}>*/}
                        {/*            <Input value={offerDetails?.name} disabled={true}/>*/}
                        {/*        </div>*/}
                        {/*        <Button onClick={handleToggleSearchPopup} textContent={t('Try new link')} theme={'stroke'}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <OfferContent offer={offerDetails} handleCashbackClick={handleCashbackClick} isMega={true}/>
                        {isLoading && <LoaderWithOverlay/>}
                    </div>
                    <Accordion/>
                </section>
            </main>
            {!isMobile && showSearchModal && <SearchModal showModal={showSearchModal} setShowModal={setShowSearchModal} closeModal={handleToggleSearchPopup}></SearchModal>}
            {isMobile && showSearchModal && <MenuMobileSearch popularStores={stores} onHandleToggleSearch={handleToggleSearchPopup}/>}

        </Fragment>

    );
}

export {OfferMega};
export default OfferMega;
