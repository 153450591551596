import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {transformOffersUtil} from 'utils';
import {CategoriesInterface, OfferInterface} from 'typings';
import {OfferStoreInterface} from './typings/interfaces/OfferStore.interface';


const defaultOfferState: OfferStoreInterface = {
    offerDetails: {} as OfferInterface,
    isError: false,
    isLoading: false,
    openFromCategory: null,
};

export const offerSlice = createSlice({
    name: 'offer',
    initialState: defaultOfferState,
    reducers: {
        hideOfferError: state => ({...state, isError: false}),
        hideOfferLoader: state => ({...state, isLoading: false}),
        showOfferError: state => ({...state, isError: true}),
        showOfferLoader: state => ({...state, isLoading: true}),
        setOfferDetails: (state, action: PayloadAction<OfferInterface>) => {
            const offerDetails = transformOffersUtil([action.payload])[0] as OfferInterface;
            return {...state, offerDetails};
        },
        setOpenFromCategory: (state, action: PayloadAction<CategoriesInterface | null>) => ({...state, openFromCategory: action.payload}),

        getOfferDetails(state, action: PayloadAction<{ offerId: string; controller: AbortController; }>) {},
    },
});

export const {
    getOfferDetails,
    hideOfferLoader,
    setOfferDetails,
    showOfferLoader,
    hideOfferError,
    setOpenFromCategory,
    showOfferError,
} = offerSlice.actions;

export const offerReducer = offerSlice.reducer;

