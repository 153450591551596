import React, {FC} from 'react';
import style from './OfferContent.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from 'components';
import {useMedia} from 'hooks';
import {OfferContentInterface} from './OfferContent.interface';

export const OfferContent: FC<OfferContentInterface> = (props) => {
    const {offer, handleCashbackClick, isMega = false} = props;
    const {cashback = '10%', cashback_origin} = offer;
    const {t} = useTranslation();
    const isMobile = useMedia();

    return (
        <div className={style.details}>
            {!isMega && <div className={`${style.details__description} textBody_01`}  dangerouslySetInnerHTML={{__html: offer?.description as string}}></div>}
            <div className={style.details__cashback_wrap}>
                <div className={style.details__cashback}>
                    <span className={`${isMobile ? 'textHeader_07' : 'textHeader_05'}`}>
                        {!cashback_origin ? <Trans i18nKey='offerDetailsFooterUpTo' cashback={cashback}>
                                Up to {{cashback}} Cashback
                            </Trans>
                            : <Trans i18nKey='offerDetailsFooterUpToWithOrigin' cashback={cashback} cashback_origin={cashback_origin}>
                                Up to <span className={style.details__with_origin}>{{cashback_origin}}</span> {{cashback}} Cashback
                            </Trans>
                        }
                    </span>
                    <Button isFullWidth={isMobile} onClick={() => {handleCashbackClick(true)}} kind={'ellipse'} textContent={t('Get cashback')}/>
                </div>
                {isMega && <p>{t(`Cashback rates may vary depending on the product category.`)}</p>}
                {/*{isMega && <div className={style.details__info}>*/}
                {/*  <p className={`textHeader_09 ${style.details__info_title}`}>{t('Please note')}:</p>*/}
                {/*  <p className={`textBodyMedium_03 ${style.details__info_text}`}>{t('It is important, you need to go to the link, then we will count you cashback.')}</p>*/}
                {/*</div>}*/}
            </div>
        </div>
    );
};
