import React, {FC} from 'react';
import style from './ReferralStats.module.scss';
import {ReferralStatsInterface} from './ReferralStat.interface';
import cn from 'classnames/bind';
import {useSelector} from "react-redux";
import {RootState} from "../../../../core";
import buttonStyle from "../../../../components/Button/Button.module.scss";



export const ReferralStat: FC<ReferralStatsInterface> = (props) => {
    const {image, text, value, keyValue} = props;
    const imageSize = '40px';
    const { siteLang } = useSelector((state: RootState) => state.commonReducer);

    const itemClassName = cn(
        style.item,
        [style[`item_${keyValue}`]],
        {
            [style.item_ru]: siteLang === 'ru',
        },
    );

    return (
        <div className={itemClassName}>
            <img src={image} alt={text} height={imageSize} width={imageSize}/>
            <span className={`textBodyMedium_02 ${style.item_text}`}>{text}</span>
            <span className={`textHeader_06 ${style.item_value}`}>{value}</span>
        </div>
    );
};
