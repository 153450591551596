import React, {FC, Fragment} from 'react';
import style from './HeaderLight.module.scss';
import {Link} from 'react-router-dom';
import giftMioLogo from 'assets/images/header/header-logo.svg';
import {LayoutContainer} from '../LayoutContainer/LayoutContainer';
import {HeaderLanguage} from './components/headerLanguage/HeaderLanguage';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {LandingTypes} from '../../modules/Landing/typings';
import {useMedia} from 'hooks';
import {useTranslation} from 'react-i18next';
import {takeAdsName} from '../../modules/Landing/consts';


export const HeaderLight: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const {landingType} = useSelector((state: RootState) => state.landingReducer);

    return (
        <Fragment>
            {landingType === LandingTypes.SEARCH
                ? <header className={`${style.header} ${style.header_gray}`}>
                    <HeaderLanguage/>
                </header>
                : <LayoutContainer>
                    <header className={style.header}>
                        <Link to={'/'} className={style.header_logo}>
                          <img src={giftMioLogo} height='27px' alt="GiftMio"/>
                        </Link>
                        {(!isMobile && (
                            landingType === LandingTypes.EXTENSION ||
                            landingType === LandingTypes.DIRECTV1 ||
                            landingType === LandingTypes.DIRECT_EXTENSION ||
                            landingType.includes(takeAdsName))) &&
                          <p className={`textHeader_08`}>{t('Time to make money by shopping')}</p>}
                        {(landingType !== LandingTypes.WELCOME && landingType !== LandingTypes.DIRECTV2)
                            && <HeaderLanguage/>}
                    </header>
                </LayoutContainer>
            }
        </Fragment>
)
};
