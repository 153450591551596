import React, {FC} from 'react';
import actionCardHeaderStyle from './ActionCardHeader.module.scss';
import {ActionCardHeaderInterface} from './ActionCardHeader.interface';
import {ActionCardStatus} from '../../../actionCardStatus/ActionCardStatus';
import actionsTableStyle from '../../../actionsTable/ActionsTable.module.scss';



export const ActionCardHeader: FC<ActionCardHeaderInterface> = (props) => {
    const {amount, status} = props;
    const amountTextStyle = (status) => {
        switch (status) {
            case 'approved':
                return actionsTableStyle.amount_text__approved;
            case 'declined':
                return actionsTableStyle.amount_text__declined;
            default:
                return actionsTableStyle.amount_text;
        }
    }
    return (
        <div className={actionCardHeaderStyle.action_card_header}>
            <ActionCardStatus status={status}/>
            <span className={`textHeader_06 ${amountTextStyle(status)}`}>{amount}</span>
        </div>
    );
};
