import React, {FC} from 'react';
import style from './FooterLight.module.scss';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {InfoRoutesPathEnum, MainRoutesEnum, RootState} from '../../core';
import {AnalyticEventNameEnum} from 'typings';
import {getGaABTestLanding, getLandingLocation, sendAnalyticEvents} from 'utils';
import {useSelector} from 'react-redux';


export const FooterLight: FC = () => {
    const {t} = useTranslation();
    const {landingType, uriParams} = useSelector((state: RootState) => state.landingReducer);

    const handleLinkClick = (eventName: string) => {
        const gaOptions = {
            event_option1: 'footer',
            event_option2: getLandingLocation(landingType),
            program_id: uriParams?.domain || 'undefined',
            sendbox_id: uriParams?.admitad_uid || 'undefined',
            website_id: uriParams?.wid || 'undefined',
            ab_test: getGaABTestLanding(landingType, uriParams?.ab)
        };
        const postHogOptions = {
            button_name: eventName,
            button_location: getLandingLocation(landingType),
            store_name: uriParams?.domain,
            referral_wid: uriParams?.wid
        };
        sendAnalyticEvents(eventName, {gaOptions, postHogOptions});
    }

    return (
        <footer className={style.footer}>
            <Link to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.FAQS}`} onClick={()=>{handleLinkClick(AnalyticEventNameEnum.CLICK_HELPS)}} className={`${style.link} textBody_02`}>{t('Helps')}</Link>
            <Link to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.PRIVACY}`} onClick={()=>{handleLinkClick(AnalyticEventNameEnum.CLICK_PRIVACY)}} className={`${style.link} textBody_02`}>{t('Privacy')}</Link>
            <Link to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.TERMS}`} onClick={()=>{handleLinkClick(AnalyticEventNameEnum.CLICK_TERMS)}} className={`${style.link} textBody_02`}>{t('Terms')}</Link>
        </footer>
    );
};
