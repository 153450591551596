import React, {FC, Fragment, useState} from 'react';
import style from './CountrySelector.module.scss';
import {Button, CountryRegistrationModal} from 'components';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {ReactComponent as ArrowRightIcon} from 'assets/images/icons/arrow-right.svg';
import {FlagIcons} from 'consts';
import {i18n} from 'i18n';
import {useGeo} from 'hooks';

export const CountrySelector: FC = () => {
    const [showCountryModal, setShowCountryModal] = useState<boolean>(false);
    const {selectedRegion} = useSelector((state: RootState) => state.commonReducer);
    const chooseCountryTranslate = i18n.t('Choose country');

    useGeo();

    return (
        <Fragment>
            <Button textContent={selectedRegion?.text ? selectedRegion?.text : chooseCountryTranslate}
                    isFullWidth={true}
                    textAlign='left'
                    size='xxl'
                    img={selectedRegion?.value && <img alt={'Location icon'} className={style.img_icon} src={FlagIcons[selectedRegion.value as string]}/>}
                    IconRight={ArrowRightIcon}
                    theme={'grey'}
                    type='button'
                    onClick={()=> setShowCountryModal(true)}/>
            {showCountryModal && <CountryRegistrationModal currentGeo={selectedRegion?.value as string} setShowModal={setShowCountryModal} showModal={showCountryModal}/>}
        </Fragment>
    );
};
