import React, {FC} from 'react';
import offerCardFooterStyle from './OfferCardFooter.module.scss';
import {OfferCardFooterInterface} from './OfferCardFooter.interface';
import {useTranslation} from 'react-i18next';
import {Button} from 'components';
import {ReactComponent as ClockIcon}  from 'assets/images/icons/clock.svg';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, PostHogOptionsEnum} from 'typings';
import {getSsoLocales, openNewLocation, sendAnalyticEvents} from 'utils';
import {SsoConst} from 'consts';
import {setShowUnloadAlert} from 'modules';
import {useDispatch} from 'react-redux';

export const OfferCardFooter: FC<OfferCardFooterInterface> = (props) => {
    const dispatch = useDispatch();
    const {name, end_date, trafficUrl = '', slug = '', location, isWelcomeLanding = false} = props;
    const {t} = useTranslation();

    const handleButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setShowUnloadAlert(false));

        const gaOptions = {
            event_option2: isWelcomeLanding ? AnalyticEventOptionsEnum.WELCOME_LINK
                : location === PostHogOptionsEnum.CATALOG ? location : AnalyticEventOptionsEnum.TOP_OFFERS,
            event_option4: isWelcomeLanding ? name : 'undefined'
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.GET_CASHBACK,
            program_name: name,
            button_location: isWelcomeLanding ? AnalyticEventOptionsEnum.WELCOME_LINK : location || ''
        };
        if (!!location || isWelcomeLanding) {
            sendAnalyticEvents(AnalyticEventNameEnum.CLICK_GET_CASHBACK, {gaOptions, postHogOptions});
        }
        setTimeout(() => {
            const locationHref = trafficUrl || `${SsoConst.login}?${getSsoLocales()}&next=/offer/${slug}`;
            openNewLocation(locationHref, !!trafficUrl);
        });
    }
    return (
        <div className={offerCardFooterStyle.offer_card_footer}>
            {!!end_date && <div className={offerCardFooterStyle.offer_card_footer__period}>
                <ClockIcon/>
                <span className={`textBody_04`}>{t('Ends {{endDate}}', {endDate: end_date})}</span>
            </div>}
            <Button className={offerCardFooterStyle.offer_card_footer__btn} theme={'stroke'} onClick={handleButtonClick} kind={'ellipse'} textContent={t(`Go to the store`)}/>
        </div>
    );
};
