import { useEffect, useRef } from 'react';

export const usePortal = (id: string) => {
  const rootElemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const parentElem = document.getElementById(id);

    if (!parentElem || !rootElemRef.current) {
      return;
    }

    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current?.remove();
    };
  }, [id]);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}
