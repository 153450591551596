import React, {FC, useEffect, useRef, useState} from 'react';
import {Button, DropdownBox, LinkComponent, Modal} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {SearchModalInterface} from './SearchModal.interface';
import style from './SearchModal.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {useMedia, useOutsideClick} from 'hooks';
import {Trans, useTranslation} from "react-i18next";
import {getSmartOffers, MainRoutesEnum, RootState} from 'core';
import {Input} from '@design-system/ui-kit';
import {ReactComponent as SearchIcon} from 'assets/images/icons/search.svg';

export const SearchModal: FC<SearchModalInterface> = ({setShowModal, showModal, closeModal}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isMobile = useMedia();
    const {smartOffers} = useSelector((state: RootState) => state.commonReducer);
    const [showSearchList, setShowSearchList] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | undefined>('');
    const ref = useRef<HTMLDivElement | null>(null);

    useOutsideClick(ref, () => {
        setShowSearchList(false);
    });
    const handleSmartSearch = (event) => {
        event.preventDefault();
        const controller = new AbortController();
        dispatch(getSmartOffers({queryParams: {s: searchValue}, controller}));
    };

    const handleCloseClick = () => {
        closeModal();
    };

    const handleClickLink = () => {
        setShowSearchList(false);
        setSearchValue(undefined);
        closeModal();
    }

    useEffect(() => {
        // if (!smartOffers.length) {
        //     return;
        // }
        setShowSearchList(true);
    }, [smartOffers]);

    useEffect(() => {
        if (!searchValue) {
            setShowSearchList(false);
        }
    }, [searchValue]);

    return (
        <Modal active={showModal}
               setActive={setShowModal}
               closable={true}
               onClose={handleCloseClick}
               size={'m'}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <div className={style.dialog}>
                <h1 className={`textHeader_06`}>
                    <Trans i18nKey='search_text'>
                        Search by brand name or <span className='brand_text'>by store URL address</span>
                    </Trans>
                </h1>
                <p className={`textBody_04`}>{t(`Just start typing the name of your favorite store or copy the link to it, and we will let you know if you can get cashback for purchases in it.`)}</p>
                <div ref={ref} className={style.search_wrap}>
                    <form className={style.search} onSubmit={handleSmartSearch}>
                        <div className={style.search_input}>
                            <Input
                                placeholder={t('Search brands or insert your links')}
                                startIcon={SearchIcon}
                                onChange={setSearchValue}
                                value={searchValue}
                                type='text'/>
                            {showSearchList
                                && <DropdownBox>
                                    {!!smartOffers?.length ?
                                        <>{smartOffers.map(offer => (
                                            <div className={style.search_item} key={offer.uuid}>
                                                <LinkComponent to={offer.mega ? `${MainRoutesEnum.MEGA}/${offer.uuid}/` : `${MainRoutesEnum.BRAND}/${offer.slug}/`} textContent={offer.name} onClick={() => {handleClickLink()}}/>
                                            </div>
                                        ))}</>
                                        : <div className={style.search_item}>{t(`Unfortunately, we couldn't find the store or we are unable to offer you cashback for purchases there. Please check the brand spelling or try inserting the store's link.`)}</div>
                                    }
                                </DropdownBox>
                            }
                        </div>
                        <Button disabled={!searchValue} onClick={() => {}} type="submit" textContent={t('Search')} isFullHeight={!isMobile}/>
                    </form>
                 </div>
            </div>
        </Modal>
    );
};
