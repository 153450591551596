import {useTranslation} from 'react-i18next';
import {InfoMenuEnum, UserMenuEnum, WithdrawalMethodInterface, WithdrawalMethodsEnum} from 'typings';

export const useTransObjects = () => {
    const {t} = useTranslation();

    const userMenuTabs = [
        {key: UserMenuEnum.MY_DETAILS, urlPath: 'MY_DETAILS', title: t('My details')},
        {key: UserMenuEnum.EARNINGS, urlPath: 'ACTIONS', title: t('My cashback')},
        {key: UserMenuEnum.WITHDRAWALS, urlPath: 'WITHDRAWAL', title: t('Withdrawals')},
        {key: UserMenuEnum.BILLING_INFO, urlPath: 'BILLING_INFO', title: t('Billing Info')},
        {key: UserMenuEnum.PROMO_CODES, urlPath: 'PROMO', title: t('Promo Codes')},
        {key: UserMenuEnum.REFERRAL_PROGRAM, urlPath: 'REFERRAL_PROGRAM', title: t('Referral program')},
    ];

    const infoMenuTabs = [
        {key: InfoMenuEnum.SUPPORT, urlPath: 'SUPPORT', title: t('Support')},
        {key: InfoMenuEnum.FAQS, urlPath: 'FAQS', title: t('FAQs')},
        {key: InfoMenuEnum.PRIVACY_POLICY, urlPath: 'PRIVACY', title: t('Privacy policy')},
        {key: InfoMenuEnum.TERMS_OF_USE, urlPath: 'TERMS', title: t('Terms of use')},
    ];

    const documentTitles = {
        main: t('Welcome'),
        catalog: t('Catalog'),
        [UserMenuEnum.EARNINGS]: t('My cashback'),
        [UserMenuEnum.WITHDRAWALS]: t('Withdrawals'),
        [UserMenuEnum.BILLING_INFO]: t('Billing Info'),
        [UserMenuEnum.PROMO_CODES]: t('Promo codes'),
        [UserMenuEnum.USER_PROFILE]: t('User Profile'),
        [InfoMenuEnum.CONTACTS]: t('Contacts'),
        [InfoMenuEnum.TERMS_OF_USE]: t('Terms of use'),
        [InfoMenuEnum.SUPPORT]: t('Support'),
        [InfoMenuEnum.PRIVACY_POLICY]: t('Privacy Policy'),
        [InfoMenuEnum.IMPRESSUM]: t('Imprint'),
        [InfoMenuEnum.FAQS]: t('Faqs'),
        [UserMenuEnum.REFERRAL_PROGRAM]: t('Referral program'),
    };

    const tableTexts = {
        noRowsPlaceholder: t('There’s nothing here yet'),
        responseValidationFailedTitle: t('Failed to load'),
        responseValidationFailedDescription: t('Please try to reload the page'),
        elementsCounter: t('of'),
        itemsPerPage: `${t('Results on page')}:`,
        goTo: t('Go to'),
    };

    const withdrawalMethods: WithdrawalMethodInterface[] = [
        {key: WithdrawalMethodsEnum.PAYPAL, title: t('PayPal')},
        {key: WithdrawalMethodsEnum.CARD, title: t('Bank Card')},
        {key: WithdrawalMethodsEnum.GIFTCARD, isDisable: true, title: t('Gift Card')},
    ];

    const getPageTitle = (key: InfoMenuEnum | UserMenuEnum, isInfoMenu: boolean = false): string => {
        const list =  isInfoMenu ? infoMenuTabs : userMenuTabs;
        // @ts-ignore
        return (list.find(item => item.key === key) || {}).title || '';
    };

    const categoriesTrans = {
        beauty: t('Health & Beauty'),
        baby: t('Baby & Toddler'),
        clothes: t('Apparel, Footwear & Accessories'),
        hobbies: t('Hobbies & Entertainment'),
        sports_and_outdoor: t('Sports & Outdoor'),
        furniture: t('Home & Garden'),
        pets: t('Pets'),
        travel: t('Travel'),
        books: t('Books'),
        finance_and_insurance: t('Finance & Insurance'),
        digital: t('Digital services'),
        adult: t('Adult'),
        food: t('Food & Food delivery'),
        gifts_and_flowers: t('Gifts & Flowers'),
        cars_and_bikes: t('Cars & Bikes'),
        electronics: t('Electronics'),
    };

    return {
        userMenuTabs,
        infoMenuTabs,
        documentTitles,
        tableTexts,
        withdrawalMethods,
        getPageTitle,
        categoriesTrans,
    }
};
