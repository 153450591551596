import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from './store.reducers';
import {rootWatcher} from './store.sagas';


const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware({serializableCheck: false, thunk: false}), sagaMiddleware],
});

sagaMiddleware.run(rootWatcher);
