import React, {FC} from 'react';
import {Link, NavLink} from 'react-router-dom';
import classNames from 'classnames/bind';
import {ButtonInterface} from './Button.interface';
import buttonStyle from './Button.module.scss';


export const Button: FC<ButtonInterface> = (props) => {
    const {
        className,
        counter,
        eventCategory,
        eventLabel,
        eventName,
        href,
        IconLeft,
        IconRight,
        isIconAbsolute = false,
        isNavLink = false,
        kind = 'square',
        isBtnLoading,
        size = 'xl',
        textContent,
        theme = 'fill',
        to,
        isFullWidth,
        isFullHeight,
        isIconWithoutText = false,
        textAlign = 'center',
        img,
        iconRightTheme = 'regular',
        ...btnProps
    } = props;
    let template;
    const btnClassName = classNames(
        'textButton_02',
        className,
        buttonStyle.btn,
        [buttonStyle[`btn_kind_${kind}`]],
        [buttonStyle[`btn_size_${size}`]],
        [buttonStyle[`btn_text_${textAlign}`]],
        [buttonStyle[`btn_theme_${theme}`]],
        {
            [buttonStyle.btn__fullWidth]: isFullWidth,
            [buttonStyle.btn__fullHeight]: isFullHeight,
            [buttonStyle.btn__absoluteIcon]: isIconAbsolute,
        },
    );

    const iconRightClassName = classNames(
        [buttonStyle[`btn_icon_right_${iconRightTheme}`]]
    );

    const btnContent = <>
        {!!IconLeft && <IconLeft className={!isIconWithoutText
            ? buttonStyle.btn__iconLeft : buttonStyle.btn__icon}/>}
        {!!img && img}
        {textContent || ''}
        {!!IconRight && <IconRight className={iconRightClassName}/>}
    </>;

    const navLinkClassName = (isActive) => classNames(
        buttonStyle.btn,
        className ? buttonStyle[className] : '',
        [buttonStyle[`btn_size_${size}`]],
        [buttonStyle[`btn_kind_${kind}`]],
        {
            [buttonStyle.btn_theme_stroke]: !isActive,
            [buttonStyle.btn_theme_fill]: isActive,
        },
    );

    if (to) {
        template = isNavLink
            ? <NavLink to={to} className={({isActive}) => navLinkClassName(isActive)} {...btnProps} >{btnContent}</NavLink>
            : <Link to={to} className={btnClassName} {...btnProps}>{btnContent}</Link>;
    } else if (href) {
        template = <a href={href} className={btnClassName} {...btnProps}>{btnContent}</a>;
    } else {
        template = <button className={btnClassName} {...btnProps}>{btnContent}</button>;
    }


    return template;
};
