import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {RootState} from 'core';
import {useBreadcrumbs, useCommonSelector, useDocumentTitle, useMedia, useTransObjects} from 'hooks';
import catalogStyle from './CatalogPage.module.scss';
import {
    Accordion,
    BlockContainer,
    CategoryCard,
    Empty,
    FilterSearchSort,
    LoaderWithOverlay,
    OfferCard,
    StoreList
} from 'components';
import {CatalogSidebar, MegaCard} from '../../components';
import {Pagination} from '@design-system/ui-kit';
import {
    IDropdownOption,
    TDropdownOptionValue,
    PaginationEnum,
    OfferInterface,
    CategoriesInterface,
    CatalogFilterEnum,
    PostHogOptionsEnum
} from 'typings';
import {useTranslation} from "react-i18next";
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getCatalogOfferList} from '../../catalog.reducer';
import {setPaginationParams} from 'utils';

const CatalogPage: FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { categoryId } = useParams();
    const isMobile = useMedia();
    const {offerList, isLoading, isError, offersCount} = useSelector((state: RootState) => state.catalogReducer);
    const appCategories: CategoriesInterface[] = useCommonSelector<CategoriesInterface[]>('categories');
    const stores: OfferInterface[] = useCommonSelector<OfferInterface[]>('trending_stores');
    const {tableTexts, documentTitles, categoriesTrans} = useTransObjects();
    const title = documentTitles.catalog;
    const orderingOptions: IDropdownOption[] = [
        {value: 'date', text: t('Date (newest first)')},
        {value: '-date', text: t('Date (oldest first)')},
        {value: 'cashback_size', text: t('Percent (lowest first)')},
        {value: '-cashback_size', text: t('Percent (highest first)')},
    ];
    const [searchParams, setSearchParams] = useSearchParams();
    const [firstInit, setFirstInit] = useState<boolean>(true);
    const currentCategory = appCategories.find(({ slug }) => slug === categoryId);
    const categoryName = categoriesTrans[categoryId || ''] || t('All categories');
    const popularCategories = appCategories.filter(cat => cat.is_popular) || [];

    const breadcrumbs = useMemo(
        () => [{ title: categoryName || title }],
        [categoryName, title]
    );
    const handlePaginationChange = (params: any) => {
        setSearchParams(setPaginationParams(params, searchParams));
    };
    const handleSearchAndOrderingChange = (controlName: string, isFromMobile = false) => (value: string | TDropdownOptionValue | null, isFromMobile?: boolean) => {
        if (firstInit) {
            setFirstInit(false);
            if (!isFromMobile) {
                return;
            }
        }
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(controlName, value as string);
        } else {
            newSearchParams.delete(controlName);
        }
        newSearchParams.set(PaginationEnum.LIMIT, '20');
        newSearchParams.set(PaginationEnum.OFFSET, '0');
        setSearchParams(newSearchParams);
    };

    useDocumentTitle(categoryName || title);
    useBreadcrumbs(breadcrumbs);

    useEffect(() => {
        const controller = new AbortController();
        const newSearchParams = new URLSearchParams(searchParams);
        if (categoryId) {
            newSearchParams.set(CatalogFilterEnum.CATEGORY, categoryId);
        }
        if (!newSearchParams.get(PaginationEnum.OFFSET)) {
            newSearchParams.set(PaginationEnum.LIMIT, '20');
            newSearchParams.set(PaginationEnum.OFFSET, '0');
        }
        window.scrollTo({top: 0});
        dispatch(getCatalogOfferList({queryParams: newSearchParams, controller}));
    }, [categoryId, searchParams, dispatch]);

    return (
        <Fragment>
            <main className={'page page_aside-left'}>
                <h1 className={`${catalogStyle.catalog__title} ${isMobile ? 'textHeader_06' : 'textHeader_03'}`}>
                    {categoryName}
                </h1>
                {!isMobile &&
                  <CatalogSidebar categories={appCategories} popularCategories={popularCategories} popularStores={stores}/>
                }
                <section className="page__content page__blank">
                    <FilterSearchSort isSeparate
                                      sort={{
                                           onChange: handleSearchAndOrderingChange(CatalogFilterEnum.ORDERING),
                                           options: orderingOptions,
                                           placeholder: t('Sort by'),
                                           value: searchParams.get(CatalogFilterEnum.ORDERING) || '',
                                           }}/>
                    <div className="loader__container">
                        {!isLoading && !offerList?.length && !isError && <Empty title={tableTexts.noRowsPlaceholder}/>}
                        {isError && !offerList?.length &&
                          <p>
                            <b>{tableTexts.responseValidationFailedTitle}</b>
                            <br/>
                            {tableTexts.responseValidationFailedDescription}
                          </p>
                        }
                        {!!offersCount && <BlockContainer>
                            <div className={catalogStyle.catalog__offers}>
                                <MegaCard stores={stores}/>
                                {offerList.map(offer => (
                                    <OfferCard
                                        key={Math.random()}
                                        offer={offer}
                                        location={PostHogOptionsEnum.CATALOG}
                                        currerntCategory={currentCategory}/>
                                ))}
                            </div>
                        </BlockContainer>}
                        {isLoading && <LoaderWithOverlay/>}
                    </div>
                    {(Number(offersCount) > 20)
                        && <Pagination itemsCount={offersCount || 0}
                                      itemsPerPage={Number(searchParams.get(PaginationEnum.LIMIT)) || 20}
                                      pageNumber={Number(searchParams.get(PaginationEnum.OFFSET) || 0) / Number(searchParams.get(PaginationEnum.LIMIT) || 20) + 1}
                                      onChange={handlePaginationChange}
                                      hidePerPageDropdown={isMobile}
                                      isMobile={isMobile}
                                      perPageDropdownOptions={[20]}
                                      texts={tableTexts}/>}
                    {isMobile &&
                      <>
                        <BlockContainer title={t('Categories')}>
                          <div className={catalogStyle.catalog__categories}>
                              {popularCategories.map(popularCategory => (
                                  <CategoryCard
                                      key={popularCategory.slug}
                                      slug={popularCategory.slug}
                                      text={categoriesTrans[popularCategory.slug]}/>
                              ))}
                          </div>
                        </BlockContainer>
                        <StoreList stores={stores} title={t('Trending stores')} currerntCategory={currentCategory}/>
                      </>
                    }
                    <Accordion/>
                </section>
            </main>
        </Fragment>
    );
}

export {CatalogPage};
export default CatalogPage;
