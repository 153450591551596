import React, {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getEarningsList, getWithdrawalList, resetAccountToInitialState} from '../../account.reducer';
import {useParams, useSearchParams} from "react-router-dom";
import {PaginationEnum, TabInterface, UserInterface, UserMenuEnum} from 'typings';
import {useBreadcrumbs, useDocumentTitle, useMedia, useTransObjects} from 'hooks';
import {ActionsHeader, ActionsTable, BannerExtension} from '../../componets';
import {Accordion, Empty, LinkTabs, LoaderWithOverlay} from 'components';
import {useTranslation} from "react-i18next";
import {ActionItemInterface, TabStateEnum} from '../../typings';
import {AccountRoutesPathEnum, MainRoutesEnum, RootState} from 'core';
import {Pagination} from '@design-system/ui-kit';
import {setPaginationParams} from 'utils';
import {useAccountTitle} from '../../hooks';



const Actions: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();
    const dispatch = useDispatch();
    const {earningsList, withdrawalsList, withdrawalsCount, isLoading, isError, earningsCount, actionState} = useSelector((state: RootState) => state.accountReducer);
    const {getPageTitle, documentTitles, tableTexts} = useTransObjects();
    const {appConfig} = useSelector((state: RootState) => state.commonReducer);
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState<UserInterface>();
    const [actionList, setActionList] = useState<ActionItemInterface[]>([]);
    const [actionsCount, setActionsCount] = useState<number>();
    const { actionId } = useParams();
    const title = getPageTitle(UserMenuEnum.EARNINGS);

    const tabs: Omit<TabInterface, 'paramValue'>[] = [
        {linkText: t('Earnings'), linkValue: `${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.ACTIONS}${TabStateEnum.EARNINGS}`},
        {linkText: t('Withdrawals'), linkValue: `${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.ACTIONS}${TabStateEnum.WITHDRAWALS}`},
    ];

    const handlePaginationChange = (params: any) => {
        setSearchParams(setPaginationParams(params, searchParams));
    };

    useDocumentTitle(documentTitles[UserMenuEnum.EARNINGS]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[UserMenuEnum.EARNINGS] }], [documentTitles[UserMenuEnum.EARNINGS]]);
    useBreadcrumbs(breadcrumbs);

    useEffect(()  => {
        if (actionId) {
            const controller = new AbortController();
            const newSearchParams = new URLSearchParams(searchParams);
            if (!newSearchParams.get(PaginationEnum.OFFSET)) {
                newSearchParams.set(PaginationEnum.LIMIT, '20');
                newSearchParams.set(PaginationEnum.OFFSET, '0');
            }
            if (actionId === 'earnings') {
                dispatch(getEarningsList({queryParams: newSearchParams, controller}));
            } else {
                dispatch(getWithdrawalList({queryParams: newSearchParams, controller}));
            }
            return () => {
                controller.abort();
            }
        }
        return () => {
            dispatch(resetAccountToInitialState());
        }
    }, [dispatch, searchParams, actionId]);

    useEffect(() => {
        if (!appConfig?.user) {
            return;
        }
        setUser(appConfig.user);
    }, [appConfig]);

    useEffect(() => {
        if (!actionState) {
            return;
        }
        if (actionState === 'earnings') {
            // setActionList(EarningListConst);
            // setActionsCount(EarningListConst.length);
            setActionList(earningsList);
            setActionsCount(earningsCount);
        } else if (actionState === 'withdrawals') {
            // setActionList(WithdrawalListConst);
            // setActionsCount(WithdrawalListConst.length);
            setActionList(withdrawalsList);
            setActionsCount(withdrawalsCount);
        }
    }, [actionState, earningsList, earningsCount, withdrawalsList, withdrawalsCount]);


    return (
        <section className='page__content page__blank'>
            <BannerExtension/>
            <ActionsHeader user={user}/>
            <div className="loader__container page__content_container">
                <h2 className={`${isMobile ? 'textHeader_08' : 'textHeader_06'}`}>{t('History')}</h2>
                <LinkTabs tabs={tabs}/>
                {!isLoading && !actionList?.length && !isError && <Empty title={tableTexts.noRowsPlaceholder}/>}
                {isError && !actionList?.length &&
                  <p>
                    <b>{tableTexts.responseValidationFailedTitle}</b>
                    <br/>{tableTexts.responseValidationFailedDescription}
                  </p>}
                {!!actionsCount && <ActionsTable actionList={actionList}/>}
                {(Number(actionsCount) > 20) && <Pagination itemsCount={actionsCount || 0}
                                                           itemsPerPage={Number(searchParams.get(PaginationEnum.LIMIT)) || 20}
                                                           pageNumber={Number(searchParams.get(PaginationEnum.OFFSET) || 0) / Number(searchParams.get(PaginationEnum.LIMIT) || 20) + 1}
                                                           onChange={handlePaginationChange}
                                                           hidePerPageDropdown={isMobile}
                                                           isMobile={isMobile}
                                                           perPageDropdownOptions={[20]}
                                                           texts={tableTexts}/>}
                {isLoading && <LoaderWithOverlay/>}
            </div>
            <Accordion withoutIndent={true}/>
        </section>
    );
}

export {Actions};
export default Actions;
