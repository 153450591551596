import React, {FC} from 'react';
import {Link, NavLink} from 'react-router-dom';
import classNames from 'classnames/bind';
import linkStyle from './Link.module.scss';
import {LinkInterface} from './Link.interface';


export const LinkComponent: FC<LinkInterface> = (props) => {
    const {className, href, textContent, to, Icon, imageSrc, withArrow = false, isNavLink = false, theme = 'fill', iconSize = 's', iconTheme = 'fill', ...linkProps} = props;
    let template;
    const linkClassName = classNames(
        className,
        linkStyle.link,
        [linkStyle[`link_theme_${theme}`]],
        {
            [linkStyle.link_with_arrow]: withArrow
        }
    );

    const navLinkClassName = (isActive) => classNames(
        className,
        linkStyle.link,
        {
            [linkStyle.link_with_arrow]: withArrow
        },
        {
            [linkStyle[`link_theme_${theme}`]]: !isActive,
            [linkStyle[`link_theme_${theme}_confirm`]]: isActive,
        },
    );

    const iconClassName = classNames(
        linkStyle.link__icon,
        linkStyle.link__icon_left,
        [linkStyle[`link__icon_theme_${iconTheme}`]],
        [linkStyle[`link__icon_size_${iconSize}`]],
    );
    const linkContent = <>
        {!!Icon && <Icon className={iconClassName}/>}
        {!!imageSrc && <img className={linkStyle.link__img} src={imageSrc} alt=''/>}
        {textContent}
    </>;

    if (to) {
        template = isNavLink
            ? <NavLink to={to} className={({isActive}) => navLinkClassName(isActive)} {...linkProps} >{linkContent}</NavLink>
            : <Link to={to} className={linkClassName} {...linkProps}>{linkContent}</Link>;
    } else if (href) {
        template = <a href={href} className={linkClassName} {...linkProps}>{linkContent}</a>;
    } else {
        template = <button className={linkClassName} {...linkProps}>{linkContent}</button>;
    }

    return template;
};
