import React, {FC, useMemo} from 'react';
import {Accordion} from 'components';
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {useAccountTitle} from '../../hooks';
import {InfoMenuEnum} from 'typings';

const Faqs: FC = () => {
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(InfoMenuEnum.FAQS, true);

    useDocumentTitle(documentTitles[InfoMenuEnum.FAQS]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[InfoMenuEnum.FAQS] }], [documentTitles[InfoMenuEnum.FAQS]]);
    useBreadcrumbs(breadcrumbs);
    return (
        <Accordion withoutIndent={true} withExtension={true}/>
    );
}

export {Faqs};
export default Faqs;
