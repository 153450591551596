import {posthog} from 'posthog-js';
import {PostHogEventOptionsInterface} from '../../typings';
import {getPlatform} from './getPlatform';
import {getTimeStamp} from './getTimeStamp';

export const sendPostHogEvent = (eventName: string, eventOptions: PostHogEventOptionsInterface = {} as PostHogEventOptionsInterface, userId: string) => {
    const params = {
        platform: getPlatform(),
        button_clicked_at: getTimeStamp(),
        user_id: userId || 'undefined',
    };
    posthog?.capture(eventName, {...params, ...eventOptions});
};
