export enum LandingTypes {
    DIRECTV2 = 'directV2',
    DIRECTV1 = 'directV1',
    SEARCH = 'search',
    TAKEADSV1 = 'takeAdsV1',
    TAKEADSV2 = 'takeAdsV2',
    WELCOME = 'welcome',
    EXTENSION = 'extension',
    DIRECT_EXTENSION = 'directExtension',
}
