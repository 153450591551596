import cn from 'classnames';
import React, { FC } from 'react';

import styles from './DarkClickableArea.module.scss';
import { IDarkClickableAreaProps } from './DarkClickableArea.types';

/**
 * Подложка, которая появляется под всплывающими элементами (модальные окна, попапы) и перекрывает контент
 */
export const DarkClickableArea: FC<IDarkClickableAreaProps> = (props) => {
  const {onClick, children, fixed = false,
  } = props;

  const darkClickableAreaClassName = cn(styles.darkClickableArea, {
    [styles.darkClickableArea_fixed]: fixed,
  });

  return (
    <div
      className={darkClickableAreaClassName}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
