import React, {FC} from 'react';
import styles from './WithdrawalMethod.module.scss';
import {useTranslation} from 'react-i18next';
import {Button} from 'components';
import {IWithdrawalMethod} from './WithdrawalMethod.interface';
import {AppConfigInterface, LanguagesEnum, WithdrawalMethodsEnum} from 'typings';
import {useCommonSelector, useTransObjects} from 'hooks';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'core';
import {setWithdrawalMethod} from '../../account.reducer';
import cn from 'classnames';
import {isWithdrawalMethodAvailable} from 'utils';



export const WithdrawalMethod: FC<IWithdrawalMethod> = (props) => {
    const dispatch = useDispatch();
    const {isNothingToWithdraw = false, emailConfirmed = false, onWithdrawClick} = props;
    const {t} = useTranslation();
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const isRegionRu = appConfig?.country === LanguagesEnum.RU.toUpperCase();
    const {withdrawalMethod: currentMethod} = useSelector((state: RootState) => state.accountReducer);
    const {withdrawalMethods} = useTransObjects();

    return (
        <div className={styles.method}>
            {(!isNothingToWithdraw && emailConfirmed) &&
              <h2 className={`textHeader_08 ${styles.method__title}`}>{t('Choose the withdrawal method:')}</h2>
            }
            {(!isNothingToWithdraw && emailConfirmed) &&
                 <div className={styles.method__type_wrapper}>
                    {withdrawalMethods.map((method) => (
                        <button
                            key={method?.key}
                            onClick={() => isWithdrawalMethodAvailable(method)
                                && dispatch(setWithdrawalMethod({method: method.key as WithdrawalMethodsEnum, isRegionRu: isRegionRu}))}
                            className={cn(
                                styles.method__type,
                                {
                                    [styles.method__type_disabled]: !isWithdrawalMethodAvailable(method),
                                    [styles.method__type_active]: currentMethod.includes(method.key),
                                },
                            )}>
                           <span className={`textBodyMedium_01`}>{method.title}</span>
                            {!isWithdrawalMethodAvailable(method) &&
                              <span className={styles.method__type_coming_soon}>
                                  {t('Coming soon...')}
                              </span>
                            }
                        </button>
                     ))}
                 </div>
            }
            <Button
                disabled={isNothingToWithdraw || !emailConfirmed}
                kind={'ellipse'}
                textContent={t('Withdrawals')}
                onClick={onWithdrawClick}
                isFullWidth={true}/>
        </div>
    );
};
