import React, {FC} from 'react';
import offerBodyStyle from './OfferCardBody.module.scss';
import {OfferCardBodyInterface} from './OfferCardBody.interface';
import {Trans} from 'react-i18next';


export const OfferCardBody: FC<OfferCardBodyInterface> = (props) => {
    const {name = '', description = '', cashback = '', cashbackOrigin} = props;

    return (<div className={offerBodyStyle.offer_card_body}>
                <div className={offerBodyStyle.offer_card_body__title}>
                    <span className={'textBodyMedium_02'}>{name}</span>
                    <span className={`textHeader_10 ${offerBodyStyle.offer_card_body__title_cashback}`}>
                        {!cashbackOrigin ? <Trans i18nKey='offerCardUpTo' cashback={cashback}>
                            up to {{cashback}}
                        </Trans>
                        : <Trans i18nKey='offerCardUpToWithOrigin' cashback={cashback} cashbackOrigin={cashbackOrigin}>
                            up to <span className={offerBodyStyle.offer_card_body__with_origin}>{{cashbackOrigin}}</span> {{cashback}}
                        </Trans>}
                    </span>
                </div>
                <p className={offerBodyStyle.offer_card_body__desc}>{description}</p>
            </div>
    );
};
