import cn from 'classnames';
import React, { FC, useMemo } from 'react';

import { NotificationItem } from './components/NotificationItem';
import styles from './Notifications.module.scss';
import { INotificationsProps } from './Notifications.types';

export const Notifications: FC<INotificationsProps> = (props) => {
  const {
    className,
    items,
    maxVisibleCount = 3,
    onItemsChange,
    backgroundColor,
  } = props;

  const notificationsList = useMemo(() => {
    return items.slice(maxVisibleCount * -1);
  }, [items, maxVisibleCount]);

  const handleDeleteItem = (id: string | number) : any => {
    const findNotificationById = items.find((item) => item.id === id);
    if (!findNotificationById) {
      console.error(`The "notification" by id: ${id} is not defined on Notifications!`);
      return;
    }

    findNotificationById.onClose?.(id);

    const newItemsArr = items.filter((item) => item.id !== id);

    onItemsChange(newItemsArr);
  };

  const notificationsClassName = cn(styles.notifications, className);

  return (
    <div
      className={ notificationsClassName }
    >
      {notificationsList.map((item) => (
        <NotificationItem
          {...item}
          key={item.id}
          id={item.id}
          className={styles.item}
          onClose={handleDeleteItem}
          backgroundColor={item.backgroundColor ?? backgroundColor}
        />
      ))}
    </div>
  );
};
