import React, {FC} from 'react';
import classNames from 'classnames/bind';
import avatarStyle from './Avatar.module.scss';
import {AvatarInterface} from './Avatar.interface';


export const Avatar: FC<AvatarInterface> = ({className, isResizable, type = 'logo', text = ''}) => {
    const avatarClassName = classNames(
        className,
        avatarStyle.avatar,
        {[avatarStyle.avatar_resizable]: isResizable},
        [avatarStyle[`avatar_type_${type}`]],
    );

    return (
        <span className={avatarClassName}>
            {text}
        </span>
    );
};
