import React, {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import style from './Info.module.scss';
import {Button} from 'components';
import {FastInfo} from '../fastInfo';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import {InfoInterface} from './Info.interface';
import {useMedia} from 'hooks';
import {LandingTypes} from '../../typings';
import classNames from 'classnames/bind';
import {takeAdsName} from '../../consts';
import {ReactComponent as ChromeIcon} from 'assets/images/icons/chrome.svg';

export const Info: FC<InfoInterface> = (props) => {
    const isMobile = useMedia();
    const {t} = useTranslation();
    const {onCancelClick, onActivateClick} = props;
    const {landingType, searchOffer, uriParams, geoBonus} = useSelector((state: RootState) => state.landingReducer);

    const infoTextClassName = classNames(style.info_text, [style[`info_text_${landingType}`]]);
    const action = searchOffer?.action || '10%';
    const domain = 'Eternal Website';

    return (
        <div className={style.info}>
            {(!!searchOffer?.image && landingType === LandingTypes.DIRECTV2) &&
              <div className={style.info_logo}>
                <img className={style.info_logo_img} src={searchOffer.image} alt={'Direct link logo'}/>
              </div>
            }
            {landingType === LandingTypes.DIRECTV2 &&
              <h1 className={`textBodyMedium_L ${style.info_title}`}>{t('Taking you to')}
                <br/>
                  {uriParams?.domain || t('External website')}
              </h1>
            }
            {landingType === LandingTypes.WELCOME &&
              <h1 className={`textBodyMedium_L ${style.info_title}`}>
                <Trans i18nKey='welcomeLandingTitle'>
                  Welcome to <span className='brand_text'>Gift</span>mio <span className='brand_text'>Cashback</span> Service
                </Trans>
              </h1>
            }
            <p className={infoTextClassName}>
                { landingType === LandingTypes.DIRECTV2 || landingType.includes(takeAdsName)
                    ? !isMobile ? uriParams?.txt ||
                        <Trans i18nKey='directLandingWantToSaveWithAction' action={action}>
                            Want to save up to <span className='brand_text'>{{action}}</span>?
                        </Trans>
                        : <></>
                    :
                    (geoBonus && <Trans i18nKey='directLandingJoinText' geoBonus={geoBonus}>
                      Join Giftmio now and earn <span className='brand_text'>{{geoBonus}}</span> for registration
                    </Trans>)
                }
            </p>
            {!isMobile && <FastInfo/>}
            {(!isMobile && (landingType === LandingTypes.DIRECTV2 || landingType.includes(takeAdsName)))
                && <div className={style.info_btns_wrapper}>
                    {landingType === LandingTypes.DIRECTV2 && <p className={`textBodyMedium_01}`}>
                        {t('Never miss a cashback opportunity while shopping online')}
                    </p>}
                    <div className={style.info_btns}>
                        <Button onClick={onCancelClick} theme='stroke' kind={'ellipse'} textContent={t('No, I hate saving')}/>
                        {landingType.includes(takeAdsName) &&
                          <Button onClick={onActivateClick} textContent={t('Yes, activate my cashback!')} kind='ellipse'/>}
                        {landingType === LandingTypes.DIRECTV2 &&
                          <Button onClick={onActivateClick}
                                  IconLeft={ChromeIcon}
                                  isIconAbsolute={true}
                                  textContent={t('Yes, add Chrome extension')}
                                  kind='ellipse'/>}
                    </div>
              </div>}
            {/*Translations for dummy project*/}
            <div className={style.info_hidden}>
                <Trans i18nKey='dummyLinkUnavailable' domain={domain}>
                    Link to {{domain}} is
                    <br/>
                    Unavailable
                </Trans>
                <Trans i18nKey='dummyLandingWantToSave' action={action}>
                    Would you like to continue shopping with up to <span
                    className='brand_text'>{{action}}</span> cashback?
                </Trans>
                <Trans i18nKey='dummyLinkAuthText'>
                    Discover more cashback opportunities at Giftmio. <b>Install our extension</b> and stay updated on
                    the latest deals.
                </Trans>
                <Trans i18nKey="dummyLinkAuthNote">
                    It seems the link you clicked <br/>
                    <b>is broken or no longer valid</b>
                </Trans>
                <span>{t('Oops... This link seems to be broken.')}</span>
                <span>{t('Never miss a cashback opportunity while shopping online.')}</span>
                <span>
                    {t('Get more opportunities and stay up to date with the latest offers by installing the Giftmio extension.')}
                </span>
                <span>{t('No, take me back')}</span>
            </div>

        </div>
    );
};
