import {useDispatch, useSelector} from 'react-redux'
import {SubmitHandler, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useCallback, useEffect, useRef, useState} from 'react'
import {registrationValidationSchema} from '../validation-schemes/registration';
import {MainRoutesEnum, notificationListPushItem, RootState} from 'core';
import {
    createSsoUser,
    getIdentityProviders,
    hideLandingLoader,
    setShowUnloadAlert,
    showLandingLoader
} from '../landing.reducer';
import {AnalyticEventNameEnum} from 'typings';
import {
    getGaABTestLanding,
    getLandingLocation,
    openNewLocation,
    sendAnalyticEvents,
    setCookie,
    getClickUnderLink,
    getCookie,
    getSsoLocales
} from 'utils';
import KeycloakService from '../services/KeycloackService';
import {ENotificationsThemes} from '../../../components/Notifications';
import {LandingTypes, RegistrationForm, SocialLink} from '../typings';
import {SsoConst} from 'consts';
import {socialList} from '../consts';

export const useRegistrationForm = () => {
    const dispatch = useDispatch();
    const REDIRECT_URL = process.env.REACT_APP_SSO_REDIRECT_URL;
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const [reCaptchaToken, setReCaptchaToken] = useState('');
    const [socialLinks, setSocialLinks] = useState<SocialLink[]>([]);
    const [redirectUrl, setRedirectUrl] = useState<string>(REDIRECT_URL || '');
    const [currentCaptchaRef, setCurrentCaptchaRef] = useState<React.MutableRefObject<any>>(useRef(null));
    const {jwtToken, formErrors, identityProviders, uriParams, landingType, isLoading} = useSelector((state: RootState) => state.landingReducer);
    const {selectedRegion} = useSelector((state: RootState) => state.commonReducer);


    const formMethods = useForm({
        mode: 'onBlur',
        shouldFocusError: false,
        resolver: yupResolver(registrationValidationSchema),
        defaultValues: {
            email: '',
            password: '',
            terms: false,
        },
    });
    const {setError } = formMethods;
    const handleSubmitForm: SubmitHandler<RegistrationForm> = (formData) => {
        dispatch(setShowUnloadAlert(false));
        const selectedRegionValue = selectedRegion.value as string || '';
        const requestData = {
            ...formData,
            ...{clientId: CLIENT_ID, terms: formData.terms ? 'checked' : null,
                region: selectedRegionValue,
                ga: getCookie('_ga'),
                registrationSource:  `landing_${landingType}`
            }
        };
        const url = new URL(redirectUrl);
        url.searchParams.set('country', selectedRegionValue);
        setCookie('country', selectedRegionValue);
        currentCaptchaRef.current.reset();
        setRedirectUrl(url.href);

        dispatch(createSsoUser({data: requestData, headers: {'Recaptcha': reCaptchaToken}}));

        const gaOptions = {
            event_option1: '',
            event_option2: getLandingLocation(landingType),
            program_id: uriParams?.domain || 'undefined',
            sendbox_id: uriParams?.admitad_uid || 'undefined',
            website_id: uriParams?.wid || 'undefined',
            ab_test: getGaABTestLanding(landingType, uriParams?.ab)
        };
        const postHogOptions = {
            button_name: AnalyticEventNameEnum.CLICK_SIGNUP,
            button_location: getLandingLocation(landingType),
            store_name: uriParams?.domain,
            referral_wid: uriParams?.wid
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_SIGNUP, {gaOptions, postHogOptions});
    };

    const handleRecaptcha = (captchaRef) => {
        setCurrentCaptchaRef(captchaRef);
        setReCaptchaToken(captchaRef.current.getValue());
    };

    const handleCreateRedirectLink = useCallback(() => {
        let nextParam = `${MainRoutesEnum.FAKE_LINK}/?url=${encodeURIComponent(uriParams?.site_url || "")}`;
        if (uriParams?.original_link) {
            try {
                const ulpParam = new URL(uriParams?.original_link).searchParams.get('ulp');
                if (ulpParam) {
                    nextParam += `&ulp=${encodeURIComponent(ulpParam)}`;
                }
            } catch (e) {
                console.error(e);
            }
        }
        let redirectLink = `${REDIRECT_URL}?next=${encodeURIComponent(nextParam)}`;
        if (uriParams?.admitad_uid) {
            redirectLink += `&admitad_uid=${uriParams?.admitad_uid}`;
        }
        if (uriParams?.wid) {
            redirectLink += `&wid=${uriParams?.wid}`;
        }
        setRedirectUrl(redirectLink);
    }, [uriParams, REDIRECT_URL]);

    const handleKeycloakInit = useCallback(() => {
        dispatch(showLandingLoader());

        KeycloakService.initKeycloak(() => {
                dispatch(hideLandingLoader());
                openNewLocation(redirectUrl);
            },
            () => {
                dispatch(getIdentityProviders());
            },
            (msg) => {
                dispatch(notificationListPushItem({
                    id: Date.now().toString(),
                    title: msg,
                    theme: ENotificationsThemes.critical,
                }));
                dispatch(hideLandingLoader());
            })
    }, [dispatch, redirectUrl]);

    const handleSocialLinkInit = useCallback(() => {
        const supportedSocial = socialList.filter((item) => identityProviders.includes(item));
        const socialRegisterLinks = supportedSocial.map((item) => {
            const url = new URL(KeycloakService.createLoginUrl({redirectUri: redirectUrl, idpHint: item}));
            return {type: item, link: url.toString()};
        });
        setSocialLinks(socialRegisterLinks);
        if (isLoading) {
            dispatch(hideLandingLoader());
        }
    }, [dispatch, identityProviders, redirectUrl, isLoading]);

    const handleGoToSso = useCallback((jwtToken: string) => {
        dispatch(setShowUnloadAlert(false));
        const url = new URL(KeycloakService.createLoginUrl({redirectUri: redirectUrl}));
        url.searchParams.append('magic-jwt', jwtToken);
        url.searchParams.set('response_mode', 'query');
        setTimeout(() => {
            openNewLocation(url.href);
        });
    }, [dispatch, redirectUrl]);

    const handleSocialCLick = (social: SocialLink) => {
        dispatch(setShowUnloadAlert(false));

        const gaOptions = {
            event_option1: '',
            event_option2: getLandingLocation(landingType),
            event_option4: social.type,
            program_id: uriParams?.domain || 'undefined',
            sendbox_id: uriParams?.admitad_uid || 'undefined',
            website_id: uriParams?.wid || 'undefined',
            ab_test: getGaABTestLanding(landingType, uriParams?.ab)
        };
        const postHogOptions = {
            button_name: `${AnalyticEventNameEnum.CLICK_LOG_IN_VIA}_${social.type}`,
            button_location: getLandingLocation(landingType),
            store_name: uriParams?.domain,
            referral_wid: uriParams?.wid
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_LOG_IN_VIA, {gaOptions, postHogOptions});
        openNewLocation(social.link, true);
        let currentRedirectLink = getClickUnderLink(uriParams?.original_link, landingType);
        if (currentRedirectLink) {
            setTimeout(() => {
                window.location.href = currentRedirectLink || '';
            });
        }
    };

    const handleSignIn = () => {
        dispatch(setShowUnloadAlert(false));
        const gaOptions = {
            event_option1: '',
            event_option2: getLandingLocation(landingType),
            program_id: uriParams?.domain || 'undefined',
            sendbox_id: uriParams?.admitad_uid || 'undefined',
            website_id: uriParams?.wid || 'undefined',
            ab_test: getGaABTestLanding(landingType, uriParams?.ab)
        };
        const postHogOptions = {
            button_name: `${AnalyticEventNameEnum.CLICK_LOG_IN}`,
            button_location: getLandingLocation(landingType),
            store_name: uriParams?.domain,
            referral_wid: uriParams?.wid
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_LOG_IN, {gaOptions, postHogOptions});
        setTimeout(() => {
            window.location.href = `${SsoConst.login}?${getSsoLocales()}`;
        });
    }

    useEffect(() => {
        if (!!formErrors && Object.keys(formErrors).length) {
            try {
                for (const key in formErrors) {
                    setError(key as keyof RegistrationForm, {
                        message: formErrors[key][0] || '',
                    });
                }
            } catch (e) {
                throw new Error (`Something wrong with setError method. ${e}`, )
            }
        }

        if (!!jwtToken) {
            handleGoToSso(jwtToken);
        }
    }, [formErrors, jwtToken, handleGoToSso, setError]);

    useEffect(() => {
        handleKeycloakInit();
    }, [dispatch]);

    useEffect(() => {
        if (!uriParams || !Object.keys(uriParams).length) {
            return;
        }
        if (landingType === LandingTypes.DIRECTV2) {
            handleCreateRedirectLink();
        }
    }, [uriParams, landingType, handleCreateRedirectLink]);

    useEffect(() => {
        if (!identityProviders?.length) {
            return;
        }
        handleSocialLinkInit();
    }, [identityProviders, handleSocialLinkInit]);

    return {
        selectedRegion,
        socialLinks,
        reCaptchaToken,
        handleSubmitForm,
        handleRecaptcha,
        handleSocialCLick,
        handleSignIn,
        formMethods,
    };
};
