export enum AnalyticEventNameEnum {
    PAGEVIEW = 'page_view',
    CLICK_LOG_IN = 'click_log_in',
    CLICK_LOG_IN_VIA = 'click_log_in_via',
    CLICK_GET_CASHBACK = 'click_get_cashback',
    CLICK_BUY_WITH_CASHBACK = 'click_buy_with_cashback',
    CLICK_WITHDRAWALS = 'click_withdrawals',
    CLICK_SEND_AGAIN = 'click_send_again',
    CLICK_GO_SHOPPING = 'click_go_shopping',
    CLICK_ACTIVATE_PROMO_CODE = 'click_activate_promo_code',
    CLICK_CREATE_REQUEST = 'click_create_request',
    CLICK_APPLY_PROMO_CODE = 'click_apply_promo_code',
    CLICK_COPY_LINK = 'click_copy_link',
    CLICK_SHARE = 'click_share',
    CLICK_ACTIVATE_MY_CASHBACK = 'click_activate_my_cashback',
    CLICK_I_HATE_SAVING = 'click_i_hate_saving',
    CLICK_HELPS = 'click_helps',
    CLICK_PRIVACY = 'click_privacy',
    CLICK_TERMS = 'click_terms',
    CLICK_PROGRAM = 'click_program',
    CLICK_SIGNUP = 'click_sign_up',
}
