import React, {FC} from 'react';
import actionBodyStyle from './ActionCardBody.module.scss';
import {ActionCardBodyInterface} from './ActionCardBody.interface';


export const ActionCardBody: FC<ActionCardBodyInterface> = (props) => {
    const {name, date, description = ''} = props;

    return (<div className={actionBodyStyle.action_card_body}>
                <div className={actionBodyStyle.action_card_body__content}>
                    <span className={'textBodyMedium_03'}>{name}</span>
                    {!!description && <span className={`textBody_04 ${actionBodyStyle.action_card_body__desc}`}>{description}</span>}
                </div>
                <span className={`textBody_03 ${actionBodyStyle.action_card_body__date}`}>{date}</span>
            </div>
    );
};
