import {RouteObject} from 'react-router-dom';
import {Faqs, Support, Terms, PrivacyPolicy, Impressum, Contacts} from '../pages';
import {PageNotFound} from 'core';
import { InfoRoutesPathEnum} from 'core/Routes/typings/enums/InfoRoutesPath.enum';



export const infoRoutes: RouteObject[] = [
    {
        element: <Faqs/>,
        path: `${InfoRoutesPathEnum.FAQS}/*`,
    },
    {
        element: <PrivacyPolicy/>,
        path: `${InfoRoutesPathEnum.PRIVACY}/*`,
    },
    {
        element: <Support/>,
        path: `${InfoRoutesPathEnum.SUPPORT}/*`,
    },
    {
        element: <Terms/>,
        path: `${InfoRoutesPathEnum.TERMS}/*`,
    },
    {
        element: <Impressum/>,
        path: `${InfoRoutesPathEnum.IMPRESSUM}/*`,
    },
    {
        element: <Contacts/>,
        path: `${InfoRoutesPathEnum.CONTACTS}/*`,
    },

    {element: <PageNotFound/>, path: '/*'},
];
