import React, {FC, HTMLAttributes} from 'react';
import styles from './WithdrawalMessage.module.scss';
import {Trans, useTranslation} from 'react-i18next';

export interface WithdrawalMessageInterface extends HTMLAttributes<HTMLDivElement> {}

export const WithdrawalMessage: FC<WithdrawalMessageInterface> = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.supportMessage}>
            <span className={`textHeader_09 ${styles.supportMessage_title}`}>{t(`Can't find the right withdrawal method?`)}</span>
            <span className={`textBodyMedium_03`}>
                <Trans i18nKey='withdrawalSupport'>
                    Reach out to our support team at <a href="mailto:support@giftmio.com">support@giftmio.com</a>. We'll make every effort to add a fitting option for you.
                </Trans>
            </span>
        </div>
    );
};
