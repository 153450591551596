import { useEffect, useState } from "react";

export const useResize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const updateZize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateZize);

    return () => window.removeEventListener("resize", updateZize);
  }, []);

  return size;
};
