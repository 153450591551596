import React, {FC} from 'react';
import style from './ReferralSocial.module.scss';
import {useTranslation} from "react-i18next";
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton} from 'react-share';
import {ReactComponent as FacebookIcon} from 'assets/images/social-media/facebook.svg';
import {ReactComponent as WhatsappIcon} from 'assets/images/social-media/whatsapp.svg';
import {ReactComponent as XIcon} from 'assets/images/social-media/x.svg';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, PostHogOptionsEnum} from 'typings';
import {sendAnalyticEvents} from 'utils';



export const ReferralSocial: FC <{referralUrl: string;}> = ({referralUrl}) => {
    const {t} = useTranslation();
    const giftmioTitle = t('Giftmio best cashback servise');

    const handleSocialClick = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.REFERRAL_PROGRAM,
            event_option4: referralUrl
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.SHARE,
            button_location: AnalyticEventOptionsEnum.REFERRAL_PROGRAM
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_SHARE, {gaOptions, postHogOptions});
    }

    return (
        <div className={style.content}>
            <span className={`textBody_02`}>{t('Share')}:</span>
            <FacebookShareButton
                beforeOnClick={handleSocialClick}
                url={referralUrl}
                quote={giftmioTitle}
                hashtag="#giftmio">
                <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton
                beforeOnClick={handleSocialClick}
                url={referralUrl}
                title={giftmioTitle}>
                <XIcon />
            </TwitterShareButton>
            <WhatsappShareButton
                beforeOnClick={handleSocialClick}
                url={referralUrl}
                title={giftmioTitle}>
                <WhatsappIcon />
            </WhatsappShareButton>
        </div>
    );
};
