import React, {FC} from 'react';
import style from './BannerExtensionMain.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import {Button} from 'components';
import {InternalLinksEnum} from 'typings';
import {ReactComponent as ChromeIcon} from 'assets/images/banners/chrome-logo.svg';
import mainLogo from 'assets/images/banners/banner-main.png';


export const BannerExtensionMain: FC = () => {
    const {t} = useTranslation();
    const isMobile = useMedia();

    return (
        <div className={style.banner}>
            <img src={mainLogo} className={style.image} alt={'Giftmio Extension'} />
            <div className={style.banner_content}>
                <h3 className={`${isMobile ? 'textHeader_05' : 'textHeader_02'} ${style.banner_title}`}>
                    <Trans i18nKey={'banner_account_extension_title'}>
                        Never miss <span className='brand_text'>a cashback opportunity</span> while shopping online
                    </Trans>
                </h3>
                <p
                    className={`${isMobile ? 'textBody_03' : 'textBody_01'}`}>
                    {t(`Install Giftmio extension for smart shopping: get notified on cashback opportunities, activate cashback in one click, and save money on purchases`)}
                </p>
                <div className={style.buttons}>
                    <Button href={InternalLinksEnum.EXTENSION}
                            target={'_blank'}
                            textContent={t('Add to chrome')}
                            IconLeft={ChromeIcon}
                            isFullWidth={isMobile}
                            theme={'fill'}
                            kind={'ellipse'}>
                    </Button>
                    <Button href={InternalLinksEnum.EXTENSION_LANDING}
                            target={'_blank'}
                            textContent={t('How it works')}
                            theme={'ghost_empty'}>
                    </Button>
                </div>

            </div>
        </div>
    );
};
