import React, {FC} from 'react';
import offerHeaderStyle from './OfferHeader.module.scss';
import {useMedia} from 'hooks';
import {Trans, useTranslation} from 'react-i18next';
import {OfferInterface} from 'typings';
import {Button} from 'components';



export const OfferHeader: FC<{ offer: OfferInterface; handleCashbackClick: (e?) => void}> = (props) => {
    const {offer, handleCashbackClick} = props;
    const {cashback, cashback_origin} = offer;
    const {t} = useTranslation();
    const isMobile = useMedia();
    const imageSize = '68px';

    return (
        <div className={offerHeaderStyle.offer_header}>
            <button className={offerHeaderStyle.offer_header__logo} onClick={handleCashbackClick}>
                <img className={offerHeaderStyle.offer_header__logo_img} src={offer?.image} height={imageSize} alt={'offer logo'}/>
            </button>
            <div className={offerHeaderStyle.offer_header__content}>
                <span className={`${isMobile ? 'textHeader_06' : 'textHeader_03'}`}>
                    <span>{offer?.name}</span>&nbsp;
                    <span className={offerHeaderStyle.offer_header__content_text}>
                        {!cashback_origin ? <Trans i18nKey='offerDetailsUpTo' cashback={cashback}>
                                up to {{cashback}} Cashback
                            </Trans>
                            : <Trans i18nKey='offerDetailsUpToWithOrigin' cashback={cashback} cashback_origin={cashback_origin}>
                                up to <span className={offerHeaderStyle.offer_header__with_origin}>{{cashback_origin}}</span> {{cashback}} Cashback
                            </Trans>
                        }
                    </span>
                </span>
                <span className={`${isMobile ? 'textBody_04' : 'textBody_03'}`}>{offer?.short_description}</span>
            </div>
            <div className={offerHeaderStyle.offer_header__button}>
                <Button isFullWidth={isMobile} onClick={handleCashbackClick} kind={'ellipse'} textContent={t('Buy with cashback')}></Button>
            </div>
        </div>
    );
};
