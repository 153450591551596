import React, {FC} from 'react';
import storeCardStyle from './StoreCard.module.scss';
import {useMedia} from 'hooks';
import {Trans} from 'react-i18next';
import {StoreCardInterface} from './StoreCard.interface';
import {Link} from 'react-router-dom';
import { setOpenFromCategory } from 'modules';
import { useDispatch } from 'react-redux';


export const StoreCard: FC<StoreCardInterface> = (props) => {
    const {src, name, cashback, slug, currerntCategory} = props;
    const isMobile = useMedia();
    const dispatch = useDispatch();

    const onStoreOpen = () => {
        dispatch(setOpenFromCategory(currerntCategory || null));
    }

    return (
        <Link to={`/offer/${slug}/`} onClick={onStoreOpen} className={storeCardStyle.storeCard}>
            <div className={storeCardStyle.storeCard__logo_wrap}>
                <img className={storeCardStyle.storeCard__logo} src={src} alt="GiftMio"/>
            </div>
            <span className={`${storeCardStyle.storeCard__text} ${isMobile ? 'textBodyMedium_04' : 'textBodyMedium_02'}`}>
                <Trans i18nKey='storeCardUpTo' cashback={cashback}>Up to {{cashback}}<br/>Cashback</Trans>
            </span>
            <span className={`${storeCardStyle.storeCard__name} ${isMobile ? 'textBodyMedium_05' : 'textBodyMedium_05'}`}>{name}</span>
        </Link>
    );
};
