import React, {FC, useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import menuMobileStyle from "./MenuMobileSearch.module.scss";
import {SearchInput, LinkComponent, MobileOverlayLayout} from 'components';
import {getSmartOffers, MainRoutesEnum, RootState} from 'core';
import {OfferInterface, SmartOfferInterface} from 'typings';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export const MenuMobileSearch: FC<{popularStores: OfferInterface[]; onHandleToggleSearch: any}> = (props) => {
    const {popularStores, onHandleToggleSearch} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {smartOffers} = useSelector((state: RootState) => state.commonReducer);
    const [firstInit, setFirstInit] = useState<boolean>(true);
    const [showSearchList, setShowSearchList] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string | undefined>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!smartOffers?.length) {
            return;
        }
        setShowSearchList(true);
    }, [smartOffers]);

    const handleSearchChange = useCallback((value) => {
        const controller = new AbortController();
        if (!value && firstInit) {
            setFirstInit(false);
            return;
        }
        if (!value) {
            setShowSearchList(false);
            return;
        }
        dispatch(getSmartOffers({queryParams: {s: value}, controller}));
    }, [firstInit, dispatch]);

    const handleClickLink = (offer?: SmartOfferInterface) => {
        setShowSearchList(false);
        setFirstInit(true);
        setSearchValue(undefined);
        onHandleToggleSearch();
        if (!offer) {
            return;
        }
        const navigateUrl = offer.mega ? `${MainRoutesEnum.MEGA}/${offer.uuid}/` : `${MainRoutesEnum.BRAND}/${offer.slug}/`
        navigate(navigateUrl);
    };

    const handleCloseSearch = () => {
        setShowSearchList(false);
        setFirstInit(true);
        onHandleToggleSearch();
    };
    return (
        <MobileOverlayLayout title={t('Search')} onCloseClick={handleCloseSearch}>
            <div className={menuMobileStyle.offer}>
                <h1 className={`textHeader_08 ${menuMobileStyle.search_title}`}>
                    <Trans i18nKey='search_text'>
                        Search by brand name or <span className='brand_text'>by store URL address</span>
                    </Trans>
                </h1>
                <p className={`textBody_04 ${menuMobileStyle.search_text}`}>{t(`Just start typing the name of your favorite store or copy the link to it, and we will let you know if you can get cashback for purchases in it.`)}</p>
                <div className={menuMobileStyle.search_input}>
                    <SearchInput onChange={handleSearchChange} searchValue={searchValue} placeholder={t('Search brands or insert your links')}/>
                </div>
                <hr className={menuMobileStyle.mms__hr}/>
                {showSearchList
                    ? <>{!!smartOffers?.length
                        ? <>{smartOffers.map(offer => (
                            <div className={menuMobileStyle.offer__link} key={Math.random()}>
                                <LinkComponent textContent={offer.name} onClick={() => {handleClickLink(offer)}}/>
                            </div>
                            ))}
                        </>
                        : <div className={menuMobileStyle.offer__link}>{t('There’s nothing here yet, try again with the link')}</div>
                        } </>
                    : <><span className={menuMobileStyle.offer__title}>{t('Popular store')}</span>
                        {popularStores.map(store => (
                            <div className={menuMobileStyle.offer__link} key={store.id}>
                                <LinkComponent textContent={store.name} to={`/offer/${store.slug}/`} onClick={handleClickLink}/>
                            </div>
                        ))}
                    </>
                }
            </div>
        </MobileOverlayLayout>
    );
};
