import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'components';
import {FirstScreenInterface} from './FirstScreen.interface';
import style from '../../CountryModal.module.scss';
import {ReactComponent as ArrowRightIcon} from 'assets/images/icons/arrow-right.svg';
import {FlagIcons} from 'consts';

export const FirstScreen: FC<FirstScreenInterface> = (props) => {
    const {configRegion, currentGeo, loggedIn, onConfirmClick, onChangeClick, onCloseClick} = props;
    const {t} = useTranslation();

    return (
        <Fragment>
            <div className={style.dialog__header}>
            <h2 className={`textHeader_07`}>
                {!!configRegion
                    ? <><span>{t(`It seems you are browsing from`)}</span>: <span className={style.dialog__header_region}>{configRegion}</span>?</>
                    : <span>{t(`We couldn't determine your region.`)}</span>
                }
            </h2>
            <p className={`${style.dialog__text} textBody_04`}>{t(`Please confirm your selected country is correct. Your choice impacts the offers and stores available to you.`)}</p>
            </div>
            <div className={style.dialog__content}>
                {!!configRegion
                    ? <span className={`${style.dialog__text} textHeader_09`}>{t(`Choose another country:`)}</span>
                    : <span className={`${style.dialog__text} textHeader_09`}>{t(`Choose a region in the field below:`)}</span>
                }
                <div className={style.dialog__content_list}>
                    <Button textContent={configRegion ? configRegion : 'Choose country'}
                            isFullWidth={true}
                            textAlign='left'
                            size='xxl'
                            img={currentGeo && <img alt={'Location icon'} className={style.dialog__img_icon} src={FlagIcons[currentGeo]}/>}
                            IconRight={ArrowRightIcon}
                            theme={'grey'}
                            onClick={onChangeClick}/>
                </div>
                {loggedIn && <p className={`textBody_05 ${style.dialog__content_note}`}>
                    {t('After selecting and confirming the region, you will not be able to change it')}
                </p>}
            </div>
            <div className={`${style.dialog__footer} controls controls_align_justify`}>
                {!loggedIn && <Button theme={'stroke'}
                                      size='xxl'
                                      onClick={onCloseClick}
                                      textContent={t('Close')}/>}
                <Button className={style.dialog__footer_btn_confirm}
                        disabled={!currentGeo}
                        onClick={onConfirmClick}
                        size='xxl'
                        textContent={t('Confirm')}/>
            </div>
        </Fragment>
    );
};
