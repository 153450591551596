import React, {FC} from 'react';
import categoryCardStyle from './CategoryCard.module.scss';
import {categoriesIcons} from 'utils';
import {useMedia} from 'hooks';
import {Link} from 'react-router-dom';


export const CategoryCard: FC<{slug: string; text: string;}> = (props) => {
    const {slug, text} = props;
    const isMobile = useMedia();
    const size = isMobile ? '40px' : '50px'

    return (
        <Link to={`/catalog/${slug}`} className={categoryCardStyle.categoryCard}>
            <img className={categoryCardStyle.categoryCard__link_logo} src={categoriesIcons[slug]} width={size} height={size} alt={text}/>
            <span className={`${isMobile ? 'textBodyMedium_06' : 'textBodyMedium_04'}`}>{text}</span>
        </Link>
    );
};
