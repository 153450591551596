import React, {FC, useRef} from 'react';
import {Button} from 'components';
import style from './Registration.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {InputSizes} from '@design-system/ui-kit';
import {Input, Checkbox} from 'components';
import {Link} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {useRegistrationForm} from '../../hooks';
import {FormProvider} from 'react-hook-form';
import {InfoRoutesPathEnum, MainRoutesEnum} from 'core';
import classNames from 'classnames/bind';
import {CountrySelector} from '../countrySelector';
import { useResize } from 'hooks';
import { SizeBreakpointsEnum } from 'typings';


export const Registration: FC = () => {
    const {t} = useTranslation();
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
    const { selectedRegion, reCaptchaToken, socialLinks, handleSubmitForm, handleRecaptcha, handleSocialCLick, handleSignIn, formMethods } = useRegistrationForm();
    const { formState, handleSubmit } = formMethods;
    const captchaRef = useRef(null);
    const { width } = useResize();

    return (
            <div className={style.registration}>
                <div className={style.header}>
                    <h1 className={`textHeader_07`}>
                        {t('Join us! Create account in Giftmio')}
                    </h1>
                    <div className={style.header_socials}>
                        {socialLinks.map(social => (
                            <button className={classNames([style.header_social_btn, style[`header_social_btn_${social.type}`]])} key={social.type} onClick={()=> {handleSocialCLick(social)}}>
                                <div className={classNames([style[`header_social_${social.type}`]])}></div>
                            </button>
                        ))}
                    </div>
                </div>
                <FormProvider {...formMethods}>
                    <form className={style.form} name='registration form' onSubmit={handleSubmit(handleSubmitForm)}>
                        <h2 className={`textBodyMedium_02`}>
                            {t('or sign up via email')}
                        </h2>
                        <Input
                            placeholder={t('Email')}
                            required={true}
                            name="email"
                            size={InputSizes.xxl}/>
                        <Input
                            placeholder={t('New password')}
                            required={true}
                            name="password"
                            type="password"
                            size={InputSizes.xxl}/>
                        <CountrySelector/>
                        <div className={style.form_recaptcha}>
                            <ReCAPTCHA
                                key={ width < SizeBreakpointsEnum.MOBILE_SMALL  ? "compact-recaptcha" : "normal-recaptcha" }
                                sitekey={RECAPTCHA_SITE_KEY}
                                size={ width < SizeBreakpointsEnum.MOBILE_SMALL ? "compact" : "normal" }
                                ref={captchaRef}
                                onChange={() => handleRecaptcha(captchaRef)}/>
                        </div>
                        <Checkbox
                            name="terms"
                            onChange={() => {}}
                            label={<Trans i18nKey='privacyPolicy'><span className={style.form_terms}>I give an opinion on the processing of my data and confirm that the verification was carried out with the <Link to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.PRIVACY}`} className={style.registration_link}>"Privacy Policy"</Link> of GiftMio.</span></Trans>}
                        />
                        <div className={style.form__btns} >
                            <button className={`${style.registration_link} textButton_02`} type={"button"} onClick={handleSignIn}>{t('Sign in')}</button>
                            <Button type='submit' disabled={!formState.isValid || !reCaptchaToken || !selectedRegion?.value} kind='ellipse' textContent={t('Create account')}/>
                        </div>
                     </form>
                </FormProvider>
            </div>
    );
};
