import {debugLogHelper} from './debugLog';
import {getGaParams} from './getGaParams';

export const sendGaEvent = (eventName, eventOptions = {}) => {
    const ga4Params  = {...getGaParams(), ...eventOptions};

    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'dev') {
        debugLogHelper(eventName, ga4Params);
    } else {
        try {
            gtag('event', eventName, ga4Params);
        } catch (error) {
            console.error('Caught error while sending GA4', error);
        }
    }
};
