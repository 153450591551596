import React, {FC} from 'react';
import style from './DropdownBox.module.scss';
import {DropdownBoxInterface} from './DropdownBox.interface';
import classNames from "classnames/bind";


export const DropdownBox: FC<DropdownBoxInterface> = (props) => {
    const {kind = 'round', isAbsolute = false} = props
    const dropdownBoxClassName = classNames(
        style.box,
        [style[`box_kind_${kind}`]],
        {
            [style.box_absolute]: isAbsolute,
        },
    );

    return (
        <div className={dropdownBoxClassName}>
            {props.children}
        </div>
    );
};
