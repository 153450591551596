import {MainRoutesEnum} from '../core/Routes/typings/enums/MainRoutes.enum';

export const LandingsConst: string[] = [
    MainRoutesEnum.DIRECT_LINK,
    MainRoutesEnum.DIRECT_LANDING,
    MainRoutesEnum.SEARCH_LANDING,
    MainRoutesEnum.TAKE_ADS_LANDING_V1,
    MainRoutesEnum.TAKE_ADS_LANDING_V2,
    MainRoutesEnum.WELCOME_LINK,
    MainRoutesEnum.EXTENSION,
    MainRoutesEnum.DIRECT_EXTENSION
];
