import React, {FC, useEffect, useMemo, useState} from 'react';
import {ActionsHeader, WithdrawalAmount, WithdrawalLimits, WithdrawalMethod, WithdrawalMessage} from '../../componets';
import {
    AnalyticEventNameEnum,
    AnalyticEventOptionsEnum,
    RestrictionInterface,
    UserInterface, UserMenuEnum
} from 'typings';
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {
    createWithdrawal,
    getCurrencyRestrictions,
    resetAccountToInitialState
} from '../../account.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {AccountRoutesPathEnum, getAppConfig, MainRoutesEnum, RootState} from 'core';
import {LoaderWithOverlay} from 'components';
import { useNavigate } from 'react-router-dom';
import {sendAnalyticEvents} from 'utils';
import {useAccountTitle} from '../../hooks';


const Withdrawal: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {appConfig} = useSelector((state: RootState) => state.commonReducer);
    const [user, setUser] = useState<UserInterface>();
    const {currencyRestrictions, isLoading, isWithdrawSucceed, isNeedBillingInfo, withdrawalMethod} = useSelector((state: RootState) => state.accountReducer);
    const [currentRestriction, setCurrentRestriction] = useState<RestrictionInterface>();
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(UserMenuEnum.WITHDRAWALS);

    const handleWithdrawClick = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.WITHDRAWALS,
            event_option4: withdrawalMethod,
        };
        const postHogOptions = {
            button_name: AnalyticEventOptionsEnum.WITHDRAWALS,
            button_location: AnalyticEventOptionsEnum.WITHDRAWALS
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_WITHDRAWALS, {gaOptions, postHogOptions});
        dispatch(createWithdrawal({data: {payment_system_type: withdrawalMethod}}));
    };

    useDocumentTitle(documentTitles[UserMenuEnum.WITHDRAWALS]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[UserMenuEnum.WITHDRAWALS] }],
        [documentTitles[UserMenuEnum.WITHDRAWALS]]);
    useBreadcrumbs(breadcrumbs);
    useEffect(() => {
        if (!appConfig?.user) {
            return;
        }
        setUser(appConfig.user);
        if (currencyRestrictions[appConfig.user.balance?.currency]) {
            setCurrentRestriction(currencyRestrictions[appConfig.user.balance?.currency]);
        }
    }, [appConfig, currencyRestrictions]);

    useEffect(() => {
        dispatch(resetAccountToInitialState());
        dispatch(getAppConfig());
        dispatch(getCurrencyRestrictions());
    }, [dispatch]);

    useEffect(() => {
        if (isWithdrawSucceed) {
            window.location.replace(`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.ACTIONS}withdrawals`);
        }
    }, [isWithdrawSucceed, navigate]);

    useEffect(() => {
        if (isNeedBillingInfo) {
            navigate(`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.BILLING_INFO}`);
        }
    }, [isNeedBillingInfo, navigate]);

    return (
        <section className='page__content'>
            <ActionsHeader user={user} isWithdrawals={true}/>
            <div className='page__blank loader__container page__content_container'>
                <WithdrawalAmount amount={user?.balance?.confirmed}
                                  minAmount={currentRestriction?.min_withdrawal}
                                  currency={user?.balance?.currency}
                                  isNothingToWithdraw={!user?.balance?.balance_amount}
                                  emailConfirmed={user?.email_confirmed}/>
                <WithdrawalMethod onWithdrawClick={handleWithdrawClick} isNothingToWithdraw={!user?.balance?.balance_amount} emailConfirmed={user?.email_confirmed}/>
                <WithdrawalMessage/>
                <WithdrawalLimits restriction={currentRestriction} currency={user?.balance?.currency}/>
                {isLoading && <LoaderWithOverlay/>}
            </div>
        </section>
    );
}

export {Withdrawal};
export default Withdrawal;
