import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDocumentTitle, useGeo} from 'hooks';
import {RootState} from 'core';
import {useDispatch, useSelector} from 'react-redux';
import {Main, ThreeSteps} from '../../components';
import {Accordion, LayoutContainer, LoaderWithOverlay} from 'components';
import {setLandingType} from '../../landing.reducer';
import {LandingTypes} from '../../typings';

const TakeAdsV2: FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { isLoading, uriParams} = useSelector((state: RootState) => state.landingReducer);

    useDocumentTitle(uriParams.domain || t('Take Ads website landing'));
    useEffect(() => {
        dispatch(setLandingType(LandingTypes.TAKEADSV2));
    }, [dispatch]);

    useGeo();

    return (
        <div>
            <Main/>
            <ThreeSteps/>
            <LayoutContainer>
                <Accordion isLanding={true} withoutIndent={true}/>
            </LayoutContainer>
            {isLoading && <LoaderWithOverlay/>}
        </div>
    );
};

export {TakeAdsV2};
export default TakeAdsV2;
