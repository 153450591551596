import cn from 'classnames';
import React, { FC, MouseEvent } from 'react';
import styles from './NotificationItem.module.scss';
import { INotificationItemProps } from './NotificationItem.types';
import {ReactComponent as CloseIcon} from 'assets/images/icons/close.svg';

export const NotificationItem: FC<INotificationItemProps> = (props) => {
  const {
    className,
    id,
    buttonProps,
    title,
    description,
    onClose,
    closable = true,
    theme = 'info',
    backgroundColor = 'black',
  } = props;

  const notificationItemClassName = cn(
    styles.notificationItem,
    className,
    styles[`theme_${theme}`],
    styles[`background_${backgroundColor}`],
  );

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    onClose?.(id);
    buttonProps?.onClick?.(e);
  };


  return (
    <div className={ notificationItemClassName }>
      <div className={styles.coloredStrip} />
      {closable && (
          <button className={styles.closeButton} onClick={handleButtonClick}>
            <CloseIcon/>
          </button>
      )}
      <div className={styles.content}>
        {title && (
            <span className={`textBodyMedium_02 ${styles.textTitle}`}>{title}</span>
        )}
        {description && (
            <p className={`textBody_03 ${styles.textDescription}`}></p>
        )}
      </div>
    </div>
  );
};
