import React, {FC, useMemo} from 'react';
import style from '../privacy/PrivacyPolicy.module.scss';
import {useTranslation} from 'react-i18next';
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {useAccountTitle} from '../../hooks';
import {InfoMenuEnum} from 'typings';

const Impressum: FC = () => {
    const {t} = useTranslation();
    const {documentTitles} = useTransObjects();

    useDocumentTitle(documentTitles[InfoMenuEnum.IMPRESSUM]);
    useAccountTitle(documentTitles[InfoMenuEnum.IMPRESSUM]);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[InfoMenuEnum.IMPRESSUM] }],
        [documentTitles[InfoMenuEnum.IMPRESSUM]]);
    useBreadcrumbs(breadcrumbs);
    return (
        <div className='page__blank page__content_container'>
            <div className={`${style.content} textBodyMedium_01`}>
                <span>{t('Innovation Technology Incubator GmbH')}</span>
                <span>{t('Heinz-Nixdorf-Straße 6, 74172 Neckarsulm')}</span>
                <span>{t(`State registration number: HRB 775252`)}</span>
                <span>{t(`Director: Eugen Geldt`)}</span>
            </div>
        </div>
    );
}

export {Impressum};
export default Impressum;
