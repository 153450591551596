import React, {FC, useState} from 'react';
import menuStyle from './CategoriesMenu.module.scss';
import {useTranslation} from 'react-i18next';
import {ReactComponent as MenuIcon} from 'assets/images/icons/menu.svg';
import {ReactComponent as CloseIcon} from 'assets/images/icons/close.svg';
import { Button, LinkComponent, LayoutContainer } from 'components';
import {categoriesIcons} from 'utils'
import {CategoriesInterface} from 'typings';
import {useCommonSelector, useTransObjects} from 'hooks';
import {MainRoutesEnum} from 'core';

export const CategoriesMenu: FC = () => {
    const categories: CategoriesInterface[] = useCommonSelector<CategoriesInterface[]>('categories');
    const {t} = useTranslation();
    const [showAllCategories, setShowAllCategories] = useState<boolean>(false);
    const {categoriesTrans} = useTransObjects();

    const popularCategories = categories.filter(cat => cat.is_popular) || [];

    const handleAllCategoriesToggle = () => {
        setShowAllCategories(!showAllCategories);
    }
    const handleAllCategoriesHide = () => {
        setShowAllCategories(false);
    }
    return (
        <>
            {!!categories?.length && <div className={menuStyle.categoriesMenu}>
                <div
                    className={`${!showAllCategories && menuStyle.categoriesMenu__shadow} ${menuStyle.categoriesMenu__top}`}>
                    <LayoutContainer>
                        <div className={`textBodyMedium_03 ${menuStyle.categoriesMenu__top_wrapper}`}>
                            <Button onClick={handleAllCategoriesToggle} textContent={t('All categories')}
                                    theme={'stroke'} IconLeft={showAllCategories ? CloseIcon : MenuIcon}/>
                            {popularCategories.map(popularCategory => (
                                <LinkComponent
                                    key={popularCategory.slug}
                                    to={`${MainRoutesEnum.CATALOG}/${popularCategory.slug}`}
                                    onClick={handleAllCategoriesHide}
                                    textContent={categoriesTrans[popularCategory.slug]}
                                    imageSrc={categoriesIcons[popularCategory.slug]}/>
                            ))}
                        </div>
                    </LayoutContainer>
                </div>
                {showAllCategories &&
                  <div className={`${showAllCategories && menuStyle.categoriesMenu__shadow} ${menuStyle.categoriesMenu__bottom}`}>
                      <LayoutContainer>
                        <div className={`textBodyMedium_03 ${menuStyle.categoriesMenu__bottom_wrapper}`}>
                            {
                                categories.map(category => (
                                    <div key={category.slug} className={menuStyle.categoriesMenu__bottom_link}>
                                        <LinkComponent
                                            to={`${MainRoutesEnum.CATALOG}/${category.slug}`}
                                            onClick={handleAllCategoriesHide}
                                            textContent={categoriesTrans[category.slug]}
                                            imageSrc={categoriesIcons[category.slug]}/>
                                    </div>
                                ))
                            }
                        </div>
                      </LayoutContainer>
                </div>}
            </div> }
        </>

    );
};
