import {call, put, StrictEffect, takeEvery} from 'redux-saga/effects';
import {
    createWithdrawalRequest,
    getBillingInfoRequest,
    getCurrencyRestrictionsRequest,
    getEarningsListRequest,
    getPromoCodesRequest,
    getReferralRequest,
    getWithdrawalsListRequest,
    postBillingInfoPaypalRequest,
    postBillingInfoCardRequest,
    registerPromoCodeRequest,
    postBillingInfoCardRuRequest,
} from './account.api';
import {
    hideAccountLoader,
    hideAccountError,
    setEarningsList,
    setEarningsCount,
    showAccountError,
    showAccountLoader,
    getEarningsList,
    getWithdrawalList,
    setWithdrawalsList,
    setWithdrawalsCount,
    getCurrencyRestrictions,
    setCurrencyRestrictions,
    createWithdrawal,
    setWithdrawSucceed,
    setBillingInfo,
    getBillingInfo,
    postBillingInfo,
    setAccountFormErrors,
    setNeedBillingInfo,
    registerPromoCode,
    getPromoCodeList,
    setPromoCodeList,
    setPromoCodeCount,
    getReferralInfo,
    setReferralInfo,
} from './account.reducer';
import {EarningInterface, RequestPayloadType, WithdrawalMethodsEnum, WithdrawalsInterface} from 'typings';
import {
    AccountRoutesPathEnum,
    getAppConfig,
    MainRoutesEnum,
    notificationListPushItem,
} from 'core';
import {
    FallbackErrorTextConst,
    nonFieldErrorsKey,
    paymentNeedBillingInfoErrorKey,
    paymentSystemTypeErrorKey
} from 'consts';
import {ENotificationsThemes} from '../../components/Notifications';
import i18n from 'i18next';


function* getEarningsListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        yield put(hideAccountError());
        const requestParams: any = {
            queryParams: payload.payload.queryParams,
        };
        const data: any = yield call(getEarningsListRequest, requestParams);
        if (data) {
            yield put(setEarningsList(data.results as EarningInterface[]));
            yield put(setEarningsCount(data.count));
            yield put(hideAccountLoader());
        }
    } catch (error) {
        yield put(showAccountError());
        yield put(hideAccountLoader());
    }
}

function* getWithdrawalsListWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        yield put(hideAccountError());
        const requestParams: any = {
            queryParams: payload.payload.queryParams,
        };
        const data: any = yield call(getWithdrawalsListRequest, requestParams);
        if (data) {
            yield put(setWithdrawalsList(data.results as WithdrawalsInterface[]));
            yield put(setWithdrawalsCount(data.count));
            yield put(hideAccountLoader());
        }
    } catch (error) {
        yield put(showAccountError());
        yield put(hideAccountLoader());
    }
}

function* getCurrencyRestrictionsWorker(): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        const restrictions: any = yield call(getCurrencyRestrictionsRequest);
        yield put(setCurrencyRestrictions(restrictions));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideAccountLoader());
    }
}

function* createWithdrawalWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        yield call(createWithdrawalRequest, requestParams);
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Withdrawal succeed!'),
            theme: ENotificationsThemes.success,
        }));
        yield put(setWithdrawSucceed());
    } catch (error: any) {
        if (!!error[paymentNeedBillingInfoErrorKey]) {
            yield put(setNeedBillingInfo(true));
        }
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error[nonFieldErrorsKey] || error[paymentSystemTypeErrorKey] || error[paymentNeedBillingInfoErrorKey] || i18n.t('Something went wrong with the withdrawal')}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideAccountLoader());
    }
}

function* getBillingInfoWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        const withdrawalMethod = payload?.payload?.withdrawalMethod || '';
        yield put(showAccountLoader());
        const billingInfo: any = yield call(getBillingInfoRequest, withdrawalMethod);
        yield put(setBillingInfo(billingInfo));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideAccountLoader());
    }
}

function* postBillingInfoWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        const withdrawalMethod = payload.payload.withdrawalMethod;
        const isGoToWithdrawal = payload.payload.isGoToWithdrawal;
        if (withdrawalMethod === WithdrawalMethodsEnum.PAYPAL) {
            yield call(postBillingInfoPaypalRequest, requestParams);
        } else if (withdrawalMethod === WithdrawalMethodsEnum.CARD) {
            yield call(postBillingInfoCardRequest, requestParams);
        } else {
            yield call(postBillingInfoCardRuRequest, requestParams);
        }

        if (isGoToWithdrawal) {
            yield call(createWithdrawalRequest, {data: {payment_system_type: withdrawalMethod}});
            yield put(notificationListPushItem({
                id: Date.now().toString(),
                title: i18n.t('Withdrawal succeed!'),
                theme: ENotificationsThemes.success,
            }));
            window.location.replace(`${MainRoutesEnum.ACCOUNT}${AccountRoutesPathEnum.ACTIONS}withdrawals`);
        } else {
            yield put(notificationListPushItem({
                id: Date.now().toString(),
                title: i18n.t('Billing info update succeed!'),
                theme: ENotificationsThemes.success,
            }));
        }
    } catch (error: any) {
        if (!!error[paymentNeedBillingInfoErrorKey]) {
            yield put(notificationListPushItem({
                id: Date.now().toString(),
                title: error[paymentNeedBillingInfoErrorKey],
                theme: ENotificationsThemes.critical,
            }));
        } else {
            yield put(setAccountFormErrors(error));
        }
    } finally {
        yield put(hideAccountLoader());
    }
}

function* getPromoCodesWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            queryParams: payload.payload.queryParams,
            signal: payload.payload.controller?.signal,
        };
        const promoCodes: any = yield call(getPromoCodesRequest, requestParams);
        yield put(setPromoCodeList(promoCodes.results));
        yield put(setPromoCodeCount(promoCodes.count));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideAccountLoader());
    }
}

function* registerPromoCodeWorker(payload: any): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        const requestParams: RequestPayloadType<any, any, any> = {
            data: payload.payload.data,
        };
        yield call(registerPromoCodeRequest, requestParams);
        yield put(getAppConfig());
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: i18n.t('Promo Code added successfully!'),
            theme: ENotificationsThemes.success,
        }));
        const promoCodes: any = yield call(getPromoCodesRequest, payload);
        yield put(setPromoCodeList(promoCodes.results));
        yield put(setPromoCodeCount(promoCodes.count));
    } catch (error) {
        yield put(setAccountFormErrors(error));
    } finally {
        yield put(hideAccountLoader());
    }
}

function* getReferralInfoWorker(): Generator<StrictEffect, void, any> {
    try {
        yield put(showAccountLoader());
        const referralInfo: any = yield call(getReferralRequest);
        yield put(setReferralInfo(referralInfo));
    } catch (error) {
        yield put(notificationListPushItem({
            id: Date.now().toString(),
            title: `${error || FallbackErrorTextConst}`,
            theme: ENotificationsThemes.critical,
        }));
    } finally {
        yield put(hideAccountLoader());
    }
}

export function* accountWatcher() {
    yield takeEvery(getEarningsList.type, getEarningsListWorker);
    yield takeEvery(getWithdrawalList.type, getWithdrawalsListWorker);
    yield takeEvery(getCurrencyRestrictions.type, getCurrencyRestrictionsWorker);
    yield takeEvery(createWithdrawal.type, createWithdrawalWorker);
    yield takeEvery(getBillingInfo.type, getBillingInfoWorker);
    yield takeEvery(getReferralInfo.type, getReferralInfoWorker);
    yield takeEvery(postBillingInfo.type, postBillingInfoWorker);
    yield takeEvery(registerPromoCode.type, registerPromoCodeWorker);
    yield takeEvery(getPromoCodeList.type, getPromoCodesWorker);
}
