import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import userHeaderAuthStyle from './UserHeaderAuth.module.scss';
import {Button} from '../../../Button/Button';
import {useMedia} from 'hooks';
import {useLocation} from 'react-router-dom';
import {HeaderRegion} from '../headerRegion/HeaderRegion';
import {getSsoLocales, sendAnalyticEvents} from 'utils';
import {AnalyticEventNameEnum, AnalyticEventOptionsEnum, InternalLinksEnum, PostHogOptionsEnum} from 'typings';
import {SsoConst} from 'consts';
import {ReactComponent as ChromeIcon} from 'assets/images/icons/chrome.svg';

export const UserHeaderAuth: FC<{country: string;}>= (props) => {
    const {country} = props;
    const {t} = useTranslation();
    const isMobile = useMedia();
    const location = useLocation();

    const handleLoginClick = () => {
        const gaOptions = {
            event_option2: AnalyticEventOptionsEnum.HEADER
        };
        const postHogOptions = {
            button_name: PostHogOptionsEnum.SIGN_IN,
            button_location: PostHogOptionsEnum.HOMEPAGE
        };
        sendAnalyticEvents(AnalyticEventNameEnum.CLICK_LOG_IN, {gaOptions, postHogOptions});
    }
    return (
        <div className={userHeaderAuthStyle.user_auth}>
            <HeaderRegion country={country}/>
            {!isMobile &&
              <Button href={InternalLinksEnum.EXTENSION}
                      IconLeft={ChromeIcon}
                      isIconAbsolute={true}
                      textContent={t('Add to chrome')}
                      kind='ellipse'/>
            }
            <Button onClick={handleLoginClick}
                    href={`${SsoConst.login}?${getSsoLocales()}&next=${location.pathname}`}
                    size={isMobile ? 'l' : 'xl'}
                    textContent={t('Join')}
                    theme={'fill'}
                    kind={'ellipse'}>
            </Button>
        </div>
    );
};
