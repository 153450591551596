import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    AppConfigInterface,
    CategoriesInterface,
    GeoResponseInterface,
    OfferInterface,
    SmartOfferInterface,
    ShortOfferInterface,
    FakeUrlsInterface,
    CountryInterface,
    BreadcrumbsInterface,
} from 'typings';
import {CommonStoreInterface} from './typings';
import {initUseResponseWidget, transformOffersUtil} from 'utils';
import {DEFAULT_LANGUAGE} from '../../consts';


const defaultCommonState: CommonStoreInterface = {
    appConfig: {} as AppConfigInterface,
    breadrumbs: [],
    selectedRegion: {} as CountryInterface,
    appCountry: '',
    categories: [],
    trending_stores: [],
    top_offers: [],
    searched_offers: [],
    geo: [],
    countries: [],
    isLoading: false,
    showCountryDialog: false,
    region: '',
    smartOffers: [],
    megaOffer: {} as SmartOfferInterface,
    fake_urls: {} as FakeUrlsInterface,
    siteLang: DEFAULT_LANGUAGE,
    fetched: {},
};


export const commonSlice = createSlice({
    name: 'common',
    initialState: defaultCommonState,
    reducers: {
        showLoader: state => ({...state, isLoading: true}),
        hideLoader: state => ({...state, isLoading: false}),
        showCountryDialog: state => ({...state, showCountryDialog: true}),
        hideCountryDialog: state => ({...state, showCountryDialog: false}),
        changeRegion: (state, action: PayloadAction<string>) => ({...state, region: action.payload}),
        setAppConfig: (state, action: PayloadAction<AppConfigInterface>) => ({
            ...state,
            appConfig: action.payload,
        }),
        setConfigCountry: (state, action: PayloadAction<string>) => ({
            ...state,
            appCountry: action.payload,
        }),
        setSelectedRegion: (state, action: PayloadAction<CountryInterface>) => ({
            ...state,
            selectedRegion: action.payload,
        }),
        setCategories: (state, action: PayloadAction<CategoriesInterface[]>)=> ({...state, categories: action.payload}),
        setTrendingStores: (state, action: PayloadAction<OfferInterface[]>) => ({
            ...state,
            trending_stores:  transformOffersUtil(action.payload) as OfferInterface[]
        }),
        setTopOffers: (state, action: PayloadAction<OfferInterface[]>) => ({
            ...state,
            top_offers:  transformOffersUtil(action.payload) as OfferInterface[]
        }),
        setSearchedOffers: (state, action: PayloadAction<ShortOfferInterface[]>) => ({...state, searched_offers: action.payload}),
        setSmartOffers: (state, action: PayloadAction<SmartOfferInterface[]>) => ({...state, smartOffers: action.payload}),
        setMegaOffer: (state, action: PayloadAction<SmartOfferInterface>) => ({...state, megaOffer: action.payload}),
        setFakeUrls: (state, action: PayloadAction<FakeUrlsInterface>) => ({...state, fake_urls: action.payload}),
        setGeo: (state, action: PayloadAction<GeoResponseInterface[]>) => ({
            ...state,
            countries: action.payload.map(val => val.countries).reduce((pre, cur) => pre.concat(cur)).map(e => ({value: e.code, text: e.name, bonus: e.bonus})).sort((a, b) => a.text.localeCompare(b.text)),
            geo: action.payload.map((geoObject: GeoResponseInterface) => ({
                region: geoObject.region,
                countries: geoObject.countries.map(geo => ({value: geo.code, text: geo.name, bonus: geo.bonus }))
            }))
        }),
        setBreadcrumbs: (state, action: PayloadAction<BreadcrumbsInterface[]>) => ({
            ...state,
            breadrumbs: action.payload,
        }),
        resetSearchedOffersState: (state) => ({...state, searched_offers: []}),
        setSiteLanguage: (state, action: PayloadAction<string>) => {
            state.siteLang = action.payload;
            initUseResponseWidget(action.payload);
        },
        setFetched: (state, action: PayloadAction<string>) => ({...state, fetched: {...state.fetched, [action.payload]: true}}),
        getAppConfig()  {},
        getCategories() {},
        getTrendingStores() {},
        getTopOffers() {},
        getSearchedOffers(state, action: PayloadAction<{ queryParams: URLSearchParams; controller: AbortController; }>) {},
        getSmartOffers(state, action: PayloadAction<{ queryParams: any; controller: AbortController; }>) {},
        getMegaOffer(state, action: PayloadAction<{ uuId: any; controller: AbortController; }>) {},
        getFakeUrls(state, action: PayloadAction<{ queryParams: any; controller: AbortController; }>) {},
        getGeo() {},
        postGeoCurrency(state, action: PayloadAction<{ data: any; }>) {},

    },
});

export const {
    showLoader,
    hideLoader,
    showCountryDialog,
    hideCountryDialog,
    resetSearchedOffersState,
    setAppConfig,
    setConfigCountry,
    setCategories,
    setTrendingStores,
    setTopOffers,
    setSearchedOffers,
    setSmartOffers,
    setMegaOffer,
    setFakeUrls,
    setGeo,
    setSelectedRegion,
    getAppConfig,
    getCategories,
    getTrendingStores,
    getTopOffers,
    getSearchedOffers,
    getGeo,
    getSmartOffers,
    getMegaOffer,
    getFakeUrls,
    setFetched,
    postGeoCurrency,
    changeRegion,
    setBreadcrumbs,
    setSiteLanguage
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
