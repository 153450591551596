export const getWebsiteNameFromLink = (url: string): string => {
    if (!url) {
        return '';
    }
    let domain;
    try {
        domain = new URL(url);
    } catch (e) {
        domain = new URL(`https://${url}`);
    }
    const hostName = domain.hostname.replace('www.','');

    return hostName.charAt(0).toUpperCase() + hostName.slice(1);
};
