import React, {FC, useEffect, useState} from 'react';
import {Modal} from 'components';
import {ModalContentIdConst, WrapperPageContainerIdConst} from 'consts';
import {CountryModalInterface} from './CountryModal.interface';
import changeDialogStyle from './CountryModal.module.scss';
import {useDispatch} from 'react-redux';
import {AppConfigInterface, GeoInterface} from 'typings';
import {useCommonSelector} from 'hooks';
import {FirstScreen, ChooseCountry} from './components';
import {hideCountryDialog, postGeoCurrency} from '../../core';



export const CountryModal: FC<CountryModalInterface> = ({setShowModal, showModal}) => {
    const dispatch = useDispatch();
    const [currentGeo, setCurrentGeo] = useState<string>('');
    const [configRegion, setConfigRegion] = useState<string>('');
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [isFirstScreen, setIsFirstScreen] = useState<boolean>(true);
    const appConfig: AppConfigInterface = useCommonSelector<AppConfigInterface>('appConfig');
    const geo: GeoInterface[] = useCommonSelector<GeoInterface[]>('geo');

    const handleGeoChange = (value) => {
        setCurrentGeo(value);
        dispatch(postGeoCurrency({data: {country: value}}));
    };

    const handleScreenChange = () => {
        setIsFirstScreen(!isFirstScreen);
    };
    const handleConfirmClick = () => {
        dispatch(postGeoCurrency({data: {country: currentGeo}}));
    };

    const handleCloseClick = () => {
        dispatch(hideCountryDialog());
    };

    useEffect(() => {
        if (!appConfig.lang) {
            return;
        }
        setLoggedIn(!!appConfig.user);
        if (!geo?.length) {
            return;
        }
        const configRegion = appConfig.country || '';
        let regionName;

        geo.forEach(geoValue => {
            if (!!regionName) {
                return;
            }
            regionName = (geoValue.countries.find(country => country.value === configRegion) || {}).text || '';
        });
        setConfigRegion(regionName);
        if (!!regionName) {
            setCurrentGeo(configRegion);
        }
    }, [appConfig, geo]);

    return (
        <Modal active={showModal}
               setActive={setShowModal}
               closable={false}
               onClose={handleCloseClick}
               size={isFirstScreen ? 's' : 'l'}
               fixed
               modalContainerId={ModalContentIdConst}
               scrollContainerId={WrapperPageContainerIdConst}>
            <div className={changeDialogStyle.dialog}>
                {isFirstScreen && <FirstScreen configRegion={configRegion}
                             currentGeo={currentGeo}
                             loggedIn={loggedIn}
                             onConfirmClick={handleConfirmClick}
                             onChangeClick={handleScreenChange}
                             onCloseClick={handleCloseClick}/>
                }
                {!isFirstScreen && <ChooseCountry geo={geo}
                                             currentGeo={currentGeo}
                                             loggedIn={loggedIn}
                                             onChangeClick={(e) => handleGeoChange(e)}
                                             onBackClick={handleScreenChange}/>
                }
            </div>
        </Modal>
    );
};
