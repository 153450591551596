import {useDispatch, useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import {AnalyticEventNameEnum, InternalLinksEnum} from 'typings';
import {
    getClickUnderLink,
    getGaABTestLanding,
    getLandingLocation,
    getPostHogCookie,
    getSsoLocales,
    getWebsiteNameFromLink,
    openNewLocation,
    sendAnalyticEvents,
    sendGaEvent,
    setPostHogPageView
} from 'utils';
import {DirectUriInterface, LandingTypes} from '../typings';
import {getSearchOffer, setShowUnloadAlert, setUriParams} from '../landing.reducer';
import {MainRoutesEnum, RootState} from 'core';
import {SsoConst} from 'consts';
import {useBeforeunload} from 'react-beforeunload';

export const useLandingMain = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {landingType, showUnloadAlert, uriParams} = useSelector((state: RootState) => state.landingReducer);

    const handleCancelClick = (eName = '') => {
        dispatch(setShowUnloadAlert(false));
        const eventName = (eName && typeof eName === 'string') ? eName : AnalyticEventNameEnum.CLICK_I_HATE_SAVING;
        setTimeout(() => {
            const gaOptions = {
                event_option1: '',
                event_option2: getLandingLocation(landingType),
                program_id: uriParams?.domain || 'undefined',
                sendbox_id: uriParams?.admitad_uid || 'undefined',
                website_id: uriParams?.wid || 'undefined',
                ab_test: getGaABTestLanding(landingType, uriParams?.ab)
            };
            const postHogOptions = {
                button_name: eventName,
                button_location: getLandingLocation(landingType),
                store_name: uriParams?.domain,
                referral_wid: uriParams?.wid
            };
            sendAnalyticEvents(eventName, {gaOptions, postHogOptions});
            window.location.href = `${uriParams?.original_link || '/'}`;
        });
    };

    const handleRegistrationClick = (isSendAnalytics?: boolean) => {
        dispatch(setShowUnloadAlert(false));
        setTimeout(() => {
            const isExtension = landingType === LandingTypes.DIRECTV1 || LandingTypes.DIRECTV2 || LandingTypes.DIRECT_EXTENSION;
            let nextParam = `${MainRoutesEnum.FAKE_LINK}/?url=${encodeURIComponent(uriParams?.site_url || "")}`;
            if (uriParams?.original_link) {
                try {
                    const ulpParam = new URL(uriParams?.original_link).searchParams.get('ulp');
                    if (ulpParam) {
                        nextParam += `&ulp=${encodeURIComponent(ulpParam)}`;
                    }
                } catch (e) {}
            }
            let redirectLink = `${SsoConst.register}?${getSsoLocales()}&next=${encodeURIComponent(nextParam)}`;
            if (uriParams?.admitad_uid) {
                redirectLink += `&admitad_uid=${uriParams?.admitad_uid}`;
            }
            if (uriParams?.wid) {
                redirectLink += `&wid=${uriParams?.wid}`;
            }
            if (isSendAnalytics) {
                const gaOptions = {
                    event_option1: '',
                    event_option2: getLandingLocation(landingType),
                    program_id: uriParams?.domain || 'undefined',
                    sendbox_id: uriParams?.admitad_uid || 'undefined',
                    website_id: uriParams?.wid || 'undefined',
                    ab_test: getGaABTestLanding(landingType, uriParams?.ab)
                };
                const postHogOptions = {
                    button_name: AnalyticEventNameEnum.CLICK_ACTIVATE_MY_CASHBACK,
                    button_location: getLandingLocation(landingType),
                    store_name: uriParams?.domain,
                    referral_wid: uriParams?.wid
                };
                sendAnalyticEvents(AnalyticEventNameEnum.CLICK_ACTIVATE_MY_CASHBACK, {gaOptions, postHogOptions});
            }
            let currentRedirectLink = getClickUnderLink(uriParams?.original_link, landingType);
            openNewLocation(isExtension ? InternalLinksEnum.EXTENSION : redirectLink, true);
            if (currentRedirectLink) {
                window.location.href = currentRedirectLink;
            }
        });
    };

    useBeforeunload(showUnloadAlert ? (event) => event.preventDefault() : null);

    useEffect(() => {
        if (!landingType) {
            return;
        }
        const controller = new AbortController();

        const urlParams = {} as DirectUriInterface;
        // @ts-ignore
        const currentParams = Object.fromEntries([...searchParams]);
        for (const [key, value] of Object.entries(currentParams)) {
            urlParams[key] = value
        }

        if (urlParams?.site_url) {
            const newSearchParams = new URLSearchParams();
            newSearchParams.set('url', urlParams.site_url);

            dispatch(getSearchOffer({queryParams: newSearchParams, controller}));
            urlParams.domain = getWebsiteNameFromLink(urlParams.site_url);
        }
        dispatch(setUriParams(urlParams));

        setTimeout(function(){
            sendGaEvent(AnalyticEventNameEnum.PAGEVIEW, {
                program_id: urlParams?.domain || 'undefined',
                sendbox_id: urlParams?.admitad_uid || 'undefined',
                website_id: urlParams?.wid || 'undefined',
                ab_test: getGaABTestLanding(landingType, urlParams?.ab)
            });
        }, 300);

        if (process.env.REACT_APP_ENVIRONMENT_NAME === 'prod') {
            const phCookie = getPostHogCookie();
            let dId;
            try {
                dId = JSON.parse(phCookie)?.distinct_id || '';
            } catch {
                dId = '';
            }
            setPostHogPageView(dId, undefined, {
                store_name: urlParams?.domain,
                referral_wid: urlParams?.wid
            });
        }
    }, [searchParams, landingType, dispatch]);

    return {
        landingType,
        handleCancelClick,
        handleRegistrationClick
    };
};
