import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input as InputUIKit, InputProps } from '@design-system/ui-kit'

export const Input: FC<InputProps> = ({ name, onChange: customOnChange, ...optionalProps }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    if (!name) {
        return null
    }
    const error = errors?.[name] as {
        message: string
    }
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <InputUIKit
                    {...field}
                    value={field.value ?? ''}
                    errorMessage={error?.message}
                    onChange={(event) => {
                        customOnChange && customOnChange(event)
                        field.onChange(event)
                    }}
                    {...optionalProps}
                />
            )}
        />
    )
}

