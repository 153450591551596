import React, {FC} from 'react';
import withdrawalLimits from './WithdrawalLimits.module.scss';
import {useTranslation} from 'react-i18next';
import {WithdrawalLimitsInterface} from './WithdrawalLimits.interface';



export const WithdrawalLimits: FC<WithdrawalLimitsInterface> = (props) => {
    const {restriction, currency = ''} = props;
    const {t} = useTranslation();

    return (
        <div className={withdrawalLimits.limits}>
            <h2 className={`textHeader_06 ${withdrawalLimits.limits__title}`}>{t('Withdrawal limits')}</h2>
            <dl className={withdrawalLimits.limits__dl}>
                {/*<dd className={withdrawalLimits.limits__dd}>{t('Minimum amount for first withdrawal')}</dd>*/}
                {/*<dt className={withdrawalLimits.limits__dt}>{restriction?.min_withdrawal} {currency}</dt>*/}
                <dd className={withdrawalLimits.limits__dd}>{t('Minimum withdrawal amount')}</dd>
                <dt className={withdrawalLimits.limits__dt}>{restriction?.min_withdrawal} {currency}</dt>
                <dd className={`${withdrawalLimits.limits__dd} ${withdrawalLimits.limits__dd_last}`}>{t('Maximum withdrawal amount per month')}</dd>
                <dt className={withdrawalLimits.limits__dt}>{restriction?.max_withdrawal_per_month} {currency}</dt>
                {/*<dd className={`${withdrawalLimits.limits__dd} ${withdrawalLimits.limits__dd_last}`}>{t('Maximum cashback amount for 1 order')}</dd>*/}
                {/*<dt className={withdrawalLimits.limits__dt}>{restriction?.max_withdrawal_per_month} {currency}</dt>*/}
            </dl>
        </div>
    );
};
